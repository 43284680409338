import { Injectable } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { firstValueFrom } from 'rxjs';
import { MitsOptionsAlertComponent } from 'src/app/components/mits-options-alert/mits-options-alert.component';
import { OrderModel } from 'src/app/models/order';
import { FunctionSwitchHelperService } from 'src/app/providers/component-helpers/function-switch.service';
import { LoadingHelper } from 'src/app/providers/helpers/loading-helper.service';
import { OrderService } from 'src/app/providers/model-services/orders/order.service';
import { SHOW_CANCEL_ORDER_ALERT_ON_SIGNOUT } from 'src/assets/constants/functionSwitch.constants';
import { CarModel } from '../models';

export type CancelOrdersAlertState = 'cancel' | 'cancelOrders' | 'confirm';
@Injectable({
  providedIn: 'root',
})
export class CarHelperService {
  lastRemoteCarsLoad: Date;
  remoteCars: CarModel[];

  constructor(
    private readonly alertCtrl: AlertController,
    private readonly functionSwitchHelper: FunctionSwitchHelperService,
    private readonly loadingHelper: LoadingHelper,
    private readonly modalCtrl: ModalController,
    private readonly orderService: OrderService
  ) {}

  /**
   * Calculates the minutes since the last remote cars load
   * @returns minutes since last remote cars load as number or 99999 if no last remote cars load is set
   */
  public getMinutesSinceLastRemoteCarsLoad(): number {
    return this.lastRemoteCarsLoad
      ? (new Date().getTime() - this.lastRemoteCarsLoad.getTime()) / 1000 / 60
      : 99999;
  }

  /**
   * Fehlermeldung das Aufträge noch im Status bearbeiten sind
   * Rückgabewert gibt an was der User geklicked hat
   */
  public async displayWarningWorkingOrders(
    signout?: boolean,
    useLoading?: boolean
  ): Promise<CancelOrdersAlertState> {
    if (await this.hasWorkingOrders()) {
      const showOrdersAlert =
        this.functionSwitchHelper.has(SHOW_CANCEL_ORDER_ALERT_ON_SIGNOUT) &&
        signout;
      const modal = await this.modalCtrl.create({
        component: MitsOptionsAlertComponent,
        cssClass: showOrdersAlert
          ? 'mits-options-alert'
          : 'mits-options-alert-small',
        componentProps: {
          title: 'Aufträge in Bearbeitung!',
          description: showOrdersAlert
            ? 'Wie soll mit diesen Aufträgen verfahren werden?'
            : 'Sind Sie sicher das sie den Vorgang durchführen möchten?',
          options: showOrdersAlert
            ? [
                {
                  title: 'Nur vom Fahrzeug abmelden',
                  description:
                    'Alle Aufträge bleiben bestehen und Sie werden vom Fahrzeug abgemeldet.',
                  role: 'confirm',
                  color: 'primary',
                  icon: 'log-out',
                },
                {
                  title: 'Alle Aufträge abbrechen',
                  description:
                    'Alle Aufträge werden abgebrochen und Sie werden vom Fahrzeug abgemeldet.',
                  role: 'cancelOrders',
                  color: 'danger',
                  icon: 'close',
                },
                {
                  title: 'Fahrzeugabmeldung abbrechen',
                  description:
                    'Alle Aufträge bleiben bestehen und Sie werden nicht vom Fahrzeug abgemeldet.',
                  role: 'cancel',
                  color: 'primary',
                  icon: 'arrow-back',
                },
              ]
            : [
                {
                  title: 'Abbrechen',
                  description: 'Vorgang abbrechen',
                  role: 'cancel',
                  color: 'danger',
                  icon: 'close-circle-outline',
                },
                {
                  title: 'Bestätigen',
                  description: 'Vorgang durchführen',
                  role: 'confirm',
                  color: 'primary',
                  icon: 'checkmark-circle-outline',
                },
              ],
        },
      });
      if (useLoading) this.loadingHelper.removeLoading();
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (useLoading) this.loadingHelper.addLoading();
      return (data?.role ?? 'cancel') as CancelOrdersAlertState;
    } else {
      return 'confirm';
    }
  }

  /**
   * Vorbedingungen für Operationen prüfen
   */
  public async checkPreConditionForBooking(
    signout?: boolean,
    useLoading?: boolean
  ): Promise<CancelOrdersAlertState> {
    const resultErrorsInQueue = await this.displayErrorOrdersInQueue(
      useLoading
    );
    if (!resultErrorsInQueue) return 'cancel';
    const result = await this.displayWarningWorkingOrders(signout, useLoading);
    return result;
  }

  /**
   * Fehlermeldung das Aufträge in der Queue sind die noch nicht raus sind.
   * Beladungen und Abmeldung kann noch nicht durchgeführt werden
   */
  public async displayErrorOrdersInQueue(
    useLoading?: boolean
  ): Promise<boolean> {
    if (await this.hasOrdersInQueue()) {
      const alert = await this.alertCtrl.create({
        header: 'Warteschlange nicht leer!',
        subHeader:
          'Bitte stellen Sie sicher das alle Aufträge an den Server übertragen wurden.',
        buttons: [
          {
            text: 'Abbrechen',
            role: 'cancel',
          },
          {
            text: 'Fortfahren',
            role: 'confirm',
          },
        ],
      });
      if (useLoading) this.loadingHelper.removeLoading();
      await alert.present();
      const { role } = await alert.onDidDismiss();
      if (useLoading) this.loadingHelper.addLoading();
      return role === 'confirm';
    } else {
      return true;
    }
  }

  public async getWorkingOrders(): Promise<OrderModel[]> {
    const result = await firstValueFrom(
      this.orderService.forUser({
        orders_filter: 'active',
      })
    );
    return result;
  }

  /**
   * Sind noch offene Aufträge für den aktuellen User vorhanden
   */
  private async hasWorkingOrders(): Promise<boolean> {
    const result = await this.getWorkingOrders();
    return result.length > 0;
  }

  /**
   * Sind noch Aufträge in der Queue vorhanden?
   */
  private hasOrdersInQueue(): Promise<boolean> {
    return this.orderService.hasDataQueued();
  }
}
