import { Injectable } from '@angular/core';
import { ITourUserService, TourUserModel } from 'src/packages/tourManagement/interfaces/user.service.interface';
import { UserService } from '../user.service';
import { firstValueFrom } from 'rxjs';
import { UserModel } from 'src/app/models/users/user';

@Injectable({
    providedIn: 'root',
})
export class UserServiceTourAdapter implements ITourUserService {
    constructor(private userService: UserService) { }

    async all(): Promise<TourUserModel[]> {
        const response = await firstValueFrom(this.userService.outside());
        return response.map(this.toTourUserModel);
    }

    private toTourUserModel(user: UserModel): TourUserModel {
        return {
            id: user.id,
            firstname: user.firstname,
            lastname: user.lastname
        };
    }
}