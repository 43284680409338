import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import {
  RIGHTS,
  ROUTE_RIGHTS,
  Right,
} from 'src/assets/constants/roles.constants';

@Injectable({
  providedIn: 'root',
})
export class AccessGuard extends KeycloakAuthGuard {
  constructor(
    protected readonly router: Router,
    protected readonly keycloak: KeycloakService
  ) {
    super(router, keycloak);
  }

  /**
   * Checks if roles are set/loaded
   * @returns True / False
   */
  public areRolesLoaded(): boolean {
    return this.roles != null;
  }

  public async hasAccessToPath(route: string): Promise<boolean> {
    let hasAccess = this.syncHasAccesToPath(route);
    if (hasAccess === null) {
      // Wait for roles to be loaded
      await new Promise((r) => setTimeout(r, 200));
      hasAccess = this.syncHasAccesToPath(route);
    }
    return hasAccess;
  }

  public syncHasAccesToPath(route: string): boolean {
    const requiredRoles = ROUTE_RIGHTS[route];

    // Allow the user to to proceed if no additional roles are required to access the route.
    if (!(requiredRoles instanceof Array) || requiredRoles.length === 0) {
      return true;
    }

    if (this.roles) {
      // Allow the user to proceed if all the required roles are present.
      return requiredRoles.every((role) => this.roles.includes(role));
    }

    // If no roles are present, return nothing.
    return null;
  }

  public hasRight(right: Right): boolean {
    return this.roles.includes(RIGHTS[right]);
  }

  public async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    // Force the user to log in if currently unauthenticated.
    if (!this.authenticated) {
      await this.keycloak.login({
        redirectUri: window.location.origin + state.url,
      });
    }
    return this.hasAccessToPath(route.routeConfig.path);
  }
}
