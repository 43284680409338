// tslint:disable: no-redundant-jsdoc

export class EndpointService {
  // eslint-disable-next-line @typescript-eslint/member-ordering
  endpoint = 'shouldBeOverwritten';
  // eslint-disable-next-line @typescript-eslint/member-ordering
  objectName = 'shouldBeOverwritten';


  /**
   * Endpoint with URL
   * @return {string}
   */
  get endpointWithUrl(): string {
    return this.url + this.endpoint;
  }


  /**
   * Getting url
   * @return {string}
   */
  get url(): string {
    return EndpointService.serviceUrl;
  }

  /**
   * Setzt die BackendURL für Test / Echtsystem
   */
  public static get serviceUrl(): string {
    const url = window.location.toString();
    if (url.indexOf('localhost') >= 0) {
      return 'http://localhost:3000/';
    } else {
      return `https://${window.location.host}/service/`;
    }
  }
}