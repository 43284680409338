import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SyncProcessorService } from '@vending/sync-engine-client';
import { StoredData } from '@vending/sync-engine-client/lib/models/storedData';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { firstValueFrom } from 'rxjs';
// internal
import { MachineModel } from 'src/app/models/machine';
import { ErrorService } from 'src/app/providers/error.service';
import { EventsService } from 'src/app/providers/events.service';
import { OfflineDataService } from 'src/app/providers/offlineData.service';

// Präfixe für die Fulltext-Suche
const FULLTEXT_EAN_PREFIX = 'EAN';
const FULLTEXT_REMOTE_ID_PREFIX = 'RID';
const FULLTEXT_CUSTOMER_ID_PREFIX = 'CID';
const FULLTEXT_CUSTOMER_LOCATION_ID_PREFIX = 'LID';

// Typen für die Präfixe
type FullTextPrefix =
  | typeof FULLTEXT_EAN_PREFIX
  | typeof FULLTEXT_REMOTE_ID_PREFIX
  | typeof FULLTEXT_CUSTOMER_ID_PREFIX
  | typeof FULLTEXT_CUSTOMER_LOCATION_ID_PREFIX;

@Injectable({
  providedIn: 'root',
})
export class MachineService extends OfflineDataService<MachineModel> {
  constructor(
    public indexedDBService: NgxIndexedDBService,
    public syncProcessor: SyncProcessorService,
    public http: HttpClient,
    public errorService: ErrorService,
    public events: EventsService
  ) {
    super(
      indexedDBService,
      syncProcessor,
      'Machine',
      http,
      errorService,
      events,
      'machines/',
      'machine',
      [
        'created_at',
        'updated_at',
        'created_by_id',
        'customer',
        'open_damages',
        'category',
      ],
      [
        'customer_location',
        'planogram_articles',
        'planogram',
        'layout',
        'select_buttons',
      ]
    );
    this.bulkSize = 10;
    this.bulkSyncActive = true;
  }

  //////////////////////////////////////////////////////////////
  //// Öffentliche Methoden

  /**
   * Validiert die Offlinedaten in jedem Sync
   * @return {boolean} true, wenn validiert werden soll
   */
  override get checkEachSync() : boolean {
    return this.checkAlreadyValidated();
  }

  /**
   * Update der Seriennummer der Maschine
   * - Aktualisiert die Maschinendaten lokal mit der neuen Seriennummer
   * - Reiht die Änderung der Seriennummer in der Warteschlange ein
   */
  public async updateSerialNumber(machineId: number, serialNumber: string): Promise<void> {
    if (!machineId || !serialNumber) return;
    try {
      const machine: MachineModel = await firstValueFrom(this.find(machineId));
      machine.serial_number = serialNumber;
      // Stammdaten lokal aktualisieren
      await this.saveLocalForce(machine);
      // Aktualisierung in der Warteschlange einreihen
      await firstValueFrom(
        this.save(
          {id: machineId, serial_number: serialNumber} as MachineModel
        )
      );
    } catch (error) {
      console.error('[MachineService][updateSerialNumber]: Fehler beim Update der Seriennummer', error);
    }
  }

  /**
   * Name des Icons für die Klasse
   * @return string - Name des Icons
   */
  public get iconName(): string {
    return 'cafe-outline';
  }

  /**
   * Suche nach einer Maschine anhand des Attributs 'remote_id' mithilfe des Fulltext
   * @param remoteId - die Remote-ID anhand dessen gesucht werden soll
   * @return Promise<MachineModel | undefined> - die gefundene Maschine oder undefined
   */
  public findByRemoteId(remoteId: string): Promise<MachineModel | undefined> {
    return this.fullTextFindWithPrefix(remoteId, FULLTEXT_REMOTE_ID_PREFIX);
  }

  /**
   * Suche nach allen Maschinen anhand des Attributs 'remote_id' mithilfe des Fulltext
   * @param remoteId - die Remote-ID anhand dessen gesucht werden soll
   * @return Promise<MachineModel[]> - die gefundenen Maschinen
   */
  public allByRemoteId(remoteId: string): Promise<MachineModel[]> {
    return this.fullTextAllWithPrefix(remoteId, FULLTEXT_REMOTE_ID_PREFIX);
  }

  /**
   * Suche nach einer Maschine anhand des Attributs 'eancode' mithilfe des Fulltext
   * @param eancode - der EanCode anhand dessen gesucht werden soll
   * @return Promise<MachineModel | undefined> - die gefundene Maschine oder undefined
   */
  public findByEan(eancode: string): Promise<MachineModel | undefined> {
    return this.fullTextFindWithPrefix(eancode, FULLTEXT_EAN_PREFIX);
  }

  /**
   * Suche nach allen Maschinen anhand des Attributs 'eancode' mithilfe des Fulltext
   * @param eancode - der EanCode anhand dessen gesucht werden soll
   * @return Promise<MachineModel[]> - die gefundenen Maschinen
   */
  public allByEan(eancode: string): Promise<MachineModel[]> {
    return this.fullTextAllWithPrefix(eancode, FULLTEXT_EAN_PREFIX);
  }

  /**
   * Suche nach einer Maschine anhand des Attributs 'customer_id' mithilfe des Fulltext
   * @param customerId - die Customer-ID anhand dessen gesucht werden soll
   * @return Promise<MachineModel | undefined> - die gefundene Maschine oder undefined
   */
  public findByCustomerId(customerId: string): Promise<MachineModel | undefined> {
    return this.fullTextFindWithPrefix(customerId, FULLTEXT_CUSTOMER_ID_PREFIX);
  }

  /**
   * Suche nach allen Maschinen anhand des Attributs 'customer_id' mithilfe des Fulltext
   * @param customerId - die Customer-ID anhand dessen gesucht werden soll
   * @return Promise<MachineModel[]> - die gefundenen Maschinen
   */
  public allByCustomerId(customerId: string): Promise<MachineModel[]> {
    return this.fullTextAllWithPrefix(customerId, FULLTEXT_CUSTOMER_ID_PREFIX);
  }

  /**
   * Suche nach einer Maschine anhand des Attributs 'customer_location_id' mithilfe des Fulltext
   * @param customerLocationId - die Customer-Location-ID anhand dessen gesucht werden soll
   * @return Promise<MachineModel | undefined> - die gefundene Maschine oder undefined
   */
  public findByCustomerLocationId(customerLocationId: string): Promise<MachineModel | undefined> {
    return this.fullTextFindWithPrefix(customerLocationId, FULLTEXT_CUSTOMER_LOCATION_ID_PREFIX);
  }

  /**
   * Suche nach allen Maschinen anhand des Attributs 'customer_location_id' mithilfe des Fulltext
   * @param customerLocationId - die Customer-Location-ID anhand dessen gesucht werden soll
   * @return Promise<MachineModel[]> - die gefundenen Maschinen
   */
  public allByCustomerLocationId(customerLocationId: string): Promise<MachineModel[]> {
    return this.fullTextAllWithPrefix(customerLocationId, FULLTEXT_CUSTOMER_LOCATION_ID_PREFIX);
  }

  /**
   * Fulltext neu aufbauen für alle Maschinen
   * @param obj - optional: die einzelne Entität, für die der Fulltext neu aufgebaut werden soll
   */
  public async rebuildFullText(obj?: MachineModel): Promise<void> {
    let machines: StoredData<MachineModel>[];
    if (!obj) {
      machines = await firstValueFrom(this.localAllStoredData());
    } else {
      machines = [this.createStoredData(obj)];
    }
    for (const s of machines) {
      this.enqueueFullTextEntries(s);
    }
  }

  //////////////////////////////////////////////////////////////
  //// Überschriebene Methoden des OfflineDataService

  /**
   * Daten aus dem lokalen Datenbestand löschen
   * @param id - ID der Entität, die gelöscht werden soll
   * @return Promise<void> - Promise, das aufgelöst wird, wenn die Entität gelöscht wurde
   */
  public async localDelete(id: number): Promise<void> {
    const currentObj: StoredData<MachineModel> = await this.localFind(id);
    await super.localDelete(id);
    if (currentObj) {
      this.enqueueFullTextEntries(currentObj, true);
    }
  }

  //////////////////////////////////////////////////////////////
  //// Überschriebene Methoden des SyncService

  /**
   * Aktualisiert beim Speichern die Fulltext-Queue, falls sich die für den Fulltext relevanten Daten geändert haben
   * @param obj - die Entität, die gespeichert wird
   * @param updatedAt - das Änderungsdatum
   * @protected
   */
  protected override async store(obj: MachineModel, updatedAt: string): Promise<boolean | StoredData<MachineModel>> {
    const newObj: StoredData<MachineModel> = this.createStoredData(obj);
    const currentObj: StoredData<MachineModel> = await this.localFind(obj.id);
    if (currentObj) {
      if (
        currentObj.content.remote_id !== obj.remote_id ||
        currentObj.content.eancode !== obj.eancode ||
        currentObj.content.customer_id !== obj.customer_id ||
        currentObj.content.customer_location_id !== obj.customer_location_id
      ) {
        this.enqueueFullTextEntries(currentObj, true);
        this.enqueueFullTextEntries(newObj, false);
      }
    } else {
      this.enqueueFullTextEntries(newObj);
    }
    return super.store(obj, updatedAt);
  }

  //////////////////////////////////////////////////////////////
  //// Private Hilfsmethoden

  /**
   * Fügt einen Eintrag mit Präfix zur Fulltext-Queue hinzu
   * @param obj - die Entität
   * @param prefix - das Präfix
   * @param remove - true, wenn der Eintrag entfernt werden soll, statt hinzugefügt
   * @private
   */
  private enqueueFullTextEntryWithPrefix(
    obj: StoredData<MachineModel>,
    prefix: FullTextPrefix,
    remove: boolean = false): void {
    let fulltextKeyWithPrefix: string = prefix + '_';

    switch (prefix) {
      case 'EAN':
        fulltextKeyWithPrefix += obj.content.eancode;
        break;
      case 'RID':
        fulltextKeyWithPrefix += obj.content.remote_id;
        break;
      case 'CID':
        fulltextKeyWithPrefix += obj.content.customer_id;
        break;
      case 'LID':
        fulltextKeyWithPrefix += obj.content.customer_location_id;
        break;
    }

    this.fulltextService.insertIntoWorkflowQueue(
      fulltextKeyWithPrefix.toLowerCase(),
      obj.content.id * (remove ? -1 : 1),
      this.type,
    );
  }

  /**
   * Fügt Einträge mit Präfix zur Fulltext-Queue hinzu (bzw. entfernt sie)
   * @param obj - die Entität, für die die Einträge hinzugefügt werden sollen
   * @param remove - true, wenn die Einträge entfernt werden sollen, statt hinzugefügt
   * @private
   */
  private enqueueFullTextEntries(obj: StoredData<MachineModel>, remove: boolean = false): void {
    if (obj.content.eancode) this.enqueueFullTextEntryWithPrefix(obj, FULLTEXT_EAN_PREFIX, remove);
    if (obj.content.remote_id) this.enqueueFullTextEntryWithPrefix(obj, FULLTEXT_REMOTE_ID_PREFIX, remove);
    if (obj.content.customer_id) this.enqueueFullTextEntryWithPrefix(obj, FULLTEXT_CUSTOMER_ID_PREFIX, remove);
    if (obj.content.customer_location_id) this.enqueueFullTextEntryWithPrefix(obj, FULLTEXT_CUSTOMER_LOCATION_ID_PREFIX, remove);
  }

  /**
   * Finden einer Entität über den Fulltext mit einem Präfix
   * @param input - Suchbegriff für das Attribut
   * @param prefix - Präfix für die Suche
   * @return Promise<MachineModel | undefined> - die gefundene Entität oder undefined
   */
  private async fullTextFindWithPrefix(input: string, prefix: FullTextPrefix): Promise<MachineModel | undefined> {
    const normalizedInput: string = input?.trim().toLowerCase();
    if (!normalizedInput || normalizedInput.length === 0) return undefined;

    const result: MachineModel[] = await this.fullTextAllWithPrefix(normalizedInput, prefix);
    if (result?.length > 0) {
      return result[0];
    }
    return undefined;
  }

  /**
   * Finden aller Entitäten über den Fulltext mit einem Präfix
   * @param input - Suchbegriff für das Attribut
   * @param prefix - Präfix für die Suche
   * @return Promise<MachineModel[]> - die gefundenen Entitäten
   */
  private async fullTextAllWithPrefix(input: string, prefix: FullTextPrefix): Promise<MachineModel[]> {
    const normalizedInput: string = input?.trim().toLowerCase();
    if (!normalizedInput || normalizedInput.length === 0) return [];

    return this.fullTextSearch(`${prefix}_${input}`);
  }
}