import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SyncProcessorService } from '@vending/sync-engine-client';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { TimelogCategoryModel } from 'src/app/models/system/timelog-category';
import { ErrorService } from '../../error.service';
import { EventsService } from '../../events.service';
import { OfflineDataService } from '../../offlineData.service';

@Injectable({
  providedIn: 'root',
})
export class TimelogCategoryService extends OfflineDataService<TimelogCategoryModel> {
  constructor(
    public indexedDBService: NgxIndexedDBService,
    public syncProcessor: SyncProcessorService,
    public http: HttpClient,
    public errorService: ErrorService,
    public events: EventsService
  ) {
    super(
      indexedDBService,
      syncProcessor,
      'System::TimelogCategory',
      http,
      errorService,
      events,
      'system/timelog_categories/',
      'system_timelog_category',
      ['created_at', 'updated_at', 'remote_id'],
      ['modules'],
      null,
      true
    );
  }
}
