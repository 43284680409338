import { TimeEvent } from '../models';

export const eventConfig: { [index: string]: string } = {
  [TimeEvent.WorkStart]: 'Arbeitszeit starten',
  [TimeEvent.DriveStart]: 'Fahren',
  [TimeEvent.CarEquipStart]: 'Fahrzeug rüsten',
  [TimeEvent.CarEquipEnd]: 'Fahrzeug rüsten beenden',
  [TimeEvent.CarLogout]: 'Vom Fahrzeug abmelden',
  [TimeEvent.BufferstockStart]: 'Bufferstock rüsten',
  [TimeEvent.MachineStart]: 'Automat bearbeiten',
  [TimeEvent.CustomerStart]: 'Kundenzeit starten',
  [TimeEvent.CustomerEnd]: 'Kundenzeit beenden',
  [TimeEvent.IndividualStart]: 'Individuelle Zeit starten',
  [TimeEvent.IndividualEnd]: 'Individuelle Zeit beenden',
  [TimeEvent.Logout]: 'Arbeitszeit beenden',
};
