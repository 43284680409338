import { Component, Input } from '@angular/core';
import { MitsOption } from './interfaces/option';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'mits-options-alert',
  templateUrl: './mits-options-alert.component.html',
  styleUrls: ['./mits-options-alert.component.scss'],
})
export class MitsOptionsAlertComponent {
  @Input() title: string = 'Option wählen';
  @Input() description: string = '';
  @Input() options: MitsOption[] = [];

  constructor(private readonly modalCtrl: ModalController) {}

  chooseOption(opt: MitsOption) {
    this.modalCtrl.dismiss({
      role: opt.role,
    });
  }
}
