import { Platform } from "@ionic/angular";
import { DeviceTypes } from "src/app/models/system/deviceTypes";



/**
 * Abstrahiert die DeviceTypes in einem Service, so dass diese genutzt werden können
 */
export abstract class DeviceService {

    deviceTypes: DeviceTypes;

    /**
     * Default-Constructor
     * @param {Platform} device 
     */
    constructor(
        public readonly device: Platform
    ) {
        this.yourDevice();
    }



    /**
     * Reads out which device is used. Sets a flag to true for the respective device.
     */
    yourDevice() {
        this.deviceTypes = {
            desktop: this.device.is('desktop'),
            ios: this.device.is('ios'),
            android: this.device.is('android'),
        };
    }
}