import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SyncProcessorService } from '@vending/sync-engine-client';
// internal
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { ErrorService } from 'src/app/providers/error.service';
import { EventsService } from 'src/app/providers/events.service';
import { OfflineDataService } from 'src/app/providers/offlineData.service';
import { BufferstockInventoryModel } from '../models/bufferstock-inventory';

@Injectable({
  providedIn: 'root',
})
export class BufferstockInventoryService extends OfflineDataService<BufferstockInventoryModel> {
  /**
   * HTTP Options
   */
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  constructor(
    public indexedDBService: NgxIndexedDBService,
    public syncProcessor: SyncProcessorService,
    public http: HttpClient,
    public errorService: ErrorService,
    public events: EventsService
  ) {
    super(
      indexedDBService,
      syncProcessor,
      'Warehouse::Inventur',
      http,
      errorService,
      events,
      'warehouse/inventurs/',
      'warehouse_inventur',
      [],
      ['positions'],
      undefined,
      false,
      false, // Verhindert, dass die Inventuren nach erfolgreichem Warteschlangenupload lokal persistiert werden.
    );
  }
}
