import { TimelogState } from '../models';

export const stateConfig: { [index: string]: string } = {
  [TimelogState.Unkown]: 'Unbekannt',
  [TimelogState.Previous]: 'Vorherig',
  [TimelogState.DayStarting]: 'Tagesstart',
  [TimelogState.Working]: 'Arbeitszeit',
  [TimelogState.DayEnding]: 'Tagesende',
  [TimelogState.Individual]: 'Individuell',
  [TimelogState.CarEquiping]: 'Fahrzeug rüsten',
  [TimelogState.CarDriving]: 'Fahrzeit',
  [TimelogState.CustomerWorking]: 'Kundenzeit',
  [TimelogState.MachineWorking]: 'Automatenzeit',
  [TimelogState.BufferstockEquiping]: 'Bufferstock rüsten',
};
