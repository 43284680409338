import { ArticleModel } from 'src/app/models/articles';

export interface SelectButtonArticle extends ArticleModel {
  select_button_key_name: string;
  select_button_id?: number;
  select_button_remote?: string;
}

export function instanceOfSelectButtonArticle(
  object: any
): object is SelectButtonArticle {
  return (
    ('select_button_id' in object || 'select_button_remote' in object) &&
    'select_button_key_name' in object
  );
}
