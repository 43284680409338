import { Injectable } from '@angular/core';
import { catchError, Observable, retry } from 'rxjs';
import { DataService } from '../data.service';
import { ErrorLogModel, ErrorLogResponseModel } from './../../models/errorlog';

@Injectable({
  providedIn: 'root',
})
export class ErrorLogService extends DataService<any> {
  endpoint = 'errorlog/index/';
  objectName = 'errorlog';

  loadErrorlogs(): Observable<ErrorLogResponseModel<ErrorLogModel>> {
    return this.http
      .get<ErrorLogResponseModel<ErrorLogModel>>(this.endpointWithUrl)
      .pipe(retry(1), catchError(this.errorService.convert));
  }
}
