import { Injectable } from '@angular/core';
import { StoredData } from '@vending/sync-engine-client/lib/models/storedData';
import { firstValueFrom } from 'rxjs';
// internal
import { ErrorService } from 'src/app/providers/error.service';
import { DAMAGE_STATE_FINISHED, DamagePositionModel, DamageReportModel, } from 'src/packages/damageManagement/models';
import { DamageReportService } from 'src/packages/damageManagement/providers/damage-report.service';
import { ArrayHelper } from 'src/packages/mitsBasics';
import { EndpointService } from 'src/packages/mitsBasics/providers/endpoint.service';


@Injectable({
  providedIn: 'root',
})
export class DamageService extends EndpointService {

  constructor(
    private reportService: DamageReportService,
    private errorService: ErrorService) {
    super();
  }

  //////////////////////////////////////////////////////////////
  //// Öffentliche Methoden
  /**
   * Lädt einen spezifischen Schaden aus einem DamageReport
   * @param damageReportId - die ID des DamageReports
   * @param damageId - die ID des Schadens innerhalb des DamageReports
   * @return Promise<DamagePositionModel> - der gefundene Schaden
   */
  public async loadDamageFromReport(damageReportId: number, damageId: number): Promise<DamagePositionModel> {
    const report: DamageReportModel = await firstValueFrom(
      this.reportService.find(damageReportId)
    );

    return report?.damages.find((damage: DamagePositionModel): boolean => damage.id === damageId);
  }

  /**
   * Speichert einen bestehenden Schaden über den Schadensbericht
   * @param damage - der zu speichernde Schaden
   * @return Promise<DamageReportModel> - der aktualisierte Schadensbericht
   */
  public async save(damage: DamagePositionModel): Promise<DamageReportModel> {
    const report: DamageReportModel = await firstValueFrom(
      this.reportService.find(damage.damage_report_id)
    );

    const damageIndex: number = report.damages.findIndex((d: DamagePositionModel): boolean => d.id === damage.id);

    ArrayHelper.insertOrUpdate(report.damages, damage, damageIndex);

    report.updated_at = undefined;
    await this.reportService.saveLocal(report);

    return firstValueFrom(this.reportService.save(report));
  }

  /**
   * Lädt die Schäden für eine Maschine
   * - Lädt zunächst alle Schadensberichte für die Maschine
   * - Gibt für alle Schadensberichte alle Schäden zurück, die nicht den Status 'DAMAGE_STATE_FINISHED' haben
   * @param machineId - die ID der Maschine, für die die Schäden geladen werden sollen
   * @return Promise<DamagePositionModel[]> - die gefundenen Schäden
   */
  public async loadOpenDamagesForMachine(machineId: number): Promise<DamagePositionModel[]> {
    const reports: DamageReportModel[] = await this.reportService.localAllByConfigurableId(machineId)
      .then((data: StoredData<DamageReportModel>[]) => {
        return data.map((d: StoredData<DamageReportModel>) => d.content);
      })
      .catch((err) => {
        this.errorService.handle(err);
        return [];
      });

    let damages: DamagePositionModel[] = [];
    reports.forEach((report: DamageReportModel): void => {
      damages = damages.concat(this.openDamages(report.damages));
    });

    return damages;
  }

  /**
   * Schäden nach dem Status 'nicht fertig' filtern
   *  - Gibt alle Schäden zurück, die nicht den Status 'DAMAGE_STATE_FINISHED' haben
   * @param damages - die Schäden, die gefiltert werden sollen
   * @return DamagePositionModel[] - die gefilterten Schäden
   */
  private openDamages(damages: DamagePositionModel[]): DamagePositionModel[] {
    return damages.filter((damage: DamagePositionModel): boolean => damage.state !== DAMAGE_STATE_FINISHED);
  }
}
