import { StoredData } from '@vending/sync-engine-client/lib/models/storedData';
import { GlobalHelper } from 'src/packages/mitsBasics/helpers/globalHelper/global.helper';
import { BasicModel } from '../models/basic';


/**
 * Mögliche Keys für die Speicherung von Objekten im LocalStorage
 * - Muss erweitert werden, wenn ein neuer Service hinzugefügt wird
 */
export type LocalDataServiceKeys =
  | 'order'
  | 'pausedCarOrder'
  | 'pausedInventory'
  | 'Test'
  | 'orderStockMovementsQueue';


/**
 * Model für die Speicherung von Objekten im LocalStorage
 */
interface LocalSaveObject<T> {
  // Das Objekt, das gespeichert werden soll
  obj: T;
  // Das Datum, an dem das Objekt zuletzt aktualisiert wurde
  updatedAt: string;
}

/**
 * Service zum Speichern von Daten im LocalStorage
 */
export class LocalDataService<T extends BasicModel> {
  // Der Key, unter dem die Daten im LocalStorage gespeichert werden
  key: LocalDataServiceKeys;
  // Die Parameter, die vor dem Speichern aus dem Objekt entfernt werden sollen
  removeParams: string[] = [];

  constructor(key: LocalDataServiceKeys, removeParams: string[] = []) {
    this.key = key;
    this.removeParams = removeParams;
  }

  /**
   * Speichert ein Objekt im LocalStorage
   * @param obj - das zu speichernde Objekt
   * @returns false, wenn das Objekt nicht gespeichert werden konnte, sonst das gespeicherte Objekt
   */
  public save(obj: T): false | StoredData<T> {
    if (this.removeParams) {
      this.removeParams.forEach((param: string) => {
        delete obj[param];
      });
    }
    const date: string = new Date().toISOString();
    obj.updated_at = date;
    try {
      return this.saveInLocalStorage(obj, date);
    } catch (err) {
      console.error('[LocalDataService][save]: Fehler beim Speichern des Objekts im LocalStorage', err, obj);
      return false;
    }
  }

  /**
   * Findet ein Objekt im LocalStorage
   * @param id - die ID des zu findenden Objekts
   * @returns das gefundene Objekt oder undefined, wenn das Objekt nicht gefunden wurde
   */
  public find(id: number): StoredData<T> | undefined {
    const data: LocalSaveObject<T> = this.getFromLocalStorage(id);
    if (data) {
      return {
        entityId: data.obj.id,
        entityType: this.key,
        content: data.obj,
        updatedAt: data.updatedAt,
      } as StoredData<T>;
    }
    return undefined;
  }

  /**
   * Lädt alle Objekte des Typs aus dem LocalStorage
   * @returns alle Objekte des Typs oder undefined, wenn keine Objekte vorhanden sind
   */
  public all(): T[] | undefined {
    const data: LocalSaveObject<T>[] = this.loadAllFromLocalStorage();
    if (!data) return undefined;
    return data.map((x: LocalSaveObject<T>) => x.obj);
  }

  /**
   * Löscht ein Objekt des Typs aus dem LocalStorage
   * @param id - die ID des zu löschenden Objekts
   * @returns true, wenn das Objekt gelöscht wurde, sonst false
   */
  public delete(id: number): boolean {
    const data: LocalSaveObject<T>[] = this.loadAllFromLocalStorage();
    if (data) {
      const index: number = data.findIndex((x: LocalSaveObject<T>) => x.obj.id === id);
      if (index !== -1) {
        data.splice(index, 1);
        if (data.length === 0) localStorage.removeItem(this.key);
        else localStorage.setItem(this.key, JSON.stringify(data));
        return true;
      }
    }
    return false;
  }

  /**
   * Löscht alle Objekte des Typs aus dem LocalStorage, indem der Key gelöscht wird
   */
  public deleteAll(): void {
    localStorage.removeItem(this.key);
  }

  ///////////////////////////////////////// HILFSMETHODEN //////////////////////////////////////////////////////////////

  /**
   * Hilfsmethode zum Speichern eines Objekts im LocalStorage
   * @param obj - das zu speichernde Objekt
   * @param updatedAt - das Datum, an dem das Objekt zuletzt aktualisiert wurde
   * @private
   * @returns false, wenn das Objekt nicht gespeichert werden konnte, sonst das gespeicherte Objekt
   */
  private saveInLocalStorage(obj: any, updatedAt: string): false | StoredData<T> {
    if (!GlobalHelper.isNumber(obj.id) || !this.key) return false;
    const data: LocalSaveObject<T>[] = this.loadAllFromLocalStorage();
    if (data) {
      const index: number = data.findIndex((x: LocalSaveObject<T>) => x.obj.id === obj.id);
      const saveObj: LocalSaveObject<T> = {
        obj,
        updatedAt,
      };
      if (index !== -1) {
        data[index] = saveObj;
      } else {
        data.push(saveObj);
      }
      localStorage.setItem(this.key, JSON.stringify(data));
    } else {
      localStorage.setItem(
        this.key,
        JSON.stringify([
          {
            obj,
            updatedAt,
          },
        ])
      );
    }
    return obj;
  }

  /**
   * Hilfsmethode zum Laden aller Objekte aus dem LocalStorage
   * @private
   * @returns alle Objekte aus dem LocalStorage oder undefined, wenn keine Objekte vorhanden sind
   */
  private loadAllFromLocalStorage(): LocalSaveObject<T>[] | undefined {
    const localData: string = localStorage.getItem(this.key);
    if (localData && localData.length > 0) {
      return JSON.parse(localData);
    }
    return undefined;
  }

  /**
   * Hilfsmethode zum Laden eines Objekts aus dem LocalStorage
   * @param id - die ID des zu ladenden Objekts
   * @private
   * @returns das Objekt aus dem LocalStorage oder undefined, wenn das Objekt nicht gefunden wurde
   */
  private getFromLocalStorage(id: number): LocalSaveObject<T> | undefined {
    const data: LocalSaveObject<T>[] = this.loadAllFromLocalStorage();
    if (data && data.length > 0) {
      return data.find((x: LocalSaveObject<T>) => Number(x.obj.id) === Number(id));
    }
    return undefined;
  }
}
