import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, } from '@angular/router';

export interface CanComponentDeactivate {
  canDeactivate: () => Promise<boolean>;
  isPopupShown: boolean;
}

@Injectable()
export class CanDeactivateGuard
  implements CanDeactivate<CanComponentDeactivate> {
  private calledPromise: Promise<boolean>;

  async canDeactivate(
    component: CanComponentDeactivate,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    if (!this.calledPromise) {
      this.calledPromise = component.canDeactivate();
    }

    const result: boolean = await this.calledPromise;
    this.calledPromise = null;
    return result;
  }
}
