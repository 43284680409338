import { DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { TimePipe } from './time.pipe';

@NgModule({
  providers: [TimePipe, DecimalPipe],
  declarations: [TimePipe],
  exports: [TimePipe],
})
export class TimePipeModule {}
