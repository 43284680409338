import { BasicModel } from 'src/app/models/basic';
import { BusinessModel } from 'src/app/models/settings/business';
import { SettingsModel } from 'src/app/models/settings/settings';
import { IHeaderAndFooterModel } from 'src/packages/templateManagement/interfaces';

/**
 * Enum für die Startseite des Mandanten.
 */
export enum MandatorHomePage {
  SYNC_QUEUE = 'SYNC_QUEUE',
  QUICK_START = 'QUICK_START',
  SERVICE_CONTRACTS = 'SERVICE_CONTRACTS'
}

/**
 * Mappt die {@link MandatorHomePage} auf den Pfad der Startseite.
 * @param homePage - Die Startseite des Mandanten
 */
export function mapHomePageEnumToPath(homePage: MandatorHomePage): string {
  switch (homePage) {
    case MandatorHomePage.SYNC_QUEUE:
      return 'dashboard-worker';
    case MandatorHomePage.QUICK_START:
      return 'quick-start';
    case MandatorHomePage.SERVICE_CONTRACTS:
      return 'orders';
    default:
      return 'dashboard-worker';
  }
}

/**
 * Deutschsprachige Auswahlmöglichkeiten für {@link MandatorHomePage}.
 */
export const MandatorHomePageDisplayOptions = [
  {
    id: MandatorHomePage.SYNC_QUEUE,
    name: 'Warteschlange'
  },
  {
    id:  MandatorHomePage.QUICK_START,
    name: 'Schnelleinstieg'
  },
  {
    id:  MandatorHomePage.SERVICE_CONTRACTS,
    name: 'Serviceaufträge'
  }
];

export interface MandatorModel extends BasicModel, IHeaderAndFooterModel {
  /** The city where the mandator is located */
  city: string;
  /** The country where is the mandator */
  country: string;
  /** A name of the mandator */
  name1: string;
  /** Another name of the mandator */
  name2: string;
  /** A third name of the mandator */
  name3: string;
  /** The street as part of the mandators address */
  street: string;
  /** The ZIP-Code of the mandator */
  zip: string;
  /** The remote_id for communicating with BüroWARE */
  remote_id: string;
  /** Settings for the mandator */
  settings: SettingsModel;
  /** And array of the mandator belonging businesses */
  businesses: BusinessModel[];
  /** The mandators company logo */
  logo_url: string;
  /** A header for correspondence */
  header_url: string;
  /** A footer for correspondence */
  footer_url: string;
  /** logo as blob to save in backend */
  logo: Blob[];
  /** report_header as blob to save in backend */
  report_header: Blob[];
  /** report_footer as blob to save in backend */
  report_footer: Blob[];
  /** logo as base64 to save in backend */
  logo64: string;
  /** header as base64 to save in backend */
  header64: string;
  /** footer as base64 to save in backend */
  footer64: string;
  /** Var for frequence of save position current device */
  position_value: number;
  /** Articelgroupname for Filter */
  aw_article_group: string;
  /** Articelgroupname for Filter */
  drive_article_group: string;
  // Die gewählte Startseite des Mandanten
  home_page: MandatorHomePage;
}
