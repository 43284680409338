import { Injectable } from '@angular/core';
import { ErrorLogService } from '../model-services/errorlog.service';
import { ErrorLogModel, ErrorLogResponseModel } from './../../models/errorlog';
import { SystemHelper } from './system-helper';

@Injectable({
  providedIn: 'root',
})
export class ErrorLogHelper {
  /** All the loaded errorlogs */
  errorlogs: ErrorLogResponseModel<ErrorLogModel>;
  /** How many errors, warnings and infos are there? */
  errorCounters: {
    errors: number;
    warnings: number;
    infos: number;
  };

  constructor(
    private readonly errorLogService: ErrorLogService,
    private readonly systemHelper: SystemHelper
  ) {
    this.reloadErrorlogs();
  }

  public reloadErrorlogs() {
    if (!this.systemHelper.isOnline) return;
    this.errorLogService.loadErrorlogs().subscribe((res) => {
      this.errorlogs = res;
      this.errorCounters = {
        errors: res.errors.length,
        warnings: res.warnings.length,
        infos: res.infos.length,
      };
    });
  }

  /**
   * Has the errorlog the given error title?
   * @param title to check for
   * @returns true if the errorlog contains the given error title
   */
  public has(title: string): boolean {
    return this.errorlogs
      ? this.errorlogs.errors.some((errorlog) => errorlog.title === title)
      : null;
  }

  /**
   * Has the errorlog any error logged?
   * @returns if the errorlog contains any error
   */
  public hasErrors(): boolean {
    return this.errorCounters ? this.errorCounters.errors > 0 : null;
  }

  /**
   * Has the errorlog any warning logged?
   * @returns if the errorlog contains any warning
   */
  public hasWarnings(): boolean {
    return this.errorCounters ? this.errorCounters.warnings > 0 : null;
  }

  /**
   * Has the errorlog any info logged?
   * @returns if the errorlog contains any info
   */
  public hasInfos(): boolean {
    return this.errorCounters ? this.errorCounters.infos > 0 : null;
  }

  /**
   * Has the errorlog any errors logged?
   * @returns true if the errorlog contains any errors
   */
  public hasAny(): boolean {
    return this.hasErrors() || this.hasWarnings() || this.hasInfos();
  }
}
