import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ITourMachineService, TourMachineModel } from 'src/packages/tourManagement/interfaces/machine.service.interface';
import { MachineModel } from 'src/app/models/machine';
import { MachineService } from '../machines/machine.service';

@Injectable({
    providedIn: 'root',
})
export class MachineServiceTourAdapter implements ITourMachineService {
    constructor(private machineService: MachineService) { }

    async all(): Promise<TourMachineModel[]> {
        const response = await firstValueFrom(this.machineService.allRemote());
        return response.data.map(this.toTourMachineModel);
    }

    private toTourMachineModel(machine: MachineModel): TourMachineModel {
        return {
            id: machine.id,
            name: machine.name,
            inventory_number: machine.inventory_number,
            stand: machine.stand,
            customer_location_id: machine.customer_location_id
        };
    }
}