import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SyncProcessorService } from '@vending/sync-engine-client';
import { Guid } from 'guid-typescript';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { catchError, firstValueFrom, Observable, retry } from 'rxjs';
// internal
import { toFormData } from 'src/app/components/mits-files-upload/mits-files-upload-select/helpers/formDataParser';
import { ImageModel } from 'src/app/models/system/image';
import { ErrorService } from 'src/app/providers/error.service';
import { EventsService } from 'src/app/providers/events.service';
import { OfflineDataService } from 'src/app/providers/offlineData.service';
import { GlobalHelper } from 'src/packages/mitsBasics/helpers/globalHelper/global.helper';

@Injectable({
  providedIn: 'root',
})
export class ObjectImageService extends OfflineDataService<ImageModel> {
  constructor(
    public indexedDBService: NgxIndexedDBService,
    public syncProcessor: SyncProcessorService,
    public http: HttpClient,
    public errorService: ErrorService,
    public events: EventsService
  ) {
    super(
      indexedDBService,
      syncProcessor,
      'System::ObjectImage',
      http,
      errorService,
      events,
      'system/object_images/',
      'system_object_image',
      [],
      [],
      undefined,
      false,
      false, // Verhindern, dass die ObjectImages nach erfolgreichem Warteschlangenupload lokal persistiert werden.
    );
  }

  /**
   * Name des Icons für die Klasse
   * @return string - Name des Icons
   */
  public get iconName(): string {
    return 'image-outline';
  }

  /**
   * Bild zu Objekten vorher versenden. Ein Bild pro Objekt
   * @param {any[]} objects
   * @param {string} attributeImage Gibt an welches Attribut das Bild enthält
   * @param {string} attributeRemoteId RemoteId des Bildes. Wird hier gesetzt
   * @param mapper
   * @param mode - online/offline
   */
  public async sendImages(
    objects: any | any[],
    attributeImage: string,
    attributeRemoteId?: string,
    mapper?: (i: any) => string | File,
    mode: 'online' | 'offline' = 'online'
  ) {
    if (!objects) return;
    if (!Array.isArray(objects)) objects = [objects];
    for (const obj of objects) {
      let image = obj[attributeImage];
      if (mapper && image) image = mapper(image);
      if (!image) return;
      if (Array.isArray(image)) {
        await Promise.all(
          image.map(async (img) => {
            if (!img) return;
            const guid = Guid.create().toString();
            if (attributeRemoteId) {
              if (!obj[attributeRemoteId]) obj[attributeRemoteId] = [];
              obj[attributeRemoteId].push(guid.toString());
            }
            return await this.buildAndSaveFile(img, obj, guid, mode);
          })
        );
      } else {
        const guid = Guid.create().toString();
        if (attributeRemoteId) {
          obj[attributeRemoteId] = guid;
        }
        await this.buildAndSaveFile(image, obj, guid, mode);
      }
      delete obj[attributeImage];
    }
  }

  /**
   * Speichern auf Service
   * @param {ImageModel} objectImage
   * @return {Observable<ImageModel>}
   */
  saveRemote(objectImage: ImageModel): Observable<ImageModel> {
    if (objectImage.id >= 0) {
      return this.http
        .put<ImageModel>(
          this.endpointWithUrl + objectImage.id,
          toFormData(objectImage, this, ['image'])
        )
        .pipe(retry(1), catchError(this.errorService.convert));
    } else {
      return this.http
        .post<ImageModel>(
          this.endpointWithUrl,
          toFormData(objectImage, this, ['image'])
        )
        .pipe(retry(1), catchError(this.errorService.convert));
    }
  }

  private async buildAndSaveFile(
    file: any,
    obj: any,
    guid: string,
    mode: 'online' | 'offline' = 'online'
  ): Promise<ImageModel> {
    if (file.toString().startsWith('data:image')) {
      file = await GlobalHelper.base64ToFile(file, `${guid}.png`);
    }
    const imageData = this.buildImage(
      file,
      guid,
      obj.for_object_type || null,
      obj.for_object_id || null
    );
    if(mode === 'offline') {
      return await firstValueFrom(this.save(imageData))
    } else if(mode === 'online') {
      return await firstValueFrom(this.saveRemote(imageData));
    }
  }

  /**
   * Object zum Versenden vorbereiten
   * @param {File} file
   * @param {string} guid
   * @return {ImageModel}
   */
  private buildImage(
    file: File,
    guid: string,
    type?: string,
    id?: number
  ): ImageModel {
    return {
      image: file,
      remote_id: guid,
      for_object_type: type,
      for_object_id: id,
    } as ImageModel;
  }
}
