import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SyncProcessorService, SyncQueueService, } from '@vending/sync-engine-client';
import { QueueEntry } from '@vending/sync-engine-client/lib/models/queueEntry';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { catchError, Observable, retry, tap } from 'rxjs';
// internal
import { TimelogModel } from 'src/app/models/system/timelog';
import { ErrorService } from 'src/app/providers/error.service';
import { EventsService } from 'src/app/providers/events.service';
import { OfflineDataService } from 'src/app/providers/offlineData.service';

@Injectable({
  providedIn: 'root',
})
export class TimelogService extends OfflineDataService<TimelogModel> {
  constructor(
    public indexedDBService: NgxIndexedDBService,
    public syncProcessor: SyncProcessorService,
    public http: HttpClient,
    public errorService: ErrorService,
    public events: EventsService,
  ) {
    super(
      indexedDBService,
      syncProcessor,
      'System::Timelog',
      http,
      errorService,
      events,
      'system/timelogs/',
      'timelog',
      ['created_at', 'updated_at', 'remote_id'],
      ['modules'],
      'trackable_id',
      true,
    );
    this.bulkSyncActive = true;
  }

  //////////////////////////////////////////////////////////////
  //// Öffentliche Methoden

  /**
   * Suche nach einem Timelog in der Queue
   * @param id
   * @return Promise<QueueEntry<TimelogModel>> - der gefundene Timelog
   */
  async findQueued(id: number): Promise<QueueEntry<TimelogModel>> {
    return await this.queueService.getQueuedId(id);
  }

  /**
   * Aktualisiert einen Timelog in der Queue
   * @param queueEntry - der zu aktualisierende Timelog
   * @return Promise<number> - die ID des aktualisierten Timelogs
   */
  async updateQueued(queueEntry: QueueEntry<TimelogModel>): Promise<number> {
    return await this.queueService.updateQueued(queueEntry);
  }

  /**
   * Name des Icons für die Klasse
   * @return {string}
   */
  public get iconName(): string {
    return 'timer-outline';
  }

  //////////////////////////////////////////////////////////////
  //// Überschriebene Methoden des OfflineDataService

  /**
   * Speichert ein Timelog-Objekt remote.
   * Info: Diese Methode wird überschrieben, da beim Queue-Upload der Timelogs, Timelog-Corrections speziell behandelt
   * werden müssen.
   * @param incomingTimelog - das remote zu speichernde Timelog-Objekt
   * @return Observable<TimelogModel> - das gespeicherte Timelog-Objekt
   * TODO: Refactoring und kommentieren.
   */
  public override saveRemote(incomingTimelog: TimelogModel): Observable<TimelogModel> {
    const timelog: TimelogModel = this.cloneObjekt(incomingTimelog);
    let negativeTimelogId = 0;
    if (timelog.id <= 0) {
      negativeTimelogId = timelog.id;
      delete timelog.id;
    }

    this.removeNotToUseParams(timelog);
    this.renameAttributedParams(timelog);

    if (timelog.id > 0) {
      return this.http
        .put<TimelogModel>(
          this.endpointWithUrl + timelog.id,
          this.formatPayload(timelog),
          this.dataService.httpOptions
        )
        .pipe(retry(1), catchError(this.errorService.convert));
    } else {
      return this.http
        .post<TimelogModel>(
          this.endpointWithUrl,
          this.formatPayload(timelog),
          this.dataService.httpOptions
        )
        .pipe(
          tap(async (res) => {
            if (negativeTimelogId < 0) {
              await this.updateQueuedTimelogCorrections(negativeTimelogId, res);
              await this.localDelete(negativeTimelogId);
            }
          })
        )
        .pipe(retry(1), catchError(this.errorService.convert));
    }
  }


  //////////////////////////////////////////////////////////////
  //// Private Hilfsmethoden

  /**
   * Aktualisiert die enqueued TimelogCorrections und macht sie zum Versand bereit
   * Das muss gemacht werden, wenn die TimelogID vorher negativ war.
   * @param negativeTimelogId
   * @param res
   * TODO: Gehört das nicht in den Timelog-Correction-Service?!
   */
  private async updateQueuedTimelogCorrections(
    negativeTimelogId: number,
    res: TimelogModel
  ): Promise<void> {
    let dataQueued: QueueEntry<any>[] =
      (await this.queueService.getQueuedData()) as QueueEntry<TimelogModel>[];

    const service: SyncQueueService<unknown> = new SyncQueueService(this.indexedDBService, '');

    dataQueued = dataQueued
      .filter((q: QueueEntry<any>): boolean => q.entityType === 'System::TimelogCorrection')
      .filter((q: QueueEntry<any>): boolean => q.content.timelog_id === negativeTimelogId);
    dataQueued.forEach((q: QueueEntry<any>): void => {
      q.content.timelog_id = res.id;
      q.ready = true;

      service.update(q);
    });
  }
}
