export enum TimelogState {
  // Allgemeine / Technische Zustände
  Unkown = 'UNKOWN', // Unbekannt
  Previous = 'Previous', // Vorheriger Zustand

  // Spezifische Zustände
  Working = 'Work', // Arbeitszeit
  DayStarting = 'DayStarting', // Tagesstart
  DayEnding = 'DayEnding', // Tagesende
  Individual = 'Individual', // Individuell
  CarEquiping = 'CarEquip', // Fahrzeug rüsten,
  CarDriving = 'CarDriving', // Fahren
  CustomerWorking = 'CustomerWorking', // Kundenzeit
  MachineWorking = 'MachineWorking', // Automaten Zeit
  BufferstockEquiping = 'BufferstockEquiping', // Bufferstock rüsten
}
