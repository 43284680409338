import { Directive, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appUrlPreview]',
  standalone: true,
})

/**
 * Diese Directive kann auf ein Element gelegt werden und die url eines images festgelegt werden,
 * welches bei einem clickevent ein "Preview bekommt"
 */
export class ImagePreviewDirective {
  @Input('appUrlPreview') url: string;

  constructor(private renderer: Renderer2) {}

  @HostListener('click')
  onClick() {
    this.showUrlPreview(this.url);
  }
  showUrlPreview(url: string) {
    const overlay = this.renderer.createElement('div');
    this.renderer.setStyle(overlay, 'position', 'fixed');
    this.renderer.setStyle(overlay, 'top', '0');
    this.renderer.setStyle(overlay, 'left', '0');
    this.renderer.setStyle(overlay, 'width', '100vw');
    this.renderer.setStyle(overlay, 'height', '100vh');
    this.renderer.setStyle(overlay, 'background', 'rgba(0, 0, 0, 0.5)');
    this.renderer.setStyle(overlay, 'display', 'flex');
    this.renderer.setStyle(overlay, 'justify-content', 'center');
    this.renderer.setStyle(overlay, 'align-items', 'center');
    this.renderer.setStyle(overlay, 'z-index', '1000');

    const content = this.renderer.createElement('div');
    this.renderer.setStyle(content, 'max-width', '90%');
    this.renderer.setStyle(content, 'max-height', '90%');

    if (this.isImage(url)) {
      const img = this.renderer.createElement('img');
      this.renderer.setAttribute(img, 'src', url);
      this.renderer.setStyle(img, 'max-width', '100%');
      this.renderer.setStyle(img, 'max-height', '100%');
      this.renderer.appendChild(content, img);
    }

    this.renderer.appendChild(overlay, content);

    this.renderer.listen(overlay, 'click', () => {
      this.renderer.removeChild(document.body, overlay);
    });

    this.renderer.appendChild(document.body, overlay);
  }

  private isImage(url: string): boolean {
    return url.match(/\.(jpeg|jpg|gif|png)$/) != null;
  }
}
