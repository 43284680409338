import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'time' })
export class TimePipe implements PipeTransform {
  constructor(private readonly decimalPipe: DecimalPipe) {}

  transform(value: number, includeMillis?: boolean): string {
    if (!value) {
      return '00:00' + (includeMillis ? '.000' : '');
    }
    const millis = value;
    const seconds = Math.floor(millis / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const strMillis = this.decimalPipe.transform(millis % 1000, '3.0');
    const strSeconds = this.decimalPipe.transform(
      Math.floor(seconds % 60),
      '2.0'
    );
    const strMinutes = this.decimalPipe.transform(minutes % 60, '2.0');
    const strHours = this.decimalPipe.transform(hours, '2.0');
    let str = `${strMinutes}:${strSeconds}`;
    if (hours > 0) str = `${strHours}:${strMinutes}:${strSeconds}`;
    if (includeMillis) str = `${str}.${strMillis}`;
    return str;
  }
}
