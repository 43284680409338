import {Routes} from '@angular/router';

export const INVENTORY_SCHEDULES_BASE_ROUTE = 'inventory-schedules';

export const InventorySchedulesRoutes: Routes = [
    {
        path: '',
        loadComponent: () => import('./pages/inventory-schedules/inventory-schedules.page')
            .then((m) => m.InventorySchedulesPage),
    },
    {
        path: 'details/:id',
        loadComponent: () => import('./pages/inventory-schedule-details/inventory-schedule-details.page')
          .then((m) => m.InventoryScheduleDetailsPage),
    },
];