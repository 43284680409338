// tslint:disable: no-redundant-jsdoc
// tslint:disable: no-console
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {
  OfflineQueueProcessorService,
  SyncError,
  SyncProcessorService,
  SyncQueueService,
  SyncService,
} from '@vending/sync-engine-client';
import { StoredData } from '@vending/sync-engine-client/lib/models/storedData';
import { SyncInformation } from '@vending/sync-engine-client/lib/models/syncInformation';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { firstValueFrom, Observable } from 'rxjs';
// internal
import { BasicModel } from 'src/app/models/basic';
import { ResponseModel } from 'src/app/models/response';
import { DataService } from 'src/app/providers/data.service';
import { ErrorService } from 'src/app/providers/error.service';
import { EventsService } from 'src/app/providers/events.service';
import { IDataService } from 'src/app/providers/idataService';

// Interval fuer den Warteschlangenupload in Millisekunden
export const OFFLINE_DATA_SERVICE_UPLOAD_INTERVAL = 120 * 1000;

export class OfflineDataService<T extends BasicModel>
  extends SyncService<T>
  implements IDataService<T> {
  // Warteschlange
  protected queueService: OfflineQueueProcessorService<T>;

  // Datenverbindung zum Backend
  protected dataService: DataService<T>;
  // Offlinedaten-Validierung wurde bereits für diesen App-Lebenszyklus durchgeführt
  private alreadyValidated: boolean = false;

  /**
   * Default-Constructor
   * @param {NgxIndexedDBService} indexedDBService
   * @param {SyncProcessorService} syncProcessor
   * @param {string} type
   * @param {HttpClient} http
   * @param {ErrorService} errorService
   */
  constructor(
    public indexedDBService: NgxIndexedDBService,
    public syncProcessor: SyncProcessorService,
    public type: string,
    public http: HttpClient,
    public errorService: ErrorService,
    public events: EventsService,
    endpoint: string,
    objectName: string,
    removeParams: string[],
    attributedParams: string[],
    configurableId: string | undefined = undefined,
    deletePreviousEntries: boolean = false,
    storeLocalAfterSaveRemote: boolean = true
  ) {
    super(indexedDBService, syncProcessor, type, configurableId);

    const syncQueueService = new SyncQueueService<T>(
      indexedDBService,
      this.type
    );
    syncQueueService.deletePreviousEntries = deletePreviousEntries;

    this.queueService = new OfflineQueueProcessorService<T>(
      OFFLINE_DATA_SERVICE_UPLOAD_INTERVAL, // Interval in Millisekunden
      syncQueueService,
      (obj: T) => {
        return new Promise(async (resolve, reject) => {
          firstValueFrom(this.saveRemote(obj))
            .then(async (result) => {
              this.events.publish(`${this.type}.created`, {});
              if (storeLocalAfterSaveRemote) {
                await this.saveLocal(result);
              }
              resolve(true);
            })
            .catch((err: HttpErrorResponse) => {
              reject(new SyncError(err.status, err.error));
            });
        });
      },
      this.type,
      this
    );

    this.dataService = new DataService<T>(this.http, this.errorService);
    this.dataService.endpoint = endpoint;
    this.dataService.objectName = objectName;
    this.dataService.removeParams = removeParams;
    this.dataService.attributedParams = attributedParams;
  }


  /**
   * Zeigt an, ob die Offlinedaten Validierung durchgeführt werden soll
   * @return {boolean} true, wenn validiert werden soll
   */
  protected checkAlreadyValidated(): boolean {
    if (!this.alreadyValidated) {
      this.alreadyValidated = true;
      return true;
    }
    return false;
  }

  /*
  * Lässt eine erneute Offlinedaten-Validierung innerhalb des App-Zykluses zu
  */ 
  public resetAlreadyValidated(): void{
    this.alreadyValidated = false;
  }

  public get queueTimestamp(): Date | null {
    return this.queueService.lastSyncDate;
  }

  /**
   * Sind noch Daten zum versenden vorhanden?
   * @return {Promise<boolean>}
   */
  public async hasDataQueued(): Promise<boolean> {
    const entries = await this.queueService.getQueuedData();

    return (
      entries.filter((e) => e.entityType === this.type && e.ready).length > 0
    );
  }

  /**
   * Name des Icons für die Klasse
   * @return {string}
   */
  public get iconName(): string {
    return 'cafe-outline';
  }

  whereRest(args: any): Observable<ResponseModel<T>> {
    throw new Error('Method not implemented.');
  }

  deleteRest(id: any): Observable<T> {
    throw new Error('Method not implemented.');
  }

  /**
   * Enqueue Object
   * @param {T} object
   * @param {string} updateDate
   * @return {Observable<T>}
   */
  protected queue(object: T, updateDate: string): Observable<T> {
    return new Observable<T>((subscriber) => {
      this.queueService
        .queue(object)
        .then((v) => {
          subscriber.next(object);
          subscriber.complete();
        })
        .catch((e) => {
          subscriber.complete();
        });
    });
  }

  get url(): string {
    return this.dataService.url;
  }

  get endpointWithUrl(): string {
    return this.dataService.endpointWithUrl;
  }

  get removeParams(): string[] {
    return this.dataService.removeParams;
  }

  get objectName(): string {
    return this.dataService.objectName;
  }

  get endpoint(): string {
    return this.dataService.endpoint;
  }

  get attributedParams(): string[] {
    return this.dataService.attributedParams;
  }

  /**
   * Erhält alle Objekte vom Endpunkt (Begrenzt auf die erste Page (25)).
   */
  all(): Observable<ResponseModel<T>> {
    return new Observable((subscriber) => {
      this.localAllwithPaging().then((result) => {
        subscriber.next(result);
        subscriber.complete();
      });
    });
  }

  /**
   * Erhält alle Objekte aus der lokalen Datenbank (ohne Paging).
   * @return Ein Observable das alle Objekte des Entitätstyps T aus der lokalen Datenbank beinhaltet.
   */
  allWithOutPaging(): Observable<T[]> {
    return this.localAll();
  }

  /**
   * Erhält alle Objecte vom Endpunkt.
   */
  allRemote(): Observable<ResponseModel<T>> {
    return this.dataService.all();
  }

  /**
   * Synchronisiert einen Datensatz in den lokalen bestand.
   * Sollte er serverseitig gelöscht worden sein, wird dieser auch lokal entfernt
   * @param {SyncInformation} info
   * @return {Promise<boolean>}
   */
  // tslint:disable-next-line: ban-types
  override async sync(info: SyncInformation): Promise<boolean> {
    const result = super.sync(info);
    this.sendReloadDataEvent();
    return result;
  }

  /**
   * Ein Image vorladen von einer URL. Wird gecached sofern ServiceWorker dafür eine URL hat
   * @param {string} url
   */
  protected preloadImage(url: string) {
    if (url) {
      const image = new Image();
      image.src = url;
    }
  }

  /**
   * Daten aus dem lokalen Datenbestand löschen
   * @param {number} id
   * @return {Promise<any>}
   */
  public override async localDelete(id: number): Promise<any> {
    const result = await super.localDelete(id);
    this.sendReloadDataEvent();
    return result;
  }

  /**
   * Reload Data senden
   */
  private sendReloadDataEvent() {
    this.events.publish('reloadData', {});
  }

  /**
   * Synchronisierung einmal starten
   */
  public startSyncOnce() {
    this.queueService.runSyncOnce();
  }

  /**
   * Synchronisierung starten
   */
  public startSync() {
    this.queueService.startSync();
  }

  /**
   * Synchronisierung stoppen
   */
  public stopSync() {
    this.queueService.stopSync();
  }

  /**
   * Ruft ein Objekt mit Details ab
   * @param id
   */
  find(id): Observable<T> {
    return new Observable((subscriber) => {
      this.localFind(id)
        .then((result) => {
          if (result) {
            subscriber.next(result.content);
          } else {
            subscriber.next(undefined);
          }
          subscriber.complete();
        })
        .catch((error) => {
          subscriber.next(undefined);
          subscriber.complete();
        });
    });
  }


  /**
   * Ruft lokal mehrere Objekte auf einmal ab
   * @param ids der Objekte, die abgerufen werden sollen
   */
  bulkFind(ids: number[]): Observable<T[]> {
    return new Observable((subscriber) => {
      this.localBulkFind(ids)
        .then((result: StoredData<T>[]) => {
          if (result) {
            const entities: T[] = result
              .filter((r) => r && r.content) // Rausfiltern von nicht gefundenen Objekten
              .map((r) => r.content);
            subscriber.next(entities);
          } else {
            subscriber.next(undefined);
          }
          subscriber.complete();
        })
        .catch((error) => {
          subscriber.next(undefined);
          subscriber.complete();
        });
    });
  }

  /**
   * Probiert den Aritkel erst local und dann online zu finden
   * @param id
   * @returns
   */
  async findLocalThenRemote(id): Promise<T> {
    const localArticle = await this.localFind(id);
    if (localArticle) {
      return localArticle.content;
    } else {
      const remoteArticle = await firstValueFrom(this.findRemote(id));
      await this.saveLocalForce(remoteArticle);
      return remoteArticle;
    }
  }

  /**
   * Mehrere Elemente anhand des Primärschlüssels suchen
   * @param {number[]} ids
   * @return {Promise<T[]>}
   */
  findMultiple(ids: number[]): Promise<T[]> {
    return Promise.all(
      ids.map(async (id) => {
        const result = await this.localFind(id);
        return result ? result.content : undefined;
      })
    );
  }

  /**
   * Objekt tief clonen. Ist nicht sehr performance orientiert, aber am simpelsten
   * @param {any} object
   * @return {any}
   */
  protected cloneObjekt(object: any): any {
    return this.dataService.cloneObjekt(object);
  }

  /**
   * Ruft ein Objekt mit Details ab
   * @param {number} id
   * @return {Observable<T>}
   */
  findRemote(id: number): Observable<T> {
    return this.dataService.find(id);
  }

  bulkFindRemote(ids: number[]): Observable<T[]> {
    return this.dataService.bulkFindRemote(ids);
  }

  /**
   * Sucht Objekte mit Argumenten
   * @param args
   */
  where(args): Observable<ResponseModel<T>> {
    return new Observable((subscriber) => {
      this.localWhere(args).then((result) => {
        subscriber.next(result);
        subscriber.complete();
      });
    });
  }

  /**
   * Sucht Objekte mit Argumenten ohne OfflineStorage
   * @params args
   */
  whereRemote(args): Observable<ResponseModel<T>> {
    return this.dataService.where(args);
  }

  /**
   * Negative Zufallszahl generieren
   * @return {number}
   */
  public getRandomId(): number {
    return (Math.floor(Math.random() * 99999) + 1) * -1;
  }

  saveAndGetQueued(object: T): Observable<{ queuedEntryId: number; obj: T }> {
    const updateDate = new Date().toISOString();
    object.updated_at = updateDate;
    if (!object.id) object.id = this.getRandomId();
    return new Observable<{ queuedEntryId: number; obj: T }>((subscriber) => {
      this.queueService
        .queue(object)
        .then((v) => {
          subscriber.next({
            queuedEntryId: v,
            obj: object,
          });
          this.sendReloadDataEvent();
          subscriber.complete();
        })
        .catch((e) => {
          subscriber.complete();
        });
    });
  }

  /**
   * Object speichern
   * @param {T} object
   * @return {Observable<T>}
   */
  save(object: T): Observable<T> {
    return new Observable<T>((subscriber) => {
      this.saveAndGetQueued(object).subscribe((result) => {
        subscriber.next(result.obj);
        subscriber.complete();
      });
    });
  }

  /**
   * Speichern forced mit neuen Timestamp
   * @param object
   * @returns
   */
  saveLocalForce(object: T): Promise<boolean | StoredData<T>> {
    object.updated_at = new Date().toISOString();
    return this.saveLocal(object);
  }

  /**
   * Speichert ein Objekt in der IndexdDB
   * @param {T} object
   */
  saveLocal(object: T): Promise<boolean | StoredData<T>> {
    if (!object.updated_at) object.updated_at = new Date().toISOString();
    if (!object.id) object.id = this.getRandomId();
    return this.store(object, object.updated_at + '');
  }

  /**
   * Erzeugt/Speichert ein Objekt im Backend (je nachdem ob ID gesetzt)
   * @param object Zu speicherndes/erzeugendes Objekt
   */
  saveRemote(object: T): Observable<T> {
    return this.dataService.save(object);
  }

  /**
   * Löscht ein Objekt ohne offline
   * @param {number} id
   */
  deleteRemote(id: number): Observable<any> {
    return this.dataService.delete(id);
  }

  /**
   * Löscht ein Objekt im Backend
   * @param id
   */
  delete(id: number): Observable<T> {
    return new Observable((subscriber) => {
      this.internalDelete(id).then((obj: T) => {
        if (id > 0) {
          this.dataService.delete(id).subscribe(
            (result) => {
              subscriber.next(result);
              subscriber.complete();
            },
            (error) => {
              console.warn('Could not delete ', this.type, id, error);
              this.saveLocalForce(obj).then(() => {
                console.debug('RESTORED');
              });
              subscriber.error();
            }
          );
        } else {
          subscriber.next(obj);
          subscriber.complete();
        }
      });
    });
  }

  private async internalDelete(id: number): Promise<T> {
    const obj = await this.localFind(id);
    await this.deleteWithQueue(id);
    return obj?.content;
  }

  /**
   * Payload formatieren
   * @param {T} obj
   * @return {string}
   */
  formatPayload(obj: T) {
    return this.dataService.formatPayload(obj);
  }

  /**
   * Entfernt Parameter welche nicht gesendet werden sollen
   * @param hash to remove params of
   */
  removeNotToUseParams(hash: any) {
    return this.dataService.removeNotToUseParams(hash);
  }

  /**
   * Remove not used attributes
   * @param {any} hash
   */
  renameAttributedParams(hash: any) {
    return this.dataService.renameAttributedParams(hash);
  }

  async getLocalCount(): Promise<number> {
    return await this.localCount(IDBKeyRange.bound(this.type, this.type));
  }

  getObjectName(): string {
    return this.dataService.objectName;
  }

  /**
   * has the object already been saved?
   * @param {BasicModel} obj
   * @return {boolean}
   */
  protected isNew(obj: BasicModel): boolean {
    return !obj.id || obj.id <= 0;
  }


  /**
   * Setzt die Konfigurierbare ID für alle Einträge in der lokalen Datenbank neu durch ein Update aller Entries
   */
  public async rebuildConfigurableIds(): Promise<void> {
    return await this.setConfigurableIdForAllEntries()
      .catch((error) => {
        console.error(`[OfflineDataService]: Error while rebuilding configurableID-Index for Type ${this.type}`, error);
      });
  }
}
