import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

export const WAREHOUSE_BASE_ROUTE = 'warehouse';

const routes: Routes = [
  {
    path: 'baskets/:id',
    loadChildren: () =>
      import(
        './pages/backend/baskets/basket-details/basket-details.module'
      ).then((m) => m.BasketDetailsPageModule),
  },
  {
    path: 'orders',
    loadChildren: () =>
      import('./pages/backend/orders/orders.module').then(
        (m) => m.WarehouseOrdersPageModule
      ),
  },
  {
    path: 'bufferstocks/:id',
    loadChildren: () =>
      import('./pages/bufferstock-details/bufferstock-details.module').then(
        (m) => m.BufferstockDetailsComponentModule
      ),
  },
  {
    path: 'bufferstocks/:id/booking/:type',
    loadChildren: () =>
      import('./pages/stock-booking/stock-booking.module').then(
        (m) => m.StockBookingPageModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class WarehouseRoutingModule {}
