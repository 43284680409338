import { Injectable } from '@angular/core';
import { DataService } from 'src/app/providers/data.service';
import { DefaultLoadlistModel } from '../models';

@Injectable({
  providedIn: 'root',
})
export class DefaultLoadlistService extends DataService<DefaultLoadlistModel> {
  endpoint = 'car_management/default_loadlists/';
  objectName = 'default_loadlist';

  removeParams = [
    'client_ok',
    'default_loadlist_id',
    'article_name',
    'article_number',
    'amount_old',
    'created_at',
    'updated_at',
    'created_by_id',
    'deleted_at',
  ];
}
