import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SyncProcessorService } from '@vending/sync-engine-client';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { Observable } from 'rxjs';
import { ErrorService } from 'src/app/providers/error.service';
import { EventsService } from 'src/app/providers/events.service';
import { OfflineDataService } from 'src/app/providers/offlineData.service';
import { BufferstockCleanupModel } from '../models/bufferstock-cleanup';

@Injectable({
  providedIn: 'root',
})
export class BufferstockCleanupService extends OfflineDataService<BufferstockCleanupModel> {
  constructor(
    public indexedDBService: NgxIndexedDBService,
    public syncProcessor: SyncProcessorService,
    public http: HttpClient,
    public errorService: ErrorService,
    public events: EventsService
  ) {
    super(
      indexedDBService,
      syncProcessor,
      'Warehouse::BufferstockCleanup',
      http,
      errorService,
      events,
      'warehouse/bufferstock_cleanups/',
      'warehouse_bufferstock_cleanup',
      ['created_at', 'updated_at'],
      ['positions', 'position_disposals'],
      null,
      false,
      true,
    );
  }

  /**
   * Erzeugt/Speichert ein Objekt im Backend (je nachdem ob ID gesetzt)
   * @param object Zu speicherndes/erzeugendes Objekt
   */
  override saveRemote(
    object: BufferstockCleanupModel
  ): Observable<BufferstockCleanupModel> {
    const cloned = { ...object };
    delete cloned.bufferstock;
    delete cloned.linked_to;
    cloned.positions = cloned.positions.map((pos) => {
      delete pos.bufferstock;
      return pos;
    });
    cloned.position_disposals = cloned.position_disposals.map((pos) => {
      delete pos.bufferstock;
      return pos;
    });
    return this.dataService.save(cloned);
  }
}
