import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { DataService } from 'src/app/providers/data.service';
import { CarArticleModel } from '../models';

@Injectable({
  providedIn: 'root',
})
export class CarArticleService extends DataService<CarArticleModel> {
  endpoint = 'car_management/articles/';
  objectName = 'article';

  /**
   * Ruft ein Objekt mit Details ab
   * @param id
   */
  findEAN(ean): Observable<any> {
    return this.http
      .get<any>(this.endpointWithUrl + '?ean_code=' + ean)
      .pipe(retry(1), catchError(this.errorService.convert));
  }
}
