import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
// internal
import { ResponseModel } from 'src/app/models/response';
import { FunctionSwitchModel } from 'src/app/models/settings/function-switch';
import { FunctionSwitchTriggerConfigModel } from 'src/app/models/settings/function-switch-config';
import { DataService } from 'src/app/providers/data.service';

@Injectable({
  providedIn: 'root',
})
export class FunctionSwitchService extends DataService<FunctionSwitchModel> {
  endpoint = 'system/function_switches/';
  objectName = 'system_function_switch';

  endpointWithoutPaginate = this.endpointWithUrl + 'all/';
  endpointForConfig = this.endpointWithUrl + 'show_information/';
  endpointForFilterValues = this.endpointWithUrl + 'filter_values/';

  /**
   * Ruft alle Funktionsschalter ohne Pagination ab
   * @returns ResponseModel<FunctionSwitchModel> - Alle Funktionsschalter
   */
  allWithoutPaginate(): Observable<ResponseModel<FunctionSwitchModel>> {
    return this.http
      .get<ResponseModel<FunctionSwitchModel>>(this.endpointWithoutPaginate)
      .pipe(retry(1), catchError(this.errorService.convert));
  }

  /**
   * Ruft die möglichen Werte für die Filterung nach einem bestimmten Attribut ab
   * @param attribute Attribut, nach dem gefiltert werden soll
   * @returns string[] - Mögliche Werte für die Filterung
   */
  public filterValues(attribute: keyof FunctionSwitchModel): Observable<string[]> {
    return this.http
      .get<string[]>(this.endpointForFilterValues + attribute)
      .pipe(retry(1), catchError(this.errorService.convert));
  }

  /**
   * Ruft die Konfiguration für einen bestimmten Schalter ab
   * @param id ID des Funktionsschalters
   * @returns Konfiguration zu den getriggerten Funktionsschaltern
   */
  getConfig(id: number): Observable<FunctionSwitchTriggerConfigModel> {
    return this.http
      .get<any>(this.endpointForConfig + id)
      .pipe(retry(1), catchError(this.errorService.convert));
  }
}
