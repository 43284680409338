<ion-content>
  <ion-list inset class="ion-no-padding">
    <ion-item color="danger">
      <ion-icon name="information-circle-outline" slot="start"></ion-icon>
      <ion-text color="light" class="ion-text-center">
        <strong>
          Zustandswechsel erforderlich
        </strong>
      </ion-text>
      <ion-icon name="information-circle-outline" slot="end"></ion-icon>
    </ion-item>
  </ion-list>

  <ion-list inset>
    <ion-item>
      <div>
        <ion-text class="ion-padding-bottom" color="primary">
          <strong>
            Diese Funktion steht Ihnen innerhalb des aktuellen Zustands nicht zur Verfügung.
          </strong>
        </ion-text>
        <ion-text class="ion-padding-bottom" color="tertiary">
          Die Aktion <strong>"{{ eventName }}"</strong> ist im aktuellen Zustand <strong>"{{ stateName }}"</strong> nicht möglich.
        </ion-text>
        <ion-text color="tertiary">
          Sie müssen vorher in eine anderen Zustand wechseln!
        </ion-text>
      </div>
    </ion-item>
  </ion-list>

  <ion-list inset class="ion-no-padding">
    <ion-item detail button color="primary" (click)="openTimeLogPage()">
      <ion-label color="light">
        <strong>
          Zur Zeiterfassung
        </strong>
      </ion-label>
    </ion-item>
  </ion-list>
</ion-content>

<ion-footer>
  <ion-row>
    <ion-col>
      <ion-button expand="block" color="danger" (click)="closeModal()">Schließen</ion-button>
    </ion-col>
  </ion-row>
</ion-footer>