import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { StoredData } from '@vending/sync-engine-client/lib/models/storedData';
import { firstValueFrom } from 'rxjs';
import { AccessGuard } from 'src/app/providers/access.guard';
import { ToastService } from '../component-helpers/toast.service';
import { ToggleMenuHelper } from '../component-helpers/toggle-menu-helper.service';
import { IDataService } from '../idataService';
import { FunctionSwitchHelperService } from './../component-helpers/function-switch.service';
import { ErrorService } from './../error.service';
import { OfflineDataService } from './../offlineData.service';
import { DeviceService } from './device.service';

@Injectable({
  providedIn: 'root',
})
export class PageService extends DeviceService {
  sortDirection = 'DESC';
  search = '';

  protected navBackUrl: string | undefined;

  constructor(
    public readonly accessGuard: AccessGuard,
    public readonly device: Platform,
    public readonly errorService: ErrorService,
    public readonly functionSwitchHelper: FunctionSwitchHelperService,
    public readonly location: Location,
    public readonly route: ActivatedRoute,
    public readonly router: Router,
    public readonly toastService: ToastService,
    public readonly toggleMenuHelper: ToggleMenuHelper
  ) {
    super(device);
    this.loadNavBackUrl();
  }

  /**
   * Loads data from given service into given variable with offline support
   * @param service is the service for loading the needed data
   * @param field is a string that specifies the variable were the returning data should get stored
   *              (xxx.yyy not possible only direct variables)
   * @param params (optional) to pass to the backend via url
   */
  async load(
    service: OfflineDataService<any>,
    field: string,
    params: any = {}
  ): Promise<boolean> {
    if (field.includes('.'))
      console.error(
        'Nested Variables like [' +
          field +
          '] are not supported! Please use the service-methods directly with subscribe!'
      );

    try {
      const res = await service.localWhere(params);
      this[field] = res.data;
      return Promise.resolve(true);
    } catch (err) {
      this.errorService.handle(err);
      return Promise.resolve(false);
    }
  }

  /**
   * Getting navBack URL if set
   * @return {string}
   */
  private loadNavBackUrl() {
    const state = this.router.getCurrentNavigation()?.extras?.state;
    if (state?.navBack) this.navBackUrl = state.navBack;
    else this.navBackUrl = undefined;
  }

  protected async beforeSaveData(object: any) {
    return Promise.resolve(true);
  }

  /**
   * Loads data from given service into given variable without offline
   * @param service is the service for loading the needed data
   * @param field is a string that specifies the variable were the returning data should get stored
   *              (xxx.yyy not possible only direct variables)
   * @param params (optional) to pass to the backend via url
   */
  async loadRemote(
    service: IDataService<any> | OfflineDataService<any>,
    field: string,
    params: any = {}
  ): Promise<any> {
    if (field.includes('.'))
      console.error(
        'Nested Variables like [' +
          field +
          '] are not supported! Please use the service-methods directly with subscribe!'
      );

    try {
      const res = await firstValueFrom(service.whereRemote(params));
      this[field] = res.data;
      return Promise.resolve(res);
    } catch (err) {
      this.errorService.handle(err);
      return Promise.reject(err);
    }
  }

  /**
   * A new entry is created, or an existing one is modified.
   * @param optionsService corresponding data service
   * @param object object to be saved/created
   */
  async saveData(optionsService: IDataService<any>, object: any): Promise<any> {
    await this.beforeSaveData(object);
    return new Promise((resolve, reject) => {
      optionsService.save(object).subscribe({
        next: (o) => {
          resolve(o);
        },
        error: (err) => {
          this.errorService.handle(err);
          reject(err);
        },
      });
    });
  }

  /**
   * A new entry is created, or an existing one is modified.
   * @param optionsService corresponding data service
   * @param object object to be saved/created
   */
  async saveDataRemote(
    optionsService: OfflineDataService<any>,
    object: any
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      optionsService.saveRemote(object).subscribe({
        next: (o) => {
          resolve(o);
        },
        error: (err) => {
          this.errorService.handle(err);
          reject(err);
        },
      });
    });
  }

  /**
   * A new entry is created, or an existing one is modified.
   * @param optionsService - corresponding data service
   * @param object - object to be saved/created
   */
  async saveDataLocal(
    optionsService: OfflineDataService<any>,
    object: any
  ): Promise<StoredData<any>> {
    return optionsService.saveLocal(object) as Promise<StoredData<any>>;
  }

  /**
   * A new entry is created, or an existing one is modified.
   * @param optionsService - corresponding data service
   * @param object - object to be saved/created
   */
  async forceSaveDataLocal(
    optionsService: OfflineDataService<any>,
    object: any
  ): Promise<StoredData<any>> {
    return optionsService.saveLocalForce(object) as Promise<StoredData<any>>;
  }

  /**
   * A new entry is created, or an existing one is modified. After that the App will return to the indexPage and reload it.
   * @param optionsService - corresponding data service
   * @param object - object to be saved/created
   */
  async saveDataRemoteAndReturnToIndex(
    optionsService: IDataService<any>,
    object: any
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      (optionsService as OfflineDataService<any>).saveRemote(object).subscribe(
        (o) => {
          this.handleAfterSave();
          resolve(o);
        },
        (err) => {
          this.errorService.handle(err);
          reject(err);
        }
      );
    });
  }

  /**
   * A new entry is created, or an existing one is modified. After that the App will return to the indexPage and reload it.
   * @param optionsService - corresponding data service
   * @param object - object to be saved/created
   */
  saveDataAndReturnToIndex(optionsService: IDataService<any>, object: any) {
    optionsService.save(object).subscribe(
      () => this.handleAfterSave(),
      (err) => {
        this.errorService.handle(err);
      }
    );
  }

  /**
   * handle after save action
   */
  public handleAfterSave() {
    this.router.navigate([this.returnUrl], {
      queryParams: { reloadMitsTable: true },
    });
  }

  /**
   * Getting returnUrl
   * @return {string}
   */
  protected get returnUrl(): string {
    return this.navBackUrl ?? this.buildReturnUrl();
  }

  /**
   * Generating a default return URL
   * @return {string}
   */
  private buildReturnUrl(): string {
    const urlParts: string[] = this.router.url.split('/');
    const idLength = urlParts[urlParts.length - 1].length;
    const detailLength = urlParts[urlParts.length - 2].length;
    return this.router.url.substring(
      0,
      this.router.url.length - idLength - detailLength - 2
    );
  }

  /**
   * The App will return to the indexPage and reload it if wanted
   * @param reloadMitsTable Should the MitsTable be reloaded on the index page?
   */
  returnToIndex(reloadMitsTable?: boolean) {
    const urlParts: string[] = this.router.url.split('/');
    const idLength = urlParts[urlParts.length - 1].length;
    const detailLength = urlParts[urlParts.length - 2].length;
    const url = this.router.url.substring(
      0,
      this.router.url.length - idLength - detailLength - 2
    );
    this.router.navigate([url], {
      queryParams: { reloadMitsTable },
    });
  }

  /**
   * Show or hide the side-menu
   */
  public toggleMenu(): void {
    this.toggleMenuHelper.toggleMenu();
  }

  /**
   * Opens the detail page of this object type
   * @param id ID of the object / null => Create new object
   * @param data object to be passed to the detail page
   */
  public openDetailsPageOf(id?: number, data?: any) {
    const param = id ? id : 'create';
    this.router.navigate(['details', param], {
      state: data,
      relativeTo: this.route,
    });
  }

  /**
   * Sometimes there are different pages, one for editing and one for creating.
   * In such cases, this function is used to access these pages for creating.
   */
  public openNewPageOf() {
    this.router.navigate(['new'], { relativeTo: this.route });
  }

  /**
   * Returns to the last page (eg. for back-button)
   */
  returnToPreviousPage() {
    this.location.back();
  }
}
