import { BasicModel } from 'src/app/models/basic';
import { ImageModel } from 'src/app/models/system/image';
import { DamageCategoryModel } from './damage-category';

export const DAMAGE_STATE_OPEN = 0;
export const DAMAGE_STATE_EDIT = 1;
export const DAMAGE_STATE_FINISHED = 2;

export enum Weight {
  'Leicht' = 1,
  'Mittel' = 2,
  'Schwer' = 3,
  'Totalschaden' = 4,
}
export interface DamagePositionModel extends BasicModel {
  /** Beschreibung */
  description: string;
  /** Drucken? */
  printable: boolean;
  /** Status */
  state: number;
  /** Titel */
  title: string;
  /** ID von Schadens-Kategorie */
  category_id: number;
  // ID des Technischen Merkmals (Anbauteil) auf das sich der Schaden bezieht
  feature_id?: number | undefined;
  /** damage category */
  category: DamageCategoryModel;
  /** ID von Schadensbericht */
  damage_report_id: number;
  /** ID von Maschine */
  machine_id: number;
  /** Bilder welche gespeichert werden sollen */
  images: ImageModel[];
  /** weight of damage */
  weight: Weight;
}
