import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { ToastService } from './component-helpers/toast.service';

export interface IMitsError {
  status: string;
  message: string;
  error: Record<string, string[]>;
}

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  constructor(private readonly toastService: ToastService) {}

  /**
   * Converts an Error of Client-Side or Server-Side Type to IMitsError
   * @param error Any Error
   * @returns Converted IMitsError
   */
  convert(error: any) {
    const errorMessage: IMitsError = {} as IMitsError;
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage.message = error.error.message;
    } else {
      // Get server-side error
      errorMessage.status = error.status;
      errorMessage.message = error.message;
      errorMessage.error = error.error;
    }
    return throwError(errorMessage);
  }

  /**
   * Handles an error, logging and show it to the user
   * @param err IMitsError to handle
   */
  handle(err: IMitsError) {
    console.error(err);
    if (Number(err.status) === 0) {
      this.toastService.danger(
        'Das Backend ist nicht erreichbar! Bitte melden Sie sich bei Ihrem Administrator'
      );
    } else if (
      Number(err.status) === 401 &&
      err.error &&
      (err.error as any).error === 'No JWT token provided'
    ) {
      window.location.reload();
    } else {
      const errStr =
        'Error:' +
        (err.status ? 'with code [' + err.status + ']:' : ':') +
        err.error[0];
      this.toastService.danger(errStr);
    }
  }
}
