import { TimelogState } from "../models";

interface Validation {
    customer_remote_id?: boolean;
    location_remote_id?: boolean;
    machine_remote_id?: boolean;
    bufferstock_remote_id?: boolean;
}

export const validationConfig: { [index: string]: Validation } = {
    [TimelogState.Unkown]: {},
    [TimelogState.Previous]: {},
    [TimelogState.Working]: {},
    [TimelogState.Individual]: {},
    [TimelogState.CarEquiping]: {},
    [TimelogState.CarDriving]: {
        customer_remote_id: true,
    },
    [TimelogState.CustomerWorking]: {
        customer_remote_id: true,
    },
    [TimelogState.MachineWorking]: {
        customer_remote_id: true,
        machine_remote_id: true
    },
    [TimelogState.BufferstockEquiping]: {
        customer_remote_id: true,
        bufferstock_remote_id: true
    },
}