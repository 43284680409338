import { Component, ElementRef, Input } from '@angular/core';
import { MitsIconName } from './interfaces/mits-icon';

@Component({
  selector: 'mits-icon',
  templateUrl: './mits-icon.component.html',
  styleUrls: ['./mits-icon.component.scss'],
})
export class MitsIconComponent {
  @Input() name: MitsIconName;

  // All keys from the interface MitsIconName as a Array
  public iconNames: MitsIconName[] = [
    'add',
    'arrow-down',
    'arrow-left',
    'arrow-right',
    'arrow-up',
    'arrows-v',
    'assign',
    'cash-register',
    'check',
    'check-outline',
    'chevron-down',
    'chevron-left',
    'chevron-right',
    'chevron-up',
    'circle',
    'close',
    'close-outline',
    'code-slash',
    'details-less',
    'distribute-horizontal',
    'distribute-vertical',
    'edit-exposure',
    'eye',
    'file',
    'file-document',
    'filter',
    'format-justify',
    'format-seperator',
    'format-text',
    'image',
    'info',
    'layout-list',
    'link',
    'list',
    'log-off',
    'more-alt',
    'money-bag',
    'meter-reading',
    'menu-boxed',
    'options',
    'paper-clip',
    'pen',
    'person-christmas',
    'play-list-search',
    'push-chevron-down',
    'push-chevron-left',
    'push-chevron-right',
    'push-chevron-up',
    'push-left',
    'push-right',
    'scan',
    'search',
    'select',
    'software-download',
    'software-upload',
    'sync',
    'smile-sad',
    'template',
    'terminal',
    'trash',
  ];

  constructor(private ref: ElementRef<HTMLElement>) {
    this.ref.nativeElement.style.height = '100%';
  }
}
