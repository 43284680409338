// Framework Dependencies
import { InjectionToken } from '@angular/core';

// Module Dependencies
import { ITourUserService } from './interfaces/user.service.interface';
import { ITourMachineService } from './interfaces/machine.service.interface';
import { ITourLocationService } from './interfaces/location.service.interface';
import { ITourChecklistService } from './interfaces/checklist.service.interface';

export const TOUR_USER_SERVICE_TOKEN =
  new InjectionToken<ITourUserService>('TOUR_USER_SERVICE_TOKEN');
export const TOUR_MACHINE_SERVICE_TOKEN =
  new InjectionToken<ITourMachineService>('TOUR_MACHINE_SERVICE_TOKEN');
export const TOUR_LOCATION_SERVICE_TOKEN =
  new InjectionToken<ITourLocationService>('TOUR_LOCATION_SERVICE_TOKEN');
export const TOUR_CHECKLIST_SERVICE_TOKEN =
  new InjectionToken<ITourChecklistService>('TOUR_CHECKLIST_SERVICE_TOKEN');
