import { Injectable } from '@angular/core';
import { SyncProcessorService } from '@vending/sync-engine-client';
import { MediaFileModel } from '../../../models/features/medie_files';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { HttpClient } from '@angular/common/http';
import { ErrorService } from '../../error.service';
import { EventsService } from '../../events.service';
import { OfflineDataService } from '../../offlineData.service';
import { Observable } from 'rxjs';
import { toFormData } from '../../../components/mits-files-upload/mits-files-upload-select/helpers/formDataParser';
import { catchError, retry } from 'rxjs/operators';

/**
 * Marks a service class as being eligible for creation by the injector.
 * When provided in the root injector, this service will be available
 * throughout the application.
 * This decorator is used to register a service as a provider for the root application injector.
 * By default, services registered with this decorator will be singletons.
 */
@Injectable({
  providedIn: 'root',
})

/**
 * Service for communication with the media file endpoint at the backend side.
 * @description
 * A service for managing media files. Extends the OfflineDataService class for storing media files offline.
 * @author Johannes Belaschow
 */
export class MediaFilesService extends OfflineDataService<MediaFileModel> {
  /**
   * Creates an instance of MediaFilesService.
   * @param indexedDBService - The service for accessing IndexedDB.
   * @param syncProcessor - The service for syncing offline data with the server.
   * @param http - The Angular HttpClient for making HTTP requests.
   * @param errorService - The service for handling errors.
   * @param events - The service for handling events.
   */
  constructor(
    public indexedDBService: NgxIndexedDBService,
    public syncProcessor: SyncProcessorService,
    public http: HttpClient,
    public errorService: ErrorService,
    public events: EventsService
  ) {
    super(
      indexedDBService,
      syncProcessor,
      'MediaFile',
      http,
      errorService,
      events,
      'media_files/' /** The endpoint for media files API (backend). */,
      'media_file' /** The name of the object in JSON file for media file. */,
      [
        'created_at',
        'updated_at',
        'created_by_id',
      ] /** An array of removeParams for DataService superclass to exclude fields while updating the object. */,
      [] /** An array of attributedParams for DataService superclass to include nested objects. */
    );
  }

  /**
   * Saves a media file along with its associated files.
   * @param mediaFile - The media file to save.
   * @returns An observable that emits the saved media file.
   */
  saveWithFiles(mediaFile: MediaFileModel): Observable<MediaFileModel> {
    if (mediaFile.id >= 0) {
      return this.http
        .put<MediaFileModel>(
          this.endpointWithUrl + mediaFile.id,
          toFormData(mediaFile, this, ['images'])
        )
        .pipe(retry(1), catchError(this.errorService.convert));
    } else {
      return this.http
        .post<MediaFileModel>(
          this.endpointWithUrl,
          toFormData(mediaFile, this, ['images'])
        )
        .pipe(retry(1), catchError(this.errorService.convert));
    }
  }
}
