import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import { ResponseModel } from 'src/app/models/response';
import {
  TimelogDataModel,
  TimelogModel,
  TimelogParamsModel,
} from 'src/app/models/system/timelog';
import { TimelogCategoryModel } from 'src/app/models/system/timelog-category';
import { FunctionSwitchHelperService } from 'src/app/providers/component-helpers/function-switch.service';
import { ErrorService } from 'src/app/providers/error.service';
import { EventsService } from 'src/app/providers/events.service';
import { LoadingHelper } from 'src/app/providers/helpers/loading-helper.service';
import { UserHelper } from 'src/app/providers/helpers/user-helper.service';
import { TimelogCategoryService } from 'src/app/providers/model-services/timelogs/timelog-category.service';
import { TimelogService } from 'src/app/providers/model-services/timelogs/timelog.service';
import { ZE_FOR_USER_OR_CAR, ZE_FORCE_TIMESTAMP } from 'src/assets/constants/functionSwitch.constants';
import { GlobalHelper } from 'src/packages/mitsBasics/helpers/globalHelper/global.helper';
import {
  forcedTransitionConfig,
  manuelTransitionConfig,
  transitionConfig,
  validationConfig,
} from '../config';
import { ITimelogHelper } from '../interfaces/ITimelogHelper';
import { TimeEvent, TimelogState } from '../models';
import { TimelogErrorPage } from '../pages/timelog-error/timelog-error.page';
import { TimelogHelperState } from '../types';
import debounce from 'src/packages/mitsBasics/helpers/debounce';

// Der Key zum Abruf des aktuellen Zustands der Arbeitszeiterfassung aus dem LocalStorage
const LOCAL_STORAGE_STATE = 'timelogHelper_State';
// Kategorie, die für die Auftragszeiterfassung verwendet wird
const ORDER_INDIVIDUAL_CATEGORY_NAME = 'Auftrag';

const DEBOUNCE_CHANGE_STATE = 500;

/**
 * TimelogHelper V2
 * @author Tobias Münch
 *
 * Diese Klasse ist für die Verwaltung des Zustands der Arbeitszeiterfassung
 * zuständig. Es wird ein Zustandsautomat verwendet, um die einzelnen Schritte
 * der Arbeitszeiterfassung zu steuern.
 *
 * Konfiguration über die transitionConfig.ts und validationConfig.ts (siehe config Ordner)
 *
 */
@Injectable({
  providedIn: 'root',
})
export class TimelogHelperV2 implements ITimelogHelper {
  // Zeigt an, ob Zeiterfassung aktiviert ist
  useTimeTracking: boolean = false;
  // Zeigt an, ob die Zeiterfassung bei ungültigem Zustandsübergang erzwungen werden soll
  forceChangeState: boolean = false;

  // Aktueller Zustand
  private _state: TimelogHelperState = {
    state: TimelogState.Unkown,
    previousState: TimelogState.Unkown,
    params: {},
    timelog: null,
    previousTimelog: null,
  };

  // KATEGORIEN
  // Alle Kategorien
  private _allCategories: TimelogCategoryModel[] = [];
  // Kategorie für Arbeitszeiterfassung ('Auftrag')
  private _orderCategory: TimelogCategoryModel;
  // Individuelle Zeiterfassungskateogrien
  private _individualCategories: TimelogCategoryModel[] = [];
  // Ladezustand der Kategorien
  private _categoriesLoaded: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  // Observable für den Ladezustand der Kategorien
  public categoriesLoaded$: Observable<boolean> =
    this._categoriesLoaded.asObservable();

  /**
   * Konstruktor
   * @param events
   * @param loadingHelper
   * @param modalCtrl
   * @param userHelper
   * @param timelogService
   * @param timelogCategoryService
   * @param errorService
   */
  constructor(
    private readonly events: EventsService,
    private readonly loadingHelper: LoadingHelper,
    private readonly modalCtrl: ModalController,
    private readonly userHelper: UserHelper,
    private readonly timelogService: TimelogService,
    private readonly timelogCategoryService: TimelogCategoryService,
    private readonly fsHelper: FunctionSwitchHelperService,
    private readonly errorService: ErrorService
  ) {
    this.fsHelper.isLoaded.subscribe(() => {
      this.useTimeTracking = this.fsHelper.has(ZE_FOR_USER_OR_CAR);
      this.forceChangeState = this.fsHelper.has(ZE_FORCE_TIMESTAMP);
    });
    this.loadFromStorage();
    this.initTimeLogCategories().then(() => {
      this._categoriesLoaded.next(true);
    });
  }

  //////////////////// HILFSMETHODEN ZUR INITIALISIERUNG  ////////////////////
  /**
   * Zustand und Parameter aus dem LocalStorage laden
   * - wird genutzt, wenn die App neu geladen wird
   */
  private loadFromStorage(): void {
    const state: string = localStorage.getItem(LOCAL_STORAGE_STATE);
    if (state) {
      this._state = JSON.parse(state) as TimelogHelperState;
    }
  }

  /**
   * Mögliche Kategorien für die Arbeitszeiterfassung laden
   * - wird genutzt, um die Kategorie für die Auftragszeiterfassung zu laden und zu setzen (this._orderCategory)
   * - wird genutzt, um die individuellen Kategorien zu laden und zu setzen (this._individualCategories)
   * @private
   */
  private async initTimeLogCategories(): Promise<void> {
    const allTimeLogCategories: TimelogCategoryModel[] = await firstValueFrom(
      this.timelogCategoryService.all()
    )
      .then((response: ResponseModel<TimelogCategoryModel>) => response.data)
      .catch((error) => {
        this.errorService.handle(error);
        return [];
      });
    this._allCategories = allTimeLogCategories;
    this._individualCategories = allTimeLogCategories.filter(
      (category: TimelogCategoryModel): boolean =>
        ORDER_INDIVIDUAL_CATEGORY_NAME !== category.name
    );
    this._orderCategory = allTimeLogCategories.find(
      (category: TimelogCategoryModel): boolean =>
        ORDER_INDIVIDUAL_CATEGORY_NAME === category.name
    );
  }

  ////////////////////////////////////////

  public get allCategories(): TimelogCategoryModel[] {
    return this._allCategories;
  }

  /**
   * Kategorie für Auftrag laden
   * @return {Promise<void>}
   */
  private async loadCategoryOrder() {
    const results: ResponseModel<TimelogCategoryModel> =
      await this.timelogCategoryService.localWhere({
        name: 'Auftrag',
      });
    this._orderCategory = results.data[0];
  }


  /**
   * Ändert den Status des Zeiteintrags, ohne zu überprüfen, ob der Schritt nach der transitionConfig zulässig ist.
   * @constant {transitionConfig}
   * @param state Status, in den Der Zeiteintrag gewechselt werden soll
   * @returns {boolean} Wurde der Status geändert ?
   */
  @debounce(DEBOUNCE_CHANGE_STATE)
  async forceStateDebounce(state: TimelogState): Promise<boolean> {
    return await this.forceState(state);
  }

  /**
   * Ändert den Status des Zeiteintrags, ohne zu überprüfen, ob der Schritt nach der transitionConfig zulässig ist.
   * @constant {transitionConfig}
   * @param state Status, in den Der Zeiteintrag gewechselt werden soll
   * @returns {boolean} Wurde der Status geändert ?
   */
  async forceState(state: TimelogState): Promise<boolean> {
    if (this.currentState === state || !this.useTimeTracking) return false;
    this.previousTimelog = this.currentTimelog;
    this.previousState = this.currentState;
    this.currentState = state;
    this.currentParams = {};
    this.events.publish('timelogs:stateChange', this.currentState);
    await this.processTimelog(this.currentState, TimelogState.Working, {});
    return true;
  }

  /**
   * Mögliche nächste automatische Events für nächsten Zustand zurückgeben
   * TODO - Wie können hier spezifische Zustände nicht erlaubt werden?
   * @return {TimeEvent[]}
   */
  get possibleNextEvents(): TimeEvent[] {
    let values = Object.keys(transitionConfig[this.currentState]);
    values = values.filter((value) => value !== TimeEvent.IndividualStart);
    return values as TimeEvent[];
  }

  /**
   * Manuell auswählbare nächste Events für nächsten Zustand zurückgeben
   * @return {TimeEvent[]}
   */
  get displayNextEvents(): TimeEvent[] {
    let values = Object.keys(manuelTransitionConfig[this.currentState]);
    values = values.filter((value) => value !== TimeEvent.IndividualStart);
    return values as TimeEvent[];
  }

  /**
   * Sind individuelle Events möglich?
   * @return {boolean}
   */
  get individualEventsPossible(): boolean {
    return (
      transitionConfig[this.currentState][TimeEvent.IndividualStart] !==
      undefined
    );
  }

  /**
   * Mögliche individuelle Kategorien zurückgeben
   * @return Promise<TimelogCategoryModel[]> - Liste der möglichen Kategorien
   */
  async getPossibleIndividualCategories(): Promise<TimelogCategoryModel[]> {
    if (this.individualEventsPossible) {
      return this._individualCategories;
    } else {
      return [];
    }
  }

  /**
   * Sind die Parameter für den abschluss des vorherigen Zustands gültig?
   * @return {boolean}
   */
  get currentStateParamsValid(): boolean {
    const config = validationConfig[this.currentState];
    if (config) {
      return Object.keys(config).every((key) => {
        return !config[key] || !!this.currentParams[key];
      });
    } else {
      return true;
    }
  }

  /**
   * Gibt mögliche Parameter für den aktuellen Zustand zurück,
   * die transferiert werden können
   */
  private get transferableParams(): string[] {
    const config = validationConfig[this.currentState];
    if (config) {
      return Object.keys(config).filter((key) => {
        return config[key];
      });
    } else {
      return [];
    }
  }

  /**
   * Getter für den aktuellen Zustand
   */
  public get currentState(): TimelogState {
    return this._state.state;
  }

  /**
   * Setter für den aktuellen Zustand
   */
  private set currentState(v: TimelogState) {
    if (this._state.state !== v) {
      this._state.state = v;
      this.saveStateToStorage();
    }
  }

  /**
   * Getter für die aktuellen Parameter
   */
  public get currentParams(): TimelogParamsModel {
    return this._state.params;
  }

  /**
   * Setter für die aktuellen Parameter
   */
  private set currentParams(v: TimelogParamsModel) {
    if (this._state.params !== v) {
      this._state.params = v;
      this.saveStateToStorage();
    }
  }

  /**
   * Getter für den aktuellen Timelog
   */
  public get currentTimelog(): TimelogModel {
    return this._state.timelog;
  }

  /**
   * Setter für den aktuellen Timelog
   */
  private set currentTimelog(v: TimelogModel) {
    if (this._state.timelog !== v) {
      this._state.timelog = v;
      this.saveStateToStorage();
    }
  }

  /**
   * Vorherigen Zustand setzen
   */
  private set previousState(v: TimelogState) {
    if (this._state.previousState !== v) {
      this._state.previousState = v;
      this.saveStateToStorage();
    }
  }

  /**
   * Vorherigen Zustand laden
   */
  public get previousState(): TimelogState {
    return this._state.previousState;
  }

  /**
   * Getter für den vorherigen Timelog
   */
  public get previousTimelog(): TimelogModel {
    return this._state.previousTimelog;
  }

  /**
   * Setter für den vorherigen Timelog
   */
  private set previousTimelog(v: TimelogModel) {
    if (this._state.previousTimelog !== v) {
      this._state.previousTimelog = v;
      this.saveStateToStorage();
    }
  }

  /**
   * Aktuellen Benutzer laden
   * @return {number}
   */
  private get userId(): number {
    return this.userHelper.getUser().id;
  }

  @debounce(DEBOUNCE_CHANGE_STATE)
  public async changeStateDebounce(
    event: TimeEvent,
    data: TimelogDataModel = undefined,
    timelogCategory: TimelogCategoryModel = undefined
  ): Promise<void> {
    if (!this.useTimeTracking) return;
    await this.changeState(event, data, timelogCategory);
  }

  /**
   * Zustand durch ein Event verändern
   * @param event
   * @param data - the data for the params
   * @param timelogCategory (optional) Abweichende Category für das nächste Timelog
   */
  public async changeState(
    event: TimeEvent,
    data: TimelogDataModel = undefined,
    timelogCategory: TimelogCategoryModel = undefined
  ): Promise<void> {
    if (!this.useTimeTracking) return;
    let transition = transitionConfig[this.currentState][event];
    // Zustandsübergang auf Basis des übergebenen Events erzwingen, bei aktiviertem Funktionsschalter (#876)
    if(!transition && this.forceChangeState) transition = forcedTransitionConfig[event];
    // Gültiger Zustandsübergang ODER erzwungener Zustandsübergang liegt vor (#876)
    if (transition) {
      const params: TimelogParamsModel = this.generateParamsFromData(data);
      // Wenn der aktuelle Timelog identischen Zustand und Parameter hat, wird der Wechsel übersprungen
      if (
        this.equalToCurrentTimelogDescriptionAndInformation(
          transition.to,
          params
        )
      )
        return;
      // Wenn zum vorherigen Zustand zurückgegeangen soll, wird der hier geladen.
      if (transition.to === TimelogState.Previous) {
        // Wenn der vorherige sowie der aktuelle Zustand "Individual" sind, wird der neue Zustand auf "Working" gesetzt
        const currentAndNextIsIndividual =
          this.previousState === TimelogState.Individual &&
          this.currentState === TimelogState.Individual;
        if (currentAndNextIsIndividual) transition.to = TimelogState.Working;
        else transition.to = this.previousState;
      }
      this.previousTimelog = this.currentTimelog;

      // Check for options and process them
      if (transition.options) {
        if (transition.options.insert) {
          await this.processTimelog(
            this.currentState,
            transition.options.insert,
            params,
            timelogCategory,
            true
          );
        }
      }

      await this.processTimelog(
        this.currentState,
        transition.to,
        params,
        timelogCategory
      );
      this.previousState = this.currentState;
      this.currentState = transition.to;
      this.currentParams = params;
      this.events.publish('timelogs:stateChange', this.currentState);
    } else {
      const modal = await this.modalCtrl.create({
        component: TimelogErrorPage,
        componentProps: {
          currentState: this.currentState,
          event,
        },
      });
      await modal.present();
      this.loadingHelper.resetLoadings();
      console.error(
        `Invalid state transition: ${this.currentState} -> ${event}`
      );
      return Promise.reject(
        new Error(`Invalid state transition: ${this.currentState} -> ${event}`)
      );
    }
  }

  /**
   * Hilfsfunktion zum inhaltlichen Vergleich von Zustandsbezeichnung (description) und Parameter (information) des
   * aktuellen Timelogs (this.currentTimelog) mit dem übergebenen Zustand (transitionTo) und Parameter (parameter).
   * @param transitionTo Der Zustand, zu dem gewechselt werden soll: wird verglichen mit der 'description'
   * @param nextParams Die Parameter des nächsten Zustands: werden inhältlich per JSON.stringify mit 'information' verglichen
   * @returns true, falls sowohl Description als auch Information gleich sind, sonst false
   */
  private equalToCurrentTimelogDescriptionAndInformation(
    transitionTo: TimelogState,
    nextParams: TimelogParamsModel
  ): boolean {
    if (!this.currentTimelog) return false;
    if (
      this.currentTimelog.description === transitionTo && // Zustand (description) ist gleich
      GlobalHelper.isEqual(this.currentTimelog.information, nextParams) // Parameter (information) sind gleich
    ) {
      console.debug(
        '[TimeLogHelperV2]: Timelog change skipped because of same state and params',
        this.currentTimelog,
        transitionTo,
        nextParams
      );
      return true;
    }
    return false;
  }

  /**
   * Zeiteintrag verarbeiten auf Basis vom neuen und alten Zustand.
   * Ferner kann auf neue und alter Paramter zugegriffen werden
   * @param from
   * @param to
   * @param newParams
   */
  private async processTimelog(
    from: TimelogState,
    to: TimelogState,
    newParams: TimelogParamsModel,
    timelogCategory: TimelogCategoryModel = undefined,
    inserted: boolean = false
  ) {
    if (!this.useTimeTracking) return;
    // Vorherigen Zeitlog beenden(bei Bedarf)
    if (this.currentTimelog) {
      this.currentTimelog.end_time = new Date();

      // Wenn vorheriger Zustand ohne ausreichend Parameter gestartet wurde
      // werden die Parameter vom nächsten Zustand übernommen
      if (!this.currentStateParamsValid) {
        this.transferableParams.forEach((key) => {
          this.currentTimelog.information[key] = newParams[key];
        });
      }

      // Speichern zum Server
      await firstValueFrom(this.timelogService.save(this.currentTimelog));
      // Lokal Speichern für Auflistung
      await this.timelogService.saveLocalForce(this.currentTimelog);
    }

    // Neuen Zeitliog erzeugen
    if (!this._orderCategory) await this.loadCategoryOrder();
    const newTimelog = this.buildTimelog(to, this._orderCategory?.id);
    newTimelog.information = newParams;
    newTimelog.inserted_timelog = inserted;
    if (timelogCategory) {
      newTimelog.timelog_category_id = timelogCategory.id;
    }
    this.currentTimelog = newTimelog;
  }

  /**
   * Zustand und Parameter zurücksetzen
   */
  public resetStoredState() {
    this.currentState = TimelogState.Unkown;
    this.currentParams = {};
    this.currentTimelog = null;
    this._state.previousState = TimelogState.Unkown;
  }

  /**
   * Zustand und Parameter in den LocalStorage speichern
   */
  private saveStateToStorage() {
    if (!this.useTimeTracking) return;
    localStorage.setItem(LOCAL_STORAGE_STATE, JSON.stringify(this._state));
  }

  /**
   * Neues Timelog erzeugen
   * @param description
   * @param categoryId
   * @return {TimelogModel}
   */
  private buildTimelog(description: string, categoryId: number): TimelogModel {
    return {
      trackable_id: this.userId,
      trackable_type: 'User',
      start_time: new Date(),
      description: description,
      timelog_category_id: categoryId,
      created_by_id: this.userId,
      client_id: this.generateClientID(),
    } as TimelogModel;
  }

  /**
   * Erzeugt zusätzliche Informationen zur Auswertung in der Büroware (TimelogParamsModel) auf Basis der übergebenen
   * Entitäten (TimelogDataModel).
   * @param data Die übergebenen Entitäten
   * @private
   */
  private generateParamsFromData(
    data: TimelogDataModel | undefined
  ): TimelogParamsModel {
    const params: TimelogParamsModel = {};
    if (!data) return params;
    // KUNDE
    if (data.customer) {
      params.customer_remote_id = data.customer.remote_id;
      params.customer_text = this.getText(data.customer, [
        'name1',
        'name2',
        'street',
        'zip',
        'city',
        'country',
      ]);
    }
    // MASCHINE
    if (data.machine) {
      params.machine_remote_id = data.machine.remote_id;
      params.machine_type = data.machine.machine_type?.description;
      params.machine_inventory_number = data.machine.inventory_number;
    }
    // STANDORT
    if (data.location) {
      params.location_remote_id = data.location.remote_id;
      if (data.location.floor && data.location.floor_position)
        params.machine_floor = `${data.location.floor}, ${data.location.floor_position}`;
      else if (data.location.floor)
        params.machine_floor = `${data.location.floor}`;
      else if (data.location.floor_position)
        params.machine_floor = `${data.location.floor_position}`;
    }
    // Bufferstock
    if (data.bufferstock_location) {
      params.bufferstock_remote_id = data.bufferstock_location.remote_id;
      params.bufferstock_code = data.bufferstock_location.bufferstock_code;
      params.bufferstock_address = this.getText(data.bufferstock_location, [
        'name1',
        'name2',
        'street',
        'zip',
        'city',
        'country',
      ]);
      if (
        data.bufferstock_location.floor &&
        data.bufferstock_location.floor_position
      )
        params.bufferstock_floor = `${data.bufferstock_location.floor}, ${data.bufferstock_location.floor_position}`;
      else if (data.bufferstock_location.floor)
        params.bufferstock_floor = `${data.bufferstock_location.floor}`;
      else if (data.bufferstock_location.floor_position)
        params.bufferstock_floor = `${data.bufferstock_location.floor_position}`;
    }
    return params;
  }

  /**
   * Hilfsmethode zum Formatieren der Informationen aus einem beliebigen Objekt basierend auf einer Liste von Attributen.
   * @param obj das Objekt, aus dem Informationen extrahiert werden
   * @param attributes die Attribute, deren Werte extrahiert und formatiert werden sollen
   * @returns einen formatierten String, der die Werte der angegebenen Attribute enthält, oder null, wenn keine Werte vorhanden sind
   */
  private getText(obj: any, attributes: string[]): string | null {
    if (!obj) return null;
    const text = attributes
      .map((a) => obj[a])
      .filter((c) => !!c)
      .join(', ');
    return text.trim().length > 0 ? text : null;
  }

  private generateClientID() {
    return `${
      this.userHelper.getUser().id
    }_${new Date().getTime()}_${Math.floor(Math.random() * 99)}`;
  }
}
