/**
 * Decorator that prevents a method from being called more than once every X seconds.
 * @param wait The number of seconds to wait between calls.
 * @param immediate If true, the method will be called immediately if it's the first call in the wait cycle.
 */
export default function debounce(wait: number, immediate: boolean = false) {
    return function (
      target: any,
      propertyKey: string,
      descriptor: PropertyDescriptor
    ) {
      let timeout: any;
      const original = descriptor.value;
  
      descriptor.value = function (...args: any[]) {
        const context = this;
  
        const later = function () {
          timeout = null;
          if (!immediate) {
            original.apply(context, args);
          }
        };
  
        const callNow = immediate && !timeout;
  
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
  
        if (callNow) {
          original.apply(context, args);
        }
      };
  
      return descriptor;
    };
  }