import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SyncProcessorService } from '@vending/sync-engine-client';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { catchError, firstValueFrom, Observable, retry } from 'rxjs';
import { toFormData } from 'src/app/components/mits-files-upload/mits-files-upload-select/helpers/formDataParser';
import { TemporaryImage } from 'src/app/models/checklists/temporary-image';
import { ErrorService } from '../../error.service';
import { EventsService } from '../../events.service';
import { IDataService } from '../../idataService';
import { OfflineDataService } from '../../offlineData.service';

@Injectable({
  providedIn: 'root',
})
export class TemporaryImageService
  extends OfflineDataService<TemporaryImage>
  implements IDataService<TemporaryImage>
{
  constructor(
    public indexedDBService: NgxIndexedDBService,
    public syncProcessor: SyncProcessorService,
    public http: HttpClient,
    public errorService: ErrorService,
    public events: EventsService
  ) {
    super(
      indexedDBService,
      syncProcessor,
      'TemporaryImage',
      http,
      errorService,
      events,
      'checklist_management/temporary_images',
      'temporary_image',
      ['created_at', 'updated_at', 'id'],
      []
    );
  }

  /**
   * Speichern auf dem Server
   * @param object
   * @returns
   */
  override saveRemote(object: any): Observable<TemporaryImage> {
    return this.http
      .post<TemporaryImage>(
        this.endpointWithUrl,
        toFormData(object, this, ['image'])
      )
      .pipe(retry(1), catchError(this.errorService.convert));
  }

  /**
   * Viele Bilder gleichzeitig in den Store einfügen
   * @param {TemporaryImage[] images
   */
  bulkSave(images: TemporaryImage[]) {
    images.forEach((image) => {
      this.save(image);
    });
  }

  /**
   * Object speichern
   * @param {TemporaryImage} object
   * @return {Observable<TemporaryImage>}
   */
  override save(object: TemporaryImage): Observable<TemporaryImage> {
    object.updated_at = new Date().toISOString();

    if (!object.id) {
      object.id = Math.floor(Math.random() * 100000) * -1;
    }

    return new Observable((subscriber) => {
      this.store(object, object.updated_at)
        .then((result) => {
          subscriber.next(object);
          subscriber.complete();
        })
        .catch((error) => {
          console.error(error);
        });
    });
  }

  /**
   * Daten aus dem lokalen Datenbestand löschen
   * @param {number} id
   * @return {Promise<any>}
   */
  public override async localDelete(id: number): Promise<any> {
    await this.localFind(id);
    const result = await super.localDelete(id);
    return result;
  }

  /**
   * Temporäre Bilder zu einem Auftrag laden
   * @param {number} orderId
   * @return {Promise<TemporaryImage[]}
   */
  public async getImagesForOrder(orderId: number): Promise<TemporaryImage[]> {
    const result = await this.localWhere({ object_id: orderId });
    return result.data;
  }

  /**
   * Bilder zu einem Auftrag lossenden
   * @param {number} orderId
   * @return {Promise<string[]>}
   */
  public async sendImages(orderId: number): Promise<string[]> {
    let result = await this.localWhere({ object_id: orderId });

    result.data.forEach(async (localObj) => {
      await firstValueFrom(this.queue(localObj, localObj.updated_at));
    });

    return result.data.map((element) => element.remote_id);
  }
}
