import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
// internal
import { OrderModel, UserAttributes } from 'src/app/models/order';

@Injectable({
  providedIn: 'root',
})
export class ServiceContractsCounterService {
  // Die ID des Users, dessen Serviceaufträge gezählt werden sollen
  private _userId: number;
  // Beinhaltet alle Ids der Orders mit 'order_filter' === 'open'
  private _serviceContractsOpenIDs: Set<number> = new Set<number>([]);
  // Beinhaltet alle Ids der Orders mit 'order_filter' === 'active'
  private _serviceContractsActiveIDs: Set<number> = new Set<number>([]);
  // Zählt die Anzahl der (offenen) Service-Aufträge
  private _serviceContractsOpen: BehaviorSubject<number> = new BehaviorSubject<number>(this._serviceContractsOpenIDs.size);
  // Zählt die Anzahl der aktiven Service-Aufträge
  private _serviceContractsActive: BehaviorSubject<number> = new BehaviorSubject<number>(this._serviceContractsActiveIDs.size);
  // Observable für _serviceContractsOpen
  public _serviceContractsOpenCounter$: Observable<number> = this._serviceContractsOpen.asObservable();
  // Observable für _serviceContractsActive
  public _serviceContractsActiveCounter$: Observable<number> = this._serviceContractsActive.asObservable();

  //////////////////////////////////////////////////////////////
  //// Öffentliche Methoden

  async initialState(data: OrderModel[], userID: number): Promise<void> {
    this._userId = userID;
    data.forEach((order: OrderModel) => this.onStore(order));
    this.updateBehaviorSubjects();
  }

  onStore(object: OrderModel): void {
    // Zunächst die ID in den beiden Sets löschen
    this._serviceContractsOpenIDs.delete(object.id);
    this._serviceContractsActiveIDs.delete(object.id);
    // Falls der Auftrag für den User ist, wird die ID in das entsprechende Set hinzugefügt
    if (!this.updatedOrderIsForUser(object)) return;
    if (this.updatedOrderHasState(object, 'open')) this._serviceContractsOpenIDs.add(object.id);
    if (this.updatedOrderHasState(object, 'active')) this._serviceContractsActiveIDs.add(object.id);
    this.updateBehaviorSubjects();
  }


  onLocalDelete(id: number): void {
    if (!id) return;
    this._serviceContractsOpenIDs.delete(id);
    this._serviceContractsActiveIDs.delete(id);
    this.updateBehaviorSubjects();
  }

  getOpenServiceContractsAsObservable(): Observable<number> {
    return this._serviceContractsOpenCounter$;
  }

  getActiveServiceContractsAsObservable(): Observable<number> {
    return this._serviceContractsActiveCounter$;
  }

  //////////////////////////////////////////////////////////////
  //// Private Hilfsmethoden
  /**
   * Überprüft, ob das Order-Objekt für den Aussendienstler bestimmt ist
   * @param object
   * @private
   */
  private updatedOrderIsForUser(object: OrderModel): boolean {
    return !!object.order_field_users.find((user: UserAttributes): boolean => user.user_id === this._userId);
  }

  /**
   * Aktualisiert die beiden Behavior-Subjects
   * @private
   */
  private updateBehaviorSubjects(): void {
    this._serviceContractsOpen.next(this._serviceContractsOpenIDs.size);
    this._serviceContractsActive.next(this._serviceContractsActiveIDs.size);
  }

  /**
   * Gibt zurück, ob der Auftrag den übergebenen Status hat
   * - Nutzt die Logik aus OrderService.transformArgs()
   * @param order
   * @param state
   * @private
   */
  private updatedOrderHasState(order: OrderModel, state: string): boolean {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const tomorrow = new Date(today.getTime() + 24 * 60 * 60 * 1000); // 1 day in milliseconds
    const dateBefore7Days = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000); // 7 days in milliseconds

    switch (state) {
      case 'open':
        return ['Offen', 'Übermittelt', 'Anfahrt', 'In Bearbeitung', 'Pause', 'Unterschrift / Vorbereitung Abreise']
            .includes(order.state_name) &&
          new Date(order.execution_date) < tomorrow &&
          new Date(order.execution_date) >= today;
      case 'active':
        return ['Anfahrt', 'In Bearbeitung', 'Pause', 'Unterschrift / Vorbereitung Abreise'].includes(order.state_name) &&
          new Date(order.execution_date) < tomorrow;
      case 'future':
        return order.state_name === 'Offen' &&
          new Date(order.execution_date) >= tomorrow;
      case 'last':
        return ['Abgeschlossen', 'Auftrag abgebrochen'].includes(order.state_name) &&
          new Date(order.created_at) > dateBefore7Days;
      default:
        return false;
    }
  }
}
