import { Injectable } from '@angular/core';
import { FeatureModel } from '../../../models/features/feature';
import { SyncProcessorService } from '@vending/sync-engine-client';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { OfflineDataService } from '../../offlineData.service';
import { HttpClient } from '@angular/common/http';
import { ErrorService } from '../../error.service';
import { EventsService } from '../../events.service';

/**
 * Marks a service class as being eligible for creation by the injector.
 * When provided in the root injector, this service will be available
 * throughout the application.
 * This decorator is used to register a service as a provider for the root application injector.
 * By default, services registered with this decorator will be singletons.
 */
@Injectable({
  providedIn: 'root',
})

/**
 * Serviceklasse der Technischen Merkmalen um diese in der SyncQueue bis zum Sync zu speichern
 */
export class FeatureService extends OfflineDataService<FeatureModel> {
  constructor(
    private readonly ngxIdb: NgxIndexedDBService,
    private readonly syncProc: SyncProcessorService,
    private readonly httpCl: HttpClient,
    errorServ: ErrorService,
    eventServ: EventsService
  ) {

    super(
      ngxIdb,
      syncProc,
      'Feature',
      httpCl,
      errorServ,
      eventServ,
      'features/',
      'feature',
      ['updated_at', 'created_at'],
      [],
      null,
      true
    );
    
  }
}