import {Routes} from '@angular/router';

export const FAILED_REQUEST_MONITORING_BASE_ROUTE = 'failed-request-monitoring';

export const FailedRequestMonitoringRoutes: Routes = [
    {
        path: '',
        loadComponent: () => import('./pages/failed-requests/failed-requests.page')
            .then((m) => m.FailedRequestsPage),
    },
    {
        path: 'details/:id',
        loadComponent: () => import('./pages/failed-request-details/failed-request-details.page')
            .then((m) => m.FailedRequestDetailsPage),
    },
];