/**
 * Helper für Array operationen
 */
export class ArrayHelper {
  /**
   * Fügt ein Objekt in ein Array ein und ersetzt es, sofern ein Index mitgegeben wird
   * @param {T[]} objectArray
   * @param {T} obj
   * @param {number} index
   */
  public static insertOrUpdate<T>(objectArray: T[], obj: T, index?: number) {
    if (index >= 0) {
      objectArray[index] = obj;
    } else {
      objectArray.push(obj);
    }
  }
}
