import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

export type MitsBadgeColor =
  | 'primary'
  | 'tertiary'
  | 'secondary'
  | 'debug'
  | 'warning'
  | 'danger'
  | 'success'
  | 'light'
  | 'medium'
  | 'dark';

export type MitsBadgeSize = 'small' | 'default' | 'large' | 'x-large';

@Component({
  selector: 'mits-badge',
  templateUrl: './mits-badge.component.html',
  styleUrls: ['./mits-badge.component.scss'],
})
export class MitsBadgeComponent implements OnInit {
  /** Value of the badge */
  @Input() value: string | number = '0';
  /** Color of the badge */
  @Input() color: MitsBadgeColor = 'primary';
  /** Very small title on top of the badge */
  @Input() title: string = '';
  /** Should the bagde be displaed as an outlined badge? */
  @Input() outlined: boolean = false;
  /** Defines the size of the badge */
  @Input() size: MitsBadgeSize = 'default';

  @ViewChild('mitsBadgeSpan') mitsBadgeSpanComp: ElementRef<HTMLSpanElement>;

  ngOnInit(): void {
    setTimeout(() => {
      this.updateClassList();
    }, 0);
  }

  private updateClassList() {
    if (this.mitsBadgeSpanComp) {
      const span = this.mitsBadgeSpanComp.nativeElement;
      span.classList.value = '';
      span.classList.add('mits-badge');
      span.classList.add(`mits-badge-${this.color}`);
      span.classList.add(`mits-badge-${this.size}`);
      if (this.outlined) {
        span.classList.add('mits-badge-outlined');
      }
    }
  }
}
