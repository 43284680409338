import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { IonicModule, ModalController } from '@ionic/angular';
// internal
import { eventConfig, stateConfig } from 'src/packages/timeManagement/config';
import { TimeEvent, TimelogState } from 'src/packages/timeManagement/models';

@Component({
  selector: 'timelog-error',
  templateUrl: './timelog-error.page.html',
  styleUrls: ['./timelog-error.page.scss'],
  standalone: true,
  imports: [
    CommonModule, IonicModule
  ],
})
export class TimelogErrorPage {
  // Der aktuelle TimelogState
  @Input() private currentState: TimelogState;
  // Das aktuelle TimeEvent
  @Input() private event: TimeEvent;

  constructor(
    private readonly modalCtrl: ModalController,
    private readonly router: Router
  ) {
  }

  /**
   * Gibt den Namen des TimelogState aus der Konfiguration zurück
   */
  protected get stateName(): string {
    return stateConfig[this.currentState];
  }

  /**
   * Gibt den Namen des TimelogEvent aus der Konfiguration zurück
   */
  protected get eventName(): string {
    return eventConfig[this.event];
  }

  /**
   * Navigiert zur Timelog-Seite
   */
  protected async openTimeLogPage(): Promise<void> {
    await this.router.navigate(['/timelogs']);
    await this.closeModal();
  }

  /**
   * Schließt das Modal
   */
  protected async closeModal(): Promise<void> {
    await this.modalCtrl.dismiss();
  }
}
