import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SyncProcessorService } from '@vending/sync-engine-client';
import { StoredData } from '@vending/sync-engine-client/lib/models/storedData';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { catchError, Observable, retry } from 'rxjs';
// internal
import { ErrorService } from 'src/app/providers/error.service';
import { EventsService } from 'src/app/providers/events.service';
import { OfflineDataService } from 'src/app/providers/offlineData.service';
import { StockMovementModel } from 'src/packages/warehouse/models/stockMovement';

@Injectable({
  providedIn: 'root',
})
export class StockMovementService extends OfflineDataService<StockMovementModel> {
  constructor(
    public indexedDBService: NgxIndexedDBService,
    public syncProcessor: SyncProcessorService,
    public http: HttpClient,
    public errorService: ErrorService,
    public events: EventsService
  ) {
    super(
      indexedDBService,
      syncProcessor,
      'Warehouse::StockMovement',
      http,
      errorService,
      events,
      'warehouse/stock_movements/',
      'stock_movement',
      [],
      [],
      'target_id'
    );
  }

  /**
   * Bestätigt die Buchung online
   * @param stockMovementId - Die id der StockMovement
   * @param bookedAt - das Datum der StockMovement
   * @param verifiedById - die ID des Users, der die StockMovement verifiziert hat
   */
  bookMovement(
    stockMovementId: number,
    bookedAt: Date,
    verifiedById: number
  ): Observable<StockMovementModel> {
    const params: HttpParams = new HttpParams()
      .set('verified_by_id', verifiedById)
      .set('booked_at', bookedAt.toISOString())
      .set('stock_movement_id', stockMovementId);
    return this.http
      .post<any>(this.endpointWithUrl + 'book_movement', {}, {params})
      .pipe(retry(1), catchError(this.errorService.convert));
  }

  /**
   * Falls verfied_by_id gesetzt ist, local löschen und nicht einfügen!
   * Wichtig: Weil wir sonst den Speicher der Endgeräte vollmüllen.
   * @param object - Das zu speichernde Objekt vom Typ StockMovementModel
   * @returns Promise<boolean | StoredData<StockMovementModel>>
   */
  async saveLocal(
    object: StockMovementModel
  ): Promise<boolean | StoredData<StockMovementModel>> {
    if (object.verified_by_id > 0) {
      await this.localDelete(object.id);
      return Promise.resolve(true);
    } else {
      return super.saveLocal(object);
    }
  }

  override saveRemote(
    object: StockMovementModel
  ): Observable<StockMovementModel> {
    if (!object.booked_at) {
      object.booked_at = object.created_at || new Date();
    }
    return super.saveRemote(object);
  }

  /**
   * Name des Icons für die Klasse
   * @return string - Der Name des Icons
   */
  public get iconName(): string {
    return 'file-tray-stacked-outline';
  }
}
