import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SyncProcessorService } from '@vending/sync-engine-client';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { catchError, firstValueFrom, Observable, retry } from 'rxjs';
import { toFormData } from 'src/app/components/mits-files-upload/mits-files-upload-select/helpers/formDataParser';
import { ResponseModel } from 'src/app/models/response';
import { ErrorService } from 'src/app/providers/error.service';
import { EventsService } from 'src/app/providers/events.service';
import { MachineService } from 'src/app/providers/model-services/machines/machine.service';
import { OfflineDataService } from 'src/app/providers/offlineData.service';
import { DamageReportModel } from '../models';

@Injectable({
  providedIn: 'root',
})
export class DamageReportService extends OfflineDataService<DamageReportModel> {
  /**
   * Constructor
   * @param indexedDBService
   * @param syncProcessor
   * @param http
   * @param errorService
   * @param events
   * @param machineService
   */
  constructor(
    public indexedDBService: NgxIndexedDBService,
    public syncProcessor: SyncProcessorService,
    public http: HttpClient,
    public errorService: ErrorService,
    public events: EventsService,
    public machineService: MachineService
  ) {
    super(
      indexedDBService,
      syncProcessor,
      'Reporting::DamageReport',
      http,
      errorService,
      events,
      'reporting/damage_reports/',
      'reporting_damage_report',
      ['created_at', 'updated_at', 'created_by_id', 'file_url'],
      [],
      'machine_id'
    );
  }

  /**
   * Name des Icons für die Klasse
   * @return {string}
   */
  public get iconName(): string {
    return 'warning';
  }

  /**
   * Schäden für einen Kunden laden
   * @param {number} customerId
   * @return
   */
  async loadForCustomer(customerId: number): Promise<DamageReportModel[]> {
    let result: ResponseModel<DamageReportModel> = await firstValueFrom(
      this.http.get<any>(this.endpointWithUrl + '?customer_id=' + customerId)
    );

    return result.data;
  }

  /**
   * Speichern des Damage-Reports auf dem Server
   * @param {DamageReportModel} object
   */
  override saveRemote(
    object: DamageReportModel
  ): Observable<DamageReportModel> {
    return this.internalSave(object).pipe(
      retry(1),
      catchError(this.errorService.convert)
    );
  }

  /**
   * Internes Speichern als POST/PUT
   * @param damageReport damagereport
   * @return saved Report
   */
  private internalSave(
    damageReport: DamageReportModel
  ): Observable<DamageReportModel> {
    const data = toFormData(damageReport, this, ['image', 'report_file']);
    if (this.isNew(damageReport)) {
      return this.http.post<DamageReportModel>(this.endpointWithUrl, data);
    } else {
      return this.http.put<DamageReportModel>(
        this.endpointWithUrl + damageReport.id,
        data
      );
    }
  }
}
