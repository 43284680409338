import { TimeEvent, TimelogState } from '../models';
import { StateMap } from '../types';

export const transitionConfig: StateMap = {
  [TimelogState.Unkown]: {
    [TimeEvent.WorkStart]: {
      to: TimelogState.Working,
      options: { insert: TimelogState.DayStarting },
    },
    [TimeEvent.Logout]: { to: TimelogState.Unkown },
  },
  [TimelogState.Working]: {
    [TimeEvent.DriveStart]: { to: TimelogState.CarDriving },
    [TimeEvent.IndividualStart]: { to: TimelogState.Individual },
    [TimeEvent.CarEquipStart]: { to: TimelogState.CarEquiping },
    [TimeEvent.Logout]: {
      to: TimelogState.Unkown,
      options: { insert: TimelogState.DayEnding },
    },
  },
  [TimelogState.Individual]: {
    [TimeEvent.IndividualEnd]: { to: TimelogState.Previous },
    [TimeEvent.Logout]: { to: TimelogState.Unkown },
  },
  [TimelogState.CarEquiping]: {
    [TimeEvent.IndividualStart]: { to: TimelogState.Individual },
    [TimeEvent.CarLogout]: { to: TimelogState.Working },
    [TimeEvent.CarEquipEnd]: { to: TimelogState.CarDriving },
  },
  [TimelogState.CarDriving]: {
    [TimeEvent.CarEquipStart]: { to: TimelogState.CarEquiping },
    [TimeEvent.BufferstockStart]: { to: TimelogState.BufferstockEquiping },
    [TimeEvent.CustomerStart]: { to: TimelogState.CustomerWorking },
    [TimeEvent.IndividualStart]: { to: TimelogState.Individual },
    [TimeEvent.Logout]: {
      to: TimelogState.Unkown,
      options: { insert: TimelogState.DayEnding },
    },
  },
  [TimelogState.BufferstockEquiping]: {
    [TimeEvent.MachineStart]: { to: TimelogState.MachineWorking },
    [TimeEvent.CustomerStart]: { to: TimelogState.CustomerWorking },
    [TimeEvent.DriveStart]: { to: TimelogState.CarDriving },
    [TimeEvent.IndividualStart]: { to: TimelogState.Individual },
  },
  [TimelogState.CustomerWorking]: {
    [TimeEvent.MachineStart]: { to: TimelogState.MachineWorking },
    [TimeEvent.CustomerStart]: { to: TimelogState.CustomerWorking },
    [TimeEvent.BufferstockStart]: { to: TimelogState.BufferstockEquiping },
    [TimeEvent.DriveStart]: { to: TimelogState.CarDriving },
    [TimeEvent.IndividualStart]: { to: TimelogState.Individual },
    [TimeEvent.CustomerEnd]: { to: TimelogState.Working },
  },
  [TimelogState.MachineWorking]: {
    [TimeEvent.CustomerStart]: { to: TimelogState.CustomerWorking },
    [TimeEvent.MachineStart]: { to: TimelogState.MachineWorking },
  },
};

/**
 * transitionConfig for manuel transitions by the user
 */
export const manuelTransitionConfig: StateMap = {
  [TimelogState.Unkown]: {
    [TimeEvent.WorkStart]: { to: TimelogState.Working },
  },
  [TimelogState.Working]: {
    [TimeEvent.DriveStart]: { to: TimelogState.CarDriving },
    [TimeEvent.IndividualStart]: { to: TimelogState.Individual },
    [TimeEvent.CarEquipStart]: { to: TimelogState.CarEquiping },
    [TimeEvent.Logout]: { to: TimelogState.Unkown },
  },
  [TimelogState.Individual]: {
    [TimeEvent.IndividualEnd]: { to: TimelogState.Previous },
    [TimeEvent.Logout]: { to: TimelogState.Unkown },
  },
  [TimelogState.CarEquiping]: {
    [TimeEvent.IndividualStart]: { to: TimelogState.Individual },
    [TimeEvent.CarLogout]: { to: TimelogState.Working },
    [TimeEvent.CarEquipEnd]: { to: TimelogState.CarDriving },
  },
  [TimelogState.CarDriving]: {
    [TimeEvent.CarEquipStart]: { to: TimelogState.CarEquiping },
    [TimeEvent.CustomerStart]: { to: TimelogState.CustomerWorking },
    [TimeEvent.IndividualStart]: { to: TimelogState.Individual },
    [TimeEvent.Logout]: { to: TimelogState.Unkown },
  },
  [TimelogState.BufferstockEquiping]: {
    [TimeEvent.CustomerStart]: { to: TimelogState.CustomerWorking },
    [TimeEvent.DriveStart]: { to: TimelogState.CarDriving },
    [TimeEvent.IndividualStart]: { to: TimelogState.Individual },
  },
  [TimelogState.CustomerWorking]: {
    [TimeEvent.DriveStart]: { to: TimelogState.CarDriving },
    [TimeEvent.IndividualStart]: { to: TimelogState.Individual },
  },
  [TimelogState.MachineWorking]: {
    [TimeEvent.CustomerStart]: { to: TimelogState.CustomerWorking },
  },
};

/**
 * Konfiguration für den Übergang in einen Zustand bei einem erzwungenen Zeitwechsel
 * - Wird verwendet beim Erzwingen eines Zeitwechsels abhängig vom übergebenen Event
 * - Stellt für jedes Event den Fallback-Zustand bereit, in den gewechselt werden soll
 */
export const forcedTransitionConfig: { [key in TimeEvent]: { to: TimelogState } } = {
  [TimeEvent.WorkStart]: { to: TimelogState.Working },
  [TimeEvent.DriveStart]: { to: TimelogState.CarDriving },
  [TimeEvent.IndividualStart]: { to: TimelogState.Individual },
  [TimeEvent.CarEquipStart]: { to: TimelogState.CarEquiping },
  [TimeEvent.CarEquipEnd]: { to: TimelogState.CarDriving },
  [TimeEvent.CarLogout]: { to: TimelogState.Working },
  [TimeEvent.BufferstockStart]: { to: TimelogState.BufferstockEquiping },
  [TimeEvent.CustomerStart]: { to: TimelogState.CustomerWorking },
  [TimeEvent.CustomerEnd]: { to: TimelogState.Working },
  [TimeEvent.MachineStart]: { to: TimelogState.MachineWorking },
  [TimeEvent.IndividualEnd]: { to: TimelogState.Previous },
  [TimeEvent.Logout]: { to: TimelogState.Unkown },
};