import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { DataService } from 'src/app/providers/data.service';
import { CarOrderModel } from '../models';

@Injectable({
  providedIn: 'root',
})
export class CarLoadingService extends DataService<CarOrderModel> {
  endpoint = 'car_management/loading/';
  objectName = 'order';

  picks(): Observable<any> {
    return this.http
      .get<any>(this.endpointWithUrl + 'picks/')
      .pipe(retry(1), catchError(this.errorService.convert));
  }

  orders(): Observable<any> {
    return this.http
      .get<any>(this.endpointWithUrl + 'orders/')
      .pipe(retry(1), catchError(this.errorService.convert));
  }

  contracts(): Observable<any> {
    return this.http
      .get<any>(this.endpointWithUrl + 'contracts/')
      .pipe(retry(1), catchError(this.errorService.convert));
  }
}
