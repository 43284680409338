<ion-list inset lines="none">
  <ion-item class="ion-margin-bottom">
    <div>
      <ion-text style="display: block;" color="tertiary">
        <strong>{{title}}</strong>
      </ion-text>
      <ion-text class="smallerText" color="tertiary">
        {{description}}
      </ion-text>
    </div>
  </ion-item>
  <ion-item [color]="opt.color ?? 'primary'" *ngFor="let opt of options" detail button
    class="roundedItemButton withMargin" (click)="chooseOption(opt)">
    <ion-icon *ngIf="opt.icon" [name]="opt.icon" slot="start" color="light" size="large"></ion-icon>
    <div class="ion-padding-vertical">
      <ion-text style="display: block;" color="light">
        <strong>{{opt.title}}</strong>
      </ion-text>
      <ion-text *ngIf="opt.description" class="smallerText" color="light">
        {{opt.description}}
      </ion-text>
    </div>
  </ion-item>
</ion-list>