import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SyncProcessorService } from '@vending/sync-engine-client';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { StatusModel } from 'src/app/models/settings/status';
import { ErrorService } from '../error.service';
import { EventsService } from '../events.service';
import { OfflineDataService } from '../offlineData.service';

@Injectable({
  providedIn: 'root',
})
export class StatusService extends OfflineDataService<StatusModel> {
  constructor(
    public indexedDBService: NgxIndexedDBService,
    public syncProcessor: SyncProcessorService,
    public http: HttpClient,
    public errorService: ErrorService,
    public events: EventsService
  ) {
    super(
      indexedDBService,
      syncProcessor,
      'System::State',
      http,
      errorService,
      events,
      'system/states/',
      'system_state',
      ['created_at', 'updated_at'],
      []
    );
  }
}
