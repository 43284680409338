import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MitsBadgeComponent } from './mits-badge.component';

@NgModule({
  imports: [CommonModule],
  declarations: [MitsBadgeComponent],
  entryComponents: [MitsBadgeComponent],
  exports: [MitsBadgeComponent],
  providers: [],
})
export class MitsBadgeModule {}
