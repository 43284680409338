import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  private eventBus: Record<string, Subject<any>> = {};

  /**
   * Einen EventBus seletieren
   * @param key of event
   * @returns subject
   */
  private selectBus(key: string): Subject<any> {
    let eventSubject = this.eventBus[key];

    if (!eventSubject) {
      eventSubject = new Subject<any>();
      this.eventBus[key] = eventSubject;
    }

    return eventSubject;
  }

  publish(key: string, data: any) {
    const eventSubject = this.selectBus(key);
    eventSubject.next(data);
  }

  subscribe(key: string): Subject<any> {
    return this.selectBus(key);
  }
}
