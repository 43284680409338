import { Injectable } from '@angular/core';
import { QueueEntry } from '@vending/sync-engine-client/lib/models/queueEntry';
import { firstValueFrom } from 'rxjs';
import { BasicModel } from 'src/app/models/basic';
import { OrderModel } from 'src/app/models/order';
import { TimelogModel } from 'src/app/models/system/timelog';
import { TimelogCategoryModel } from 'src/app/models/system/timelog-category';
import { FunctionSwitchHelperService } from 'src/app/providers/component-helpers/function-switch.service';
import { UserHelper } from 'src/app/providers/helpers/user-helper.service';
import { TimelogCategoryService } from 'src/app/providers/model-services/timelogs/timelog-category.service';
import { TimelogService } from 'src/app/providers/model-services/timelogs/timelog.service';
import { ZE_FOR_USER_OR_CAR } from 'src/assets/constants/functionSwitch.constants';
import { GlobalHelper } from 'src/packages/mitsBasics/helpers/globalHelper/global.helper';

@Injectable({ providedIn: 'root' })
export class OrderTimelogHelper {
  orderCategory: TimelogCategoryModel;
  dayCategory: TimelogCategoryModel;
  useTimeTracking: boolean = false;

  sortDate = (a, b) => GlobalHelper.substractDates(b.start_time, a.start_time);

  constructor(
    private readonly timelogCategoryService: TimelogCategoryService,
    private readonly timelogService: TimelogService,
    private readonly userHelper: UserHelper,
    private readonly fsHelper: FunctionSwitchHelperService
  ) {
    this.fsHelper.isLoaded.subscribe(() => {
      this.useTimeTracking = this.fsHelper.has(ZE_FOR_USER_OR_CAR);
    });
  }

  /**
   * Ends last open timelog if available and starts new timelog
   * @param obj managing the timlogs for
   * @returns updated order
   */
  public async updateTimelog(obj: OrderModel): Promise<OrderModel> {
    if (!this.useTimeTracking) return;
    const logs: TimelogModel[] = [
      ...(
        await firstValueFrom(
          this.timelogService.where({
            trackable_id: obj.id,
            trackable_type: 'Order',
          })
        )
      ).data,
    ].sort(this.sortDate);

    if (logs?.length > 0) {
      if (!logs[logs.length - 1].end_time) {
        await this.endLastTimelog(obj, logs, true);
        await this.startNewTimelog(logs, obj);
      } else {
        await this.startNewTimelog(logs, obj);
      }
    } else {
      await this.startNewTimelog(logs, obj);
    }
    return obj;
  }

  /**
   * Ends the last open timelog and saves it to the given order
   * @param obj order to update last timelog on
   * @param save should the timelog get saved?
   * @returns updated order
   */
  public async endLastTimelog(
    obj: BasicModel,
    logs?: TimelogModel[],
    save?: boolean
  ): Promise<TimelogModel[]> {
    if (!this.useTimeTracking) return;
    if (!logs || logs?.length === 0) {
      logs = (
        await firstValueFrom(
          this.timelogService.where({
            trackable_id: obj.id,
            trackable_type: 'Order',
          })
        )
      ).data;
    }
    logs = logs.sort(this.sortDate);
    const lastNoEndLogIndex = logs.findIndex((log) => !log.end_time);
    if (lastNoEndLogIndex === -1) return;
    logs[lastNoEndLogIndex].end_time = new Date();
    if (save) {
      logs[lastNoEndLogIndex] = await this.saveNonReadyTimelog(
        logs[logs.length - 1]
      );
      this.timelogService.saveLocal(logs[lastNoEndLogIndex]);
    }
    return logs;
  }

  /**
   * Starts a new timelog on the given order
   * @param logs timelogs to save the new timelog to
   * @param obj order to start the timelog on
   * @returns updated order
   */
  private async startNewTimelog(
    logs: TimelogModel[],
    obj: OrderModel
  ): Promise<OrderModel> {
    if (!this.orderCategory) await this.loadCategoryOrder();
    const newTimelog: TimelogModel = {} as TimelogModel;
    newTimelog.trackable_id = obj.id;
    newTimelog.trackable_type = 'Order';
    newTimelog.start_time = new Date();
    newTimelog.description = obj.state_name;
    newTimelog.timelog_category_id = this.orderCategory?.id;
    newTimelog.created_by_id = this.userHelper.getUser().id;
    newTimelog.client_id = this.generateClientID();
    const newLogSaved: TimelogModel =
      await this.saveNonReadyTimelog(newTimelog);
    await this.timelogService.saveLocal(newLogSaved);
    obj.timelogs.push(newLogSaved);
    logs.push(newLogSaved);
    return obj;
  }

  /**
   * Erzeugt eine Client-ID für den Timelog
   * @private
   */
  private generateClientID(): string {
    return `${
      this.userHelper.getUser().id
    }_${new Date().getTime()}_${Math.floor(Math.random() * 99)}`;
  }

  private async saveNonReadyTimelog(tl: TimelogModel) {
    if (tl.trackable_type != 'Order' || tl.trackable_id >= 0) {
      return firstValueFrom(this.timelogService.save(tl));
    }
    const savedData = await firstValueFrom(
      this.timelogService.saveAndGetQueued(tl)
    );
    const queueObj: QueueEntry<TimelogModel> =
      await this.timelogService.findQueued(savedData.queuedEntryId);
    queueObj.ready = false;
    await this.timelogService.updateQueued(queueObj);
    return savedData.obj;
  }

  private async loadCategoryOrder() {
    this.orderCategory = (
      await this.timelogCategoryService.localWhere({ name: 'Auftrag' })
    ).data[0];
  }
}
