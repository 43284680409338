import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { MitsBadgeModule } from 'src/app/components/mits-badge/mits-badge.module';
import { DevOptions } from './dev-options';
import { DevelopmentHelper } from '../../helpers/development.helper';

@NgModule({
  imports: [CommonModule, IonicModule, MitsBadgeModule],
  declarations: [DevOptions],
  exports: [DevOptions],
  providers: [DevelopmentHelper],
})
export class DevOptionsModule {}
