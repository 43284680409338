import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ITourChecklistService, TourChecklistModel } from 'src/packages/tourManagement/interfaces/checklist.service.interface';
import { ChecklistService } from 'src/packages/checklistManagement/providers/checklist.service';
import { IChecklist } from 'src/packages/checklistManagement/models/checklist';

@Injectable({
  providedIn: 'root',
})
export class ChecklistServiceTourAdapter implements ITourChecklistService {
  constructor(private checklistService: ChecklistService) { }

  async all(): Promise<TourChecklistModel[]> {
    const response = await firstValueFrom(this.checklistService.allRemote());
    return response.data.map(this.toTourChecklistModel);
  }

  private toTourChecklistModel(checklist: IChecklist): TourChecklistModel {
    return {
      id: checklist.id,
      name: checklist.name
    };
  }
}