import { Injectable } from '@angular/core';
import { IWriteableChecklist } from 'src/packages/checklistManagement/models/checklist';
import { ChecklistService } from 'src/packages/checklistManagement/providers/checklist.service';

@Injectable({
  providedIn: 'root',
})
export class ChecklistsHelper {
  constructor(private readonly checklistService: ChecklistService) {}

  /**
   * Loads all full Checklists and Deletes ID of those if checklist_id is not yet set
   */
  async mapAndDeleteIds(
    checklists: IWriteableChecklist[],
    for_type: 'Order' | 'AssemblyReport',
    for_id: number
  ): Promise<IWriteableChecklist[]> {
    if (!checklists) return [];
    const fullChecklists = await Promise.all(
      checklists
        .filter((c) => !!c)
        .map(async (c): Promise<IWriteableChecklist> => {
          if (c.checklist_id) return c;
          try {
            const result = await this.checklistService.localFind(c.id);
            if (result) {
              const resChecklist = result.content as IWriteableChecklist;
              if (for_id > 0) {
                resChecklist.for_object_id = for_id;
                resChecklist.for_object_type = for_type;
              }
              return resChecklist;
            } else {
              console.error('Checklist with ID: ' + c.id + ' not found');
              return c;
            }
          } catch (ex) {
            console.error(ex);
            return c;
          }
        })
    );
    return this.deleteChecklistsIds(fullChecklists);
  }

  /**
   * Deletes ID of the checklists if checklist_id is not yet set
   */
  private deleteChecklistsIds(
    checklists: IWriteableChecklist[]
  ): IWriteableChecklist[] {
    if (!checklists) return [];
    const mappedChecklists = checklists.map((c) => {
      if (!c.checklist_id) {
        const oldID = JSON.stringify(c.id);
        this.removeNotToUseParams(c);
        c.checklist_id = Number(oldID);
      }
      return c;
    });
    return mappedChecklists;
  }

  /**
   * Entfernt Parameter welche nicht gesendet werden sollen
   * @param hash to remove params of
   */
  public removeNotToUseParams(hash: any) {
    if (hash === undefined || hash == null) return;
    [
      'id',
      'group_id',
      'basic_module_id',
      'dependency_id',
      'checklist_id',
    ].forEach((p) => {
      delete hash[p];
    });
    Object.keys(hash).forEach((element) => {
      if (typeof hash[element] === 'object' || Array.isArray(hash[element]))
        this.removeNotToUseParams(hash[element]);
    });
  }
}
