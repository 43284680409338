import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { InitializationComponent } from 'src/app/components/initialization/initialization.component';

export type State = 'pending' | 'inProgress' | 'completed';

export interface Progress {
  percentage?: number;
  startedAt?: Date;
  timeRemaining?: number;
  startEvent: string;
  proggressEvent?: string;
  completedEvent: string;
  title: string;
  description?: string;
  status?: State;
}

@Injectable({ providedIn: 'root' })
export class InitializationHelper {
  private tasks: Progress[] = [];

  constructor(private readonly modalCtrl: ModalController) {}

  public addTask(task: Progress, asFirst: boolean = false) {
    if (asFirst) {
      this.tasks.unshift(task);
      return;
    }
    this.tasks.push(task);
  }

  public async runInitialization() {
    await this.openInitializationModal();
  }

  private async openInitializationModal() {
    const initialLoadingOverlay = await this.modalCtrl.create({
      component: InitializationComponent,
      backdropDismiss: false,
      componentProps: {
        tasks: this.tasks,
      },
    });
    await initialLoadingOverlay.present();
    await initialLoadingOverlay.onWillDismiss();
  }
}
