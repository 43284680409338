// tslint:disable: no-console
import { EventEmitter, Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
// internal
import { MileageService } from 'src/app/providers/model-services/orders/mileage.service';
import { TimelogService } from 'src/app/providers/model-services/timelogs/timelog.service';
import { OfflineDataService } from 'src/app/providers/offlineData.service';

interface DestroyServiceOptions {
  service: OfflineDataService<any>;
  destroyAfterMillis: number;
  destroyRunTresholdMillis: number;
  lastDestroyRun: Date;
  filterMethod: (object: any, millis: number) => boolean;
}

// Default time in milliseconds when to destroy a object
const DEFAULT_MILLIS_TO_DESTROY = 7 * 24 * 60 * 60 * 1000; // 7 Days
// Default time in milliseconds when to run the destroy action again
const DEFAULT_MILLIS_RUN_TRESHOLD = 8 * 60 * 60 * 1000; // 8 Hours

@Injectable({
  providedIn: 'root',
})
/**
 * Service to clean old data from device
 */
export class LocalDestroyService {
  constructor(
    // Hier müssen weitere offline verfügbare Dienste injiziert werden, damit diese im ProcessorService registriert sind
    public readonly mileageService: MileageService,
    public readonly timelogService: TimelogService
  ) {
    this.addService(this.mileageService);
    this.addService(this.timelogService);
  }

  /**
   * All registered services for local data cleaning
   */
  private services: Array<DestroyServiceOptions> = [];

  /**
   * Event wich gets triggered when the state of the destroy-action gets changed
   * (true if running, false if not)
   */
  public destroyStatusChanged: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  /**
   * Provides all registered services wich get cleaned
   * @returns registered services with theire options
   */
  public getServices(): Array<DestroyServiceOptions> {
    return this.services;
  }

  /**
   * Fügt intern den Service ein
   * @param service to add
   * @param millis after wich time the data should get destroyed (default: 7 Days)
   * @param treshold after wich time the destroy action should get run again (default: 8 Hours)
   */
  private addService(
    service: OfflineDataService<any>,
    millis: number = DEFAULT_MILLIS_TO_DESTROY,
    treshold: number = DEFAULT_MILLIS_RUN_TRESHOLD
  ) {
    this.services.push({
      service,
      destroyAfterMillis: millis,
      destroyRunTresholdMillis: treshold,
      lastDestroyRun: null,
      filterMethod: (o, m) => {
        const current = new Date().getTime();
        const updatedAt = new Date(o.updated_at).getTime();
        const destroyAfter = new Date(updatedAt + m).getTime();
        return current > destroyAfter;
      },
    });
  }

  /**
   * Start the filtering and deletion of on-device data
   */
  public async runLocalDataCleaner() {
    this.destroyStatusChanged.emit(true);
    const now: Date = new Date();
    const allDestroyPromises = this.services.map((s) => {
      if (s.lastDestroyRun && now.getTime() - s.lastDestroyRun.getTime() < s.destroyRunTresholdMillis) {
        console.debug(
          `[LocalDestroyService] Skipping ${s.service.objectName}`,
          `last run: ${s.lastDestroyRun}`,
          `next run: ${s.destroyRunTresholdMillis - (now.getTime() - s.lastDestroyRun.getTime())}ms`
        );
        return Promise.resolve();
      }
      s.lastDestroyRun = now;
      return this.findAndDestroyWithService(s);
    });
    await Promise.all(allDestroyPromises);
    this.destroyStatusChanged.emit(false);
  }

  /**
   * Finds and destroys all objects wich are outdated
   * @param opt Options from the services-array
   * @returns array of delete responses
   */
  private async findAndDestroyWithService(
    opt: DestroyServiceOptions
  ): Promise<any> {
    const oldData = (await firstValueFrom(opt.service.allWithOutPaging())).filter(
      (d) => opt.filterMethod(d, opt.destroyAfterMillis)
    );
    const allDeletePromises = oldData.map((od) =>
      opt.service.localDelete(od.id)
    );
    if (oldData.length > 0)
      console.debug(
        '[LocalDestroyService] Found and destroyed ' +
        oldData.length +
        ' outdated ' +
        opt.service.objectName +
        ' objects'
      );
    return Promise.all(allDeletePromises);
  }
}
