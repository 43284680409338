import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ToastService } from '../providers/component-helpers/toast.service';
import { EventsService } from '../providers/events.service';
import { SystemHelper } from '../providers/helpers/system-helper';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private alreadyShowedAuthError = false;

  constructor(
    private readonly events: EventsService,
    private readonly systemHelper: SystemHelper,
    private readonly toastService: ToastService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser?.token) {
      request = request.clone({
        setHeaders: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${currentUser.token}`,
        },
      });
    }

    return next.handle(request).pipe(
      tap({
        next: () => {},
        error: (err) => {
          if (err instanceof HttpErrorResponse) {
            if (
              err.status === 502 ||
              (err.status === 0 && this.systemHelper.isOnline)
            ) {
              this.systemHelper.isBackendOnline = false;
              return;
            }
            // AuthError nur Triggern, wenn gerade keine Abmeldung durchgeführt wird
            if (err.status === 401 && !this.systemHelper.isInLogoutProcess){
              this.handleAuthError();
            }
          }
        },
        complete: () => {
          this.systemHelper.isBackendOnline = true;
        },
      })
    );
  }

  /**
   * Display Toast that the user is not logged in anymore
   */
  private handleAuthError() {
    if (!this.alreadyShowedAuthError) {
      this.alreadyShowedAuthError = true;
      this.toastService.custom(
        'Die Sitzung ist abgelaufen. Bitte loggen Sie sich erneut ein.',
        'danger',
        0,
        [
          {
            text: 'Weiterarbeiten',
            role: 'cancel',
            handler: () => {
              setTimeout(() => {
                this.alreadyShowedAuthError = false;
              }, 30 * 1000);
            },
          },
          {
            text: 'Abmelden',
            role: 'submit',
            handler: () => {
              this.events.publish('logout', null);
              this.alreadyShowedAuthError = false;
            },
          },
        ]
      );
    }
  }
}
