import { ITemplateElementStyle } from './tp-em-style';
import { ITemplateRow } from './tp-row';

export interface ITemplateColumn {
  /** Element in the column */
  module: any;
  /** Grid-Rows of the col */
  rows: ITemplateRow[];
  /** Styling of the column */
  element_style: ITemplateElementStyle;
  /** Title of this column */
  title: string;
  /** Index to sort the cols inside the parent-element */
  sort_index?: number;
  /** Muss auf true gesetzt werden wenn das element gelöscht werden soll */
  _destroy?: boolean;

  attribute_key?: string;

  // Bearbeitbar?
  disabled?: boolean;
}

export function instanceOfITemplateColumn(
  object: any
): object is ITemplateColumn {
  return 'rows' in object && !('cols' in object);
}
