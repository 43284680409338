import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
// internal
import { UserHelper } from 'src/app/providers/helpers/user-helper.service';
import { StockInventoryHelperService } from 'src/packages/warehouse/helpers/stock-inventory-helper';
import { StockBooking } from 'src/packages/warehouse/models';
import { StockMovementModel } from 'src/packages/warehouse/models/stockMovement';

/**
 * Service für die Verbuchung der Warehouse::StockMovements auf Basis der Positionen eines StockBookings
 */
@Injectable({
  providedIn: 'root',
})
export class StockBookingService {
  constructor(
    public readonly stockHelper: StockInventoryHelperService,
    private readonly userHelper: UserHelper
  ) {
  }

  /**
   * Verbuch ein StockBooking als einzelne Movements
   * @param stockBooking - StockBooking Objekt, dessen Positionen verbucht werden sollen
   * @return - Observable mit dem Fortschritt der Buchung
   */
  public save(stockBooking: StockBooking): Observable<number> {
    const dataToSend: StockMovementModel[] = stockBooking.buildStockMovements(
      this.userHelper.getUser()?.id
    );
    return new Observable<number>((observer) => {
      let counter = 0;
      dataToSend.forEach((movement: StockMovementModel): void => {
        this.stockHelper.book(movement).then(
          (): void => {
            counter++;
            observer.next((counter / dataToSend.length));
            if (counter === dataToSend.length) {
              observer.complete();
            }
          },
          (error): void => {
            observer.error(error);
          }
        );
      });
    });
  }

  /**
   * Name des Icons für die Klasse
   * @return Icon-Name für die Klasse
   */
  public get iconName(): string {
    return 'file-tray-full-outline';
  }
}
