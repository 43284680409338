import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SyncProcessorService } from '@vending/sync-engine-client';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { catchError, Observable, retry } from 'rxjs';
import { ClientDeviceModel } from 'src/app/models/settings/client-device';
import { ErrorService } from '../error.service';
import { EventsService } from '../events.service';
import { OfflineDataService } from '../offlineData.service';

@Injectable({
  providedIn: 'root',
})
export class ClientDeviceService extends OfflineDataService<ClientDeviceModel> {
  constructor(
    public indexedDBService: NgxIndexedDBService,
    public syncProcessor: SyncProcessorService,
    public http: HttpClient,
    public errorService: ErrorService,
    public events: EventsService
  ) {
    super(
      indexedDBService,
      syncProcessor,
      'ClientDevice',
      http,
      errorService,
      events,
      'client_devices/',
      'client_device',
      ['created_at', 'updated_at', 'created_by_id'],
      []
    );
  }

  /**
   * Name des Icons für die Klasse
   * @return {string}
   */
  public get iconName(): string {
    return 'tablet-portrait';
  }

  findBy(args: any): Observable<any> {
    return this.http
      .get<any>(this.endpointWithUrl + 'device/', { params: args })
      .pipe(retry(1), catchError(this.errorService.convert));
  }
}
