import { Injectable, OnInit } from '@angular/core';
import { OrderModel } from 'src/app/models/order';
import { StatusModel } from 'src/app/models/settings/status';
import { StatusService } from 'src/app/providers/model-services/status.service';
import { GlobalHelper } from 'src/packages/mitsBasics/helpers/globalHelper/global.helper';
import {
  TimeEvent,
  TimelogHelperV2,
  TimelogState,
} from 'src/packages/timeManagement';
import { OrderTimelogHelper } from './order-timelog-helper';

// Schlüssel im Localstorage wo die OrderId gespeichert wird,
// falls z.B. die App neugestartet wird.
export const CURRENT_ORDER_STORAGE = 'OrderStateManager.previousOrderId';

// Konstanten für Order-States
export const ORDER_STATE_OFFEN = 'Offen';
export const ORDER_STATE_UEBERMITTELT = 'Übermittelt';
export const ORDER_STATE_ANFAHRT = 'Anfahrt';
export const ORDER_STATE_IN_BEARBEITUNG = 'In Bearbeitung';
export const ORDER_STATE_PAUSE = 'Pause';
export const ORDER_STATE_ABGESCHLOSSEN = 'Abgeschlossen';
export const ORDER_STATE_ABGERECHNET = 'Abgerechnet';
export const ORDER_STATE_AUFTRAG_ABGEBROCHEN = 'Auftrag abgebrochen';
export const ORDER_STATE_UNTERSCHRIFT_VORBEREITUNG_ABREISE = 'Unterschrift / Vorbereitung Abreise';


export type OrderState =
  | typeof ORDER_STATE_OFFEN
  | typeof ORDER_STATE_UEBERMITTELT
  | typeof ORDER_STATE_ANFAHRT
  | typeof ORDER_STATE_IN_BEARBEITUNG
  | typeof ORDER_STATE_PAUSE
  | typeof ORDER_STATE_ABGESCHLOSSEN
  | typeof ORDER_STATE_ABGERECHNET
  | typeof ORDER_STATE_AUFTRAG_ABGEBROCHEN
  | typeof ORDER_STATE_UNTERSCHRIFT_VORBEREITUNG_ABREISE;

const OrderStateTranslation: Record<OrderState, TimeEvent> = {
  [ORDER_STATE_OFFEN]: null,
  [ORDER_STATE_PAUSE]: null,
  [ORDER_STATE_ANFAHRT]: TimeEvent.DriveStart,
  [ORDER_STATE_IN_BEARBEITUNG]: TimeEvent.CustomerStart,
  [ORDER_STATE_ABGESCHLOSSEN]: TimeEvent.CustomerStart,
  [ORDER_STATE_AUFTRAG_ABGEBROCHEN]: TimeEvent.CustomerStart,
  [ORDER_STATE_UNTERSCHRIFT_VORBEREITUNG_ABREISE]: null,
  [ORDER_STATE_ABGERECHNET]: null,
  [ORDER_STATE_UEBERMITTELT]: null,
};

const SAME_STATES: Record<OrderState, TimelogState> = {
  [ORDER_STATE_OFFEN]: null,
  [ORDER_STATE_PAUSE]: null,
  [ORDER_STATE_ANFAHRT]: TimelogState.CarDriving,
  [ORDER_STATE_IN_BEARBEITUNG]: TimelogState.CustomerWorking,
  [ORDER_STATE_ABGESCHLOSSEN]: null,
  [ORDER_STATE_AUFTRAG_ABGEBROCHEN]: null,
  [ORDER_STATE_UNTERSCHRIFT_VORBEREITUNG_ABREISE]: null,
  [ORDER_STATE_ABGERECHNET]: null,
  [ORDER_STATE_UEBERMITTELT]: null,
};

// Zustände die pausiert werden können
const PAUSABLE_ORDER_STATES = [
  ORDER_STATE_ANFAHRT,
  ORDER_STATE_IN_BEARBEITUNG
];

/**
 * Verwaltet den Zustand des Auftrages
 */
@Injectable({ providedIn: 'root' })
export class OrderStateManager implements OnInit {
  private _currentOrder: OrderModel;

  // Vorheriger Auftrag
  // wird erst gesetzt, wenn der Status beim aktuellen Auftrag verändert wird.
  private _previousOrder: OrderModel | undefined;

  private states: StatusModel[];

  // Callback, wenn der vorherige Auftrag gespeichert werden soll
  public localSaveOrder: (order: OrderModel) => Promise<any> | undefined;
  public localLoadOrder: (id: number) => Promise<OrderModel> | undefined;

  constructor(
    private readonly orderTimelogHelper: OrderTimelogHelper,
    private readonly statusService: StatusService,
    private readonly timelogHelper: TimelogHelperV2
  ) {}

  public async ngOnInit(): Promise<void> {
    try {
      const orderId = parseInt(localStorage.getItem(CURRENT_ORDER_STORAGE));
      if (
        !!this.localLoadOrder &&
        !GlobalHelper.isZero(orderId) &&
        !Number.isNaN(orderId)
      ) {
        let order = await this.localLoadOrder(orderId);
        if (order) {
          this.previousOrder = order;
        }
      }
    } catch (ex) {
      console.error(ex);
    }
  }

  public get currentOrder(): OrderModel {
    return this._currentOrder;
  }

  public get previousOrder(): OrderModel | undefined {
    return this._previousOrder;
  }

  private set previousOrder(v: OrderModel | undefined) {
    this._previousOrder = v;
    if (v) {
      localStorage.setItem(CURRENT_ORDER_STORAGE, v.id.toString());
    } else {
      localStorage.removeItem(CURRENT_ORDER_STORAGE);
    }
  }

  /**
   * Aktuelle Order setzen
   */
  public set currentOrder(v: OrderModel) {
    if (!GlobalHelper.isNullOrUndefined(v) && v !== this._currentOrder) {
      this._currentOrder = v;

      if (
        !GlobalHelper.isNullOrUndefined(this.previousOrder) &&
        this.previousOrder.id == this._currentOrder.id
      ) {
        this.previousOrder = this.currentOrder;
      }
    }
  }

  /**
   * Zustand des Auftrages ändern
   */
  public async changeStatus(statusName: OrderState) {
    if (GlobalHelper.isNullOrUndefined(this._currentOrder)) {
      return false;
    } else {
      console.debug(
        `OrderStateManager.changeStatus(${statusName}), Order-ID: ${this._currentOrder.id}`
      );

      const state = await this.findState(statusName);

      if (GlobalHelper.isNullOrUndefined(state)) {
        return false;
      }

      // für den aktuellen Auftrag
      // ...den Status setzen
      this.setStateToOrder(state);
      // ...die Zeiteinträge stoppen
      await this.updateOrStopTimelogs(this._currentOrder);

      if (
        OrderStateTranslation[statusName] &&
        SAME_STATES[statusName] !== this.timelogHelper.currentState
      )
        await this.timelogHelper.changeStateDebounce(
          OrderStateTranslation[statusName],
          {
            customer: this._currentOrder.customer,
          }
        );

      if (
        !GlobalHelper.isNullOrUndefined(this.previousOrder) &&
        this.previousOrder.id !== this._currentOrder.id
      ) {
        if (OrderStateManager.isPausable(this.previousOrder)) {
          // ...den Status setzen, sofern notwendig
          await this.setPreviousOrderToPause();
          // ...die Zeiteinträge stoppen
          await this.updateOrStopTimelogs(this.previousOrder);
          // ...save Callback auslösen
          await this.savePreviousOrder();
        }
        // ...den vorherigen Auftrag entfernen
        this.previousOrder = undefined;
      }

      // Wenn Auftrag in Bearbeitung oder Anfahrt geht, gegebenenfalls zurücksetzen
      if (OrderStateManager.isPausable(this._currentOrder)) {
        this.previousOrder = this.currentOrder;
      }

      return true;
    }
  }

  /**
   * Ist der Auftrag pausierbar?
   * @param order
   * @returns
   */
  private static isPausable(order: OrderModel) {
    return PAUSABLE_ORDER_STATES.includes(order.state?.name);
  }

  /**
   * Vorherigen Auftrag über einen Callback speichern, sofern dies definiert ist.
   */
  private async savePreviousOrder() {
    if (!GlobalHelper.isNullOrUndefined(this._previousOrder)) {
      if (!GlobalHelper.isNullOrUndefined(this.localSaveOrder))
        await this.localSaveOrder(this._previousOrder);
      else
        console.warn(
          `Vorherige Auftrag ${this.previousOrder.id} wurde nicht im Zustand Pause gespeichert!`
        );
    }
  }

  private async setPreviousOrderToPause() {
    if (!GlobalHelper.isNullOrUndefined(this._previousOrder)) {
      const state = await this.findState('Pause');

      OrderStateManager.setState(this._previousOrder, state);
    }
  }

  /**
   * Status anhand des Namens finden
   * @param statusName
   * @returns
   */
  public async findState(statusName: OrderState): Promise<StatusModel> {
    await this.loadStates();
    return this.states.find((s) => s.name === statusName.toString());
  }

  /**
   * Aktueller Status des Auftrages
   */
  public get currentState(): StatusModel {
    return this._currentOrder.state;
  }

  /**
   * Soll der Auftrag verlassen werden?
   */
  public get shouldExitOrder(): boolean {
    return (
      this.currentState.id === 6 ||
      this.currentState.name === 'Auftrag abgebrochen'
    );
  }

  /**
   * Wird angefahren?
   */
  public get approaching(): boolean {
    return this.currentState.name === 'Anfahrt';
  }

  /**
   * Status am Auftrag setzen
   * @param state
   */
  private setStateToOrder(state: StatusModel) {
    OrderStateManager.setState(this._currentOrder, state);
  }

  private static setState(order: OrderModel, state: StatusModel) {
    console.debug(
      `OrderStateManager.setState(${state.name}), Order-ID: ${order.id}`
    );
    order.state_id = state.id;
    order.state_name = state.name;
    order.state = Object.assign({}, state);
    order.finished = state.id === 6;
    order.updated_at = new Date();
  }

  /**
   * Timelogs aktualisieren je nach Statuswechsel
   */
  private async updateOrStopTimelogs(order: OrderModel) {
    if (!this.timelogHelper.useTimeTracking) return;
    if (this.shouldExitOrder) {
      order.timelogs = await this.orderTimelogHelper.endLastTimelog(
        order,
        null,
        true
      );
    } else {
      // Normaler Statuswechsel z.B. Pause, In Bearbeitung, ...
      await this.orderTimelogHelper.updateTimelog(order);
    }
  }

  public async loadStates() {
    if (
      GlobalHelper.isNullOrUndefined(this.states) ||
      this.states.length === 0
    ) {
      this.states = await this.statusService.localWhereWithoutPaging({});
    }
  }
}
