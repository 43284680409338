import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SyncProcessorService } from '@vending/sync-engine-client';
import { StoredData } from '@vending/sync-engine-client/lib/models/storedData';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { firstValueFrom, Observable } from 'rxjs';
// internal
import { ErrorService } from 'src/app/providers/error.service';
import { EventsService } from 'src/app/providers/events.service';
import {
  ActiveInventoriesCounterService
} from 'src/app/providers/state-services/active-intentories-counter.state.service';
import { StockObjectModel } from 'src/packages/warehouse/models/stock-object';
import { StockWithInventoryService } from 'src/packages/warehouse/providers/StockWithInventory.service';

// Präfixe für die Fulltext-Suche
const FULLTEXT_INVENTORY_REQUIRED_PREFIX = 'INR';
const FULLTEXT_LINKED_TO_ID_PREFIX = 'LID';

// Typen für die Präfixe
type FullTextPrefix = typeof FULLTEXT_INVENTORY_REQUIRED_PREFIX | typeof FULLTEXT_LINKED_TO_ID_PREFIX;


@Injectable({
  providedIn: 'root',
})
export class StockObjectService extends StockWithInventoryService<StockObjectModel> {
  constructor(
    public indexedDBService: NgxIndexedDBService,
    public syncProcessor: SyncProcessorService,
    public http: HttpClient,
    public errorService: ErrorService,
    public events: EventsService,
    private stateService: ActiveInventoriesCounterService,
  ) {
    super(
      indexedDBService,
      syncProcessor,
      'Warehouse::Stock',
      http,
      errorService,
      events,
      'warehouse/stocks/',
      'warehouse_stock',
      [],
      ['positions'],
      'inventory_required_by_id'
    );
    this.bulkSize = 10;
    this.bulkSyncActive = true;
  }

  //////////////////////////////////////////////////////////////
  //// Öffentliche Methoden

  /**
   * Suche nach allen Stocks anhand des Attributs 'required' mithilfe des Fulltext
   * @param required - true, wenn Stocks mit Inventory-Required gesucht werden sollen
   * @return Promise<StockObjectModel[]> - die gefundenen Stocks
   */
  public async allByInventoryRequired(required: boolean = true): Promise<StockObjectModel[]> {
    return this.fullTextAllWithPrefix(required.toString(), FULLTEXT_INVENTORY_REQUIRED_PREFIX);
  }

  /**
   * Suche nach allen Stocks anhand des Attributs 'linked_to_id' mithilfe des Fulltext
   * @param linkedToId - die ID des verknüpften Standorts des Warehouse::Stock
   * @return Promise<StockObjectModel> - der gefundene Stock oder undefined
   */
  public async findByLinkedToId(linkedToId: number): Promise<StockObjectModel> {
    return this.fullTextFindWithPrefix(linkedToId.toString(), FULLTEXT_LINKED_TO_ID_PREFIX);
  }

  /**
   * Fulltext neu aufbauen für alle Stocks
   * @param obj - optional: die einzelne Entität, für die der Fulltext neu aufgebaut werden soll
   */
  public async rebuildFullText(obj?: StockObjectModel): Promise<void> {
    let stockObjects: StoredData<StockObjectModel>[];
    if (!obj) {
      stockObjects = await firstValueFrom(this.localAllStoredData());
    } else {
      stockObjects = [this.createStoredData(obj)];
    }
    for (const s of stockObjects) {
      this.enqueueFullTextEntries(s);
    }
  }

  /**
   * Erforderlichkeit der Inventur für einen Stock entfernen
   * - Online-Service: Setzt das Attribut 'inventory_required' auf false und 'inventory_required_by_id' auf null
   * @param id - Die ID des Stocks, für den die Erforderlichkeit der Inventur entfernt werden soll
   * @return Observable<StockObjectModel> - der aktualisierte Stock-Object
   */
  public cancelRequiredInventoryRemote(id: number): Observable<StockObjectModel> {
    return this.http.post<StockObjectModel>(
      `${this.endpointWithUrl}cancel_inventory/${id}`,
      ''
    );
  }

  //////////////////////////////////////////////////////////////
  //// Überschriebene Methoden des OfflineDataService

  /**
   * Daten aus dem lokalen Datenbestand löschen
   * @param id - ID der Entität, die gelöscht werden soll
   * @return Promise<void> - Promise, das aufgelöst wird, wenn die Entität gelöscht wurde
   */
  public async localDelete(id: number): Promise<void> {
    this.stateService.onLocalDelete(id);
    const currentObj: StoredData<StockObjectModel> = await this.localFind(id);
    await super.localDelete(id);
    if (currentObj) {
      this.enqueueFullTextEntries(currentObj, true);
    }
  }

  //////////////////////////////////////////////////////////////
  //// Überschriebene Methoden des SyncService
  /**
   * Aktualisiert beim Speichern die Fulltext-Queue, falls sich die für den Fulltext relevanten Daten geändert haben
   * @param obj - die Entität, die gespeichert wird
   * @param updatedAt - das Änderungsdatum
   * @protected
   */
  protected override async store(obj: StockObjectModel, updatedAt: string): Promise<boolean | StoredData<StockObjectModel>> {
    const newObj: StoredData<StockObjectModel> = this.createStoredData(obj);
    const currentObj: StoredData<StockObjectModel> = await this.localFind(obj.id);
    if (currentObj) {
      if (
        currentObj.content.inventory_required !== obj.inventory_required ||
        currentObj.content.inventory_required_by_id !== obj.inventory_required_by_id
      ) {
        this.enqueueFullTextEntries(currentObj, true);
        this.enqueueFullTextEntries(newObj, false);
      }
    } else {
      this.enqueueFullTextEntries(newObj);
    }
    // Weitergeben der Entität an den State-Service, um Aktive Invneturen zu zählen
    const storedObj: boolean | StoredData<StockObjectModel> | void = await super.store(obj, updatedAt);
    if (storedObj && (storedObj as StoredData<StockObjectModel>)?.content) {
      this.stateService.onStore((storedObj as StoredData<StockObjectModel>).content);
    }
    return storedObj;
  }

  //////////////////////////////////////////////////////////////
  //// Private Hilfsmethoden

  /**
   * Fügt einen Eintrag mit Präfix zur Fulltext-Queue hinzu
   * @param obj - die Entität
   * @param prefix - das Präfix
   * @param remove - true, wenn der Eintrag entfernt werden soll, statt hinzugefügt
   * @private
   */
  private enqueueFullTextEntryWithPrefix(
    obj: StoredData<StockObjectModel>,
    prefix: FullTextPrefix,
    remove: boolean = false): void {
    let fulltextKeyWithPrefix: string = prefix + '_';
    switch (prefix) {
      case 'INR':
        fulltextKeyWithPrefix += obj.content.inventory_required;
        break;
      case 'LID':
        fulltextKeyWithPrefix += obj.content.linked_to_id;
        break;
    }

    this.fulltextService.insertIntoWorkflowQueue(
      fulltextKeyWithPrefix.toLowerCase(),
      obj.content.id * (remove ? -1 : 1),
      this.type,
    );
  }

  /**
   * Fügt Einträge mit Präfix zur Fulltext-Queue hinzu (bzw. entfernt sie)
   * @param obj - die Entität, für die die Einträge hinzugefügt werden sollen
   * @param remove - true, wenn die Einträge entfernt werden sollen, statt hinzugefügt
   * @private
   */
  private enqueueFullTextEntries(obj: StoredData<StockObjectModel>, remove: boolean = false): void {
    if (obj.content.inventory_required !== undefined) this.enqueueFullTextEntryWithPrefix(obj, FULLTEXT_INVENTORY_REQUIRED_PREFIX, remove);
    if (obj.content.linked_to_id) this.enqueueFullTextEntryWithPrefix(obj, FULLTEXT_LINKED_TO_ID_PREFIX, remove);
  }

  /**
   * Finden einer Entität über den Fulltext mit einem Präfix
   * @param input - Suchbegriff für das Attribut
   * @param prefix - Präfix für die Suche
   * @return Promise<MachineModel | undefined> - die gefundene Entität oder undefined
   */
  private async fullTextFindWithPrefix(input: string, prefix: FullTextPrefix): Promise<StockObjectModel | undefined> {
    const normalizedInput: string = input?.trim().toLowerCase();
    if (!normalizedInput || normalizedInput.length === 0) return undefined;

    const result: StockObjectModel[] = await this.fullTextAllWithPrefix(normalizedInput, prefix);
    if (result?.length > 0) {
      return result[0];
    }
    return undefined;
  }

  /**
   * Finden aller Entitäten über den Fulltext mit einem Präfix
   * @param input - Suchbegriff für das Attribut
   * @param prefix - Präfix für die Suche
   * @return Promise<StockObjectModel[]> - die gefundenen Entitäten
   */
  private async fullTextAllWithPrefix(input: string, prefix: FullTextPrefix): Promise<StockObjectModel[]> {
    const normalizedInput: string = input?.trim().toLowerCase();
    if (!normalizedInput || normalizedInput.length === 0) return [];

    return this.fullTextSearch(`${prefix}_${input}`);
  }
}
