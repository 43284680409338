import { SIZE_TO_MEDIA } from '@ionic/core/dist/collection/utils/media';

export class ToggleMenuHelper {
  /**
   * Show or hide the side-menu
   */
  public toggleMenu(): void {
    const splitPane = document.querySelector('ion-split-pane');
    if (
      window.matchMedia(SIZE_TO_MEDIA[splitPane.when] || splitPane.when).matches
    ) {
      splitPane.classList.toggle('split-pane-visible');
    }
  }
}
