import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

export const DAMAGE_MANAGEMENT_BASE_ROUTE = 'damageManagement';

const routes: Routes = [
  {
    path: 'damage-reports/:damageReportId/damages/:damageId',
    loadChildren: () =>
      import('./pages/damage-detail/damage-detail.module').then(
        (m) => m.DamageDetailPageModule
      ),
  },
  {
    path: 'machines/:machineId/damage-reports/create',
    loadChildren: () =>
      import('./pages/damage-report-detail/damage-report-detail.module').then(
        (m) => m.DamageReportDetailPageModule
      ),
  }, 
  {
    path: 'machines/:machineId/damage-reports/create/damage',
    loadChildren: () =>
      import('./pages/damage-new/damage-new.module').then(
        (m) => m.DamageNewPageModule
      ),
  },
  {
    path: 'customers/:customerId/damages',
    loadChildren: () =>
      import('./pages/damages/damages.module').then(
        (m) => m.DamagesPageModule
      ),
  },
  {
    path: 'damage_categories',
    loadChildren: () =>
      import('./pages/damage-categories/damage-categories.module').then(
        (m) => m.DamageCategoriesPageModule
      ),
  },
  {
    path: 'damage_reports',
    loadChildren: () =>
      import('./pages/damage-reports/damage-reports.module').then(
        (m) => m.DamageReportsPageModule
      ),
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DamageManagementRoutingModule {}
