import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TimePipeModule } from 'src/app/pipes/time/time.module';
import { MitsHeaderModule } from '../mits-header/mits-header.module';
import { InitializationComponent } from './initialization.component';

@NgModule({
  imports: [CommonModule, IonicModule, MitsHeaderModule, TimePipeModule],
  declarations: [InitializationComponent],
  entryComponents: [InitializationComponent],
  exports: [InitializationComponent],
  providers: [],
})
export class InitializationModule {}
