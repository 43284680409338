import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SyncProcessorService } from '@vending/sync-engine-client';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { Observable } from 'rxjs';
import { ErrorService } from 'src/app/providers/error.service';
import { EventsService } from 'src/app/providers/events.service';
import { OfflineDataService } from 'src/app/providers/offlineData.service';
import { WarehouseOrderModel } from '../models/warehouse-order';

@Injectable({
  providedIn: 'root',
})
export class WarehouseOrderService extends OfflineDataService<WarehouseOrderModel> {
  constructor(
    public indexedDBService: NgxIndexedDBService,
    public syncProcessor: SyncProcessorService,
    public http: HttpClient,
    public errorService: ErrorService,
    public events: EventsService
  ) {
    super(
      indexedDBService,
      syncProcessor,
      'Warehouse::Order',
      http,
      errorService,
      events,
      'warehouse/orders/',
      'warehouse_order',
      [],
      ['positions', 'bufferstock', 'basket'],
      'bufferstock_id'
    );
  }

  /**
   * Name des Icons für die Klasse
   * @return {string}
   */
  public get iconName(): string {
    return 'document-attach-outline';
  }

  protected cloneObjekt(object: WarehouseOrderModel): WarehouseOrderModel {
    let cloned = { ...object };
    delete cloned.bufferstock;
    delete cloned.basket;
    // Entfernt die Verknüpfung zu einem Lagerobjekt um eine circular structure zu verhindern
    if (object.linked_to?.stock_object) {
      delete (object.linked_to.stock_object as any).linked_to;
    }
    if (object.position_disposals)
      cloned.position_disposals = object.position_disposals.map((pos) => {
        let clonedPos = { ...pos };
        delete clonedPos.bufferstock;
        return clonedPos;
      });
    if (object.positions)
      cloned.positions = object.positions.map((pos) => {
        let clonedPos = { ...pos };
        return clonedPos;
      });

    return cloned;
  }

  /**
   * Erzeugt/Speichert ein Objekt im Backend (je nachdem ob ID gesetzt)
   * @param object Zu speicherndes/erzeugendes Objekt
   */
  override saveRemote(
    object: WarehouseOrderModel
  ): Observable<WarehouseOrderModel> {
    const cloned = this.cloneObjekt(object);
    return this.dataService.save(cloned);
  }
}
