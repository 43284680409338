/**
 * Enum für die verschiedenen Zeitereignisse
 * Sie verändern den Zustand des TimelogHelpers
 */
export enum TimeEvent {
  WorkStart = 'WorkStart', // App starten
  DriveStart = 'DriveStart', // Fahren
  CarEquipStart = 'CarEquipStart', // Auto rüsten
  CarEquipEnd = 'CarEquipEnd', // Auto rüsten beenden
  CarLogout = 'CarLogout', // Auto abmelden
  BufferstockStart = 'BufferstockStart', // Lager rüsten
  MachineStart = 'MachineStart', // Bearbeitung an dem Automat starten
  CustomerStart = 'CustomerStart', // Kundenzeit starten
  CustomerEnd = 'CustomerEnd', // Kundenzeit beenden
  IndividualStart = 'IndividualStart', // Individuelle Zeit starten
  IndividualEnd = 'IndividualEnd', // Individuelle Zeit beenden
  Logout = 'Logout', // App aktiv ausloggen (nur bei klick auf "Ausloggen")
}
