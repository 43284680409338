import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SyncProcessorService } from '@vending/sync-engine-client';
import { StoredData } from '@vending/sync-engine-client/lib/models/storedData';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { firstValueFrom } from 'rxjs';
// internal
import { CustomerLocationModel } from 'src/app/models/customers/location';
import { ErrorService } from 'src/app/providers/error.service';
import { EventsService } from 'src/app/providers/events.service';
import { OfflineDataService } from 'src/app/providers/offlineData.service';

// Präfixe für die Fulltext-Queue
const FULLTEXT_REMOTE_ID_PREFIX = 'RID';
const FULLTEXT_LOCATION_NUMBER_PREFIX = 'LNU';

// Typen für die Präfixe
type FullTextPrefix =
  | typeof FULLTEXT_REMOTE_ID_PREFIX
  | typeof FULLTEXT_LOCATION_NUMBER_PREFIX;

@Injectable({
  providedIn: 'root',
})
export class LocationBufferstockService extends OfflineDataService<CustomerLocationModel> {
  constructor(
    public indexedDBService: NgxIndexedDBService,
    public syncProcessor: SyncProcessorService,
    public http: HttpClient,
    public errorService: ErrorService,
    public events: EventsService
  ) {
    super(
      indexedDBService,
      syncProcessor,
      'Customers::LocationBufferstock',
      http,
      errorService,
      events,
      'customers/locations/',
      'location',
      ['created_at', 'updated_at'],
      []
    );
    this.bulkSyncActive = true;
  }


  //////////////////////////////////////////////////////////////
  //// Öffentliche Methoden
  /**
   * Suche nach eines Bufferstock-Standorts anhand des Attributs 'remote_id' mithilfe des Fulltext
   * @param remoteId - die Remote-ID anhand dessen gesucht werden soll
   * @return Promise<CustomerLocationModel | undefined> - der gefundene Bufferstock-Standort oder undefined
   */
  findByRemoteId(remoteId: string): Promise<CustomerLocationModel | undefined> {
    return this.fullTextFindWithPrefix(remoteId, FULLTEXT_REMOTE_ID_PREFIX);
  }

  /**
   * Suche nach allen Bufferstock-Standorten anhand des Attributs 'remote_id' mithilfe des Fulltext
   * @param remoteId - die Remote-ID anhand dessen gesucht werden soll
   * @return Promise<CustomerLocationModel[]> - die gefundenen Bufferstock-Standorte
   */
  allByRemoteId(remoteId: string): Promise<CustomerLocationModel[]> {
    return this.fullTextAllWithPrefix(remoteId, FULLTEXT_REMOTE_ID_PREFIX);
  }

  /**
   * Suche nach eines Bufferstock-Standorts anhand des Attributs 'location_number' mithilfe des Fulltext
   * @param locationNumber - die Standortnummer anhand dessen gesucht werden soll
   * @return Promise<CustomerLocationModel | undefined> - der gefundene Bufferstock-Standort oder undefined
   */
  findByLocationNumber(locationNumber: string): Promise<CustomerLocationModel | undefined> {
    return this.fullTextFindWithPrefix(locationNumber, FULLTEXT_LOCATION_NUMBER_PREFIX);
  }

  /**
   * Suche nach allen Bufferstock-Standorten anhand des Attributs 'location_number' mithilfe des Fulltext
   * @param locationNumber - die Standortnummer anhand dessen gesucht werden soll
   * @return Promise<CustomerLocationModel[]> - die gefundenen Bufferstock-Standorte
   */
  allByLocationNumber(locationNumber: string): Promise<CustomerLocationModel[]> {
    return this.fullTextAllWithPrefix(locationNumber, FULLTEXT_LOCATION_NUMBER_PREFIX);
  }

  /**
   * Fulltext neu aufbauen für alle Entitäten
   * @param obj - optional: die Entität, für die der Fulltext neu aufgebaut werden soll
   */
  public async rebuildFullText(obj?: CustomerLocationModel): Promise<void> {
    let stockObjects: StoredData<CustomerLocationModel>[];
    if (!obj) {
      stockObjects = await firstValueFrom(this.localAllStoredData());
    } else {
      stockObjects = [this.createStoredData(obj)];
    }
    for (const s of stockObjects) {
      this.enqueueFullTextEntries(s);
    }
  }

  //////////////////////////////////////////////////////////////
  //// Überschriebene Methoden des OfflineDataService

  /**
   * Daten aus dem lokalen Datenbestand löschen
   * @param id - ID der Entität, die gelöscht werden soll
   * @return Promise<void> - Promise, das aufgelöst wird, wenn die Entität gelöscht wurde
   */
  public override async localDelete(id: number): Promise<void> {
    const currentObj: StoredData<CustomerLocationModel> = await this.localFind(id);
    await super.localDelete(id);
    if (currentObj) {
      this.enqueueFullTextEntries(currentObj, true);
    }
  }

  //////////////////////////////////////////////////////////////
  //// Überschriebene Methoden des SyncService

  /**
   * Aktualisiert beim Speichern die Fulltext-Queue, falls sich die für den Fulltext relevanten Daten geändert haben
   * @param obj - die Entität, die gespeichert wird
   * @param updatedAt - das Änderungsdatum
   * @protected
   */
  protected override async store(obj: CustomerLocationModel, updatedAt: string): Promise<boolean | StoredData<CustomerLocationModel>> {
    const newObj: StoredData<CustomerLocationModel> = this.createStoredData(obj);
    const currentObj: StoredData<CustomerLocationModel> = await this.localFind(obj.id);
    if (currentObj) {
      if (
        currentObj.content.remote_id !== obj.remote_id ||
        currentObj.content.location_number !== obj.location_number
      ) {
        this.enqueueFullTextEntries(currentObj, true);
        this.enqueueFullTextEntries(newObj, false);
      }
    } else {
      this.enqueueFullTextEntries(newObj, false);
    }
    return super.store(obj, updatedAt);
  }

  //////////////////////////////////////////////////////////////
  //// Private Hilfsmethoden

  /**
   * Fügt einen Eintrag mit Präfix zur Fulltext-Queue hinzu
   * @param obj - die Entität
   * @param prefix - das Präfix
   * @param remove - true, wenn der Eintrag entfernt werden soll, statt hinzugefügt
   * @private
   */
  private enqueueFullTextEntryWithPrefix(
    obj: StoredData<CustomerLocationModel>,
    prefix: FullTextPrefix,
    remove: boolean = false
  ): void {
    let fulltextKeyWithPrefix: string = prefix + '_';

    switch (prefix) {
      case 'RID':
        fulltextKeyWithPrefix += obj.content.remote_id;
        break;

      case 'LNU':
        fulltextKeyWithPrefix += obj.content.location_number;
        break;
    }

    this.fulltextService.insertIntoWorkflowQueue(
      fulltextKeyWithPrefix.toLowerCase(),
      obj.content.id * (remove ? -1 : 1),
      this.type,
    );
  }

  /**
   * Fügt Einträge mit Präfix zur Fulltext-Queue hinzu (bzw. entfernt sie)
   * @param obj - die Entität, für die die Einträge hinzugefügt werden sollen
   * @param remove - true, wenn die Einträge entfernt werden sollen, statt hinzugefügt
   * @private
   */
  private enqueueFullTextEntries(obj: StoredData<CustomerLocationModel>, remove: boolean = false): void {
    if (obj.content.remote_id) this.enqueueFullTextEntryWithPrefix(obj, FULLTEXT_REMOTE_ID_PREFIX, remove);
    if (obj.content.location_number) this.enqueueFullTextEntryWithPrefix(obj, FULLTEXT_LOCATION_NUMBER_PREFIX, remove);
  }

  /**
   * Finden einer Entität über den Fulltext mit einem Präfix
   * @param input - Suchbegriff für das Attribut
   * @param prefix - Präfix für die Suche
   * @return Promise<CustomerLocationModel | undefined> - die gefundene Entität oder undefined
   */
  private async fullTextFindWithPrefix(input: string, prefix: FullTextPrefix): Promise<CustomerLocationModel | undefined> {
    const normalizedInput: string = input?.trim().toLowerCase();
    if (!normalizedInput || normalizedInput.length === 0) return undefined;

    const result: CustomerLocationModel[] = await this.fullTextAllWithPrefix(input, prefix);
    if (result?.length > 0) {
      return result[0];
    }
    return undefined;
  }

  /**
   * Finden aller Entitäten über den Fulltext mit einem Präfix
   * @param input - Suchbegriff für das Attribut
   * @param prefix - Präfix für die Suche
   * @return Promise<CustomerLocationModel[]> - die gefundenen Entitäten
   */
  private async fullTextAllWithPrefix(input: string, prefix: FullTextPrefix): Promise<CustomerLocationModel[]> {
    const normalizedInput: string = input?.trim().toLowerCase();
    if (!normalizedInput || normalizedInput.length === 0) return [];

    return this.fullTextSearch(`${prefix}_${input}`);
  }
}
