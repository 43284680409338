import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SyncProcessorService } from '@vending/sync-engine-client';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { MachineStateModel } from 'src/app/models/machines/machine-state';
import { ErrorService } from '../../error.service';
import { EventsService } from '../../events.service';
import { OfflineDataService } from './../../offlineData.service';

@Injectable({
  providedIn: 'root',
})
export class MachineStateService extends OfflineDataService<MachineStateModel> {
  constructor(
    public indexedDBService: NgxIndexedDBService,
    public syncProcessor: SyncProcessorService,
    public http: HttpClient,
    public errorService: ErrorService,
    public events: EventsService
  ) {
    super(
      indexedDBService,
      syncProcessor,
      'Machines::MachineState',
      http,
      errorService,
      events,
      'machines/machine_states/',
      'machine_state',
      ['created_at', 'updated_at', 'created_by_id'],
      []
    );
  }
}
