import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ITourLocationService, TourLocationModel } from 'src/packages/tourManagement/interfaces/location.service.interface';

import { CustomerLocationModel } from 'src/app/models/customers/location';
import { LocationCustomerService } from '../locations/location-customer.service';

@Injectable({
    providedIn: 'root',
})
export class LocationServiceTourAdapter implements ITourLocationService {
    constructor(private locationService: LocationCustomerService) { }

    async all(): Promise<TourLocationModel[]> {
        const response = await firstValueFrom(this.locationService.allRemote());
        return response.data.map(this.toTourLocationModel);
    }

    private toTourLocationModel(location: CustomerLocationModel): TourLocationModel {
        return {
            id: location.id,
            name1: location.name1,
            name2: location.name2
        };
    }
}