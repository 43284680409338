import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, retry, tap } from 'rxjs/operators';
import { DataService } from 'src/app/providers/data.service';
import { ErrorService } from 'src/app/providers/error.service';
import { CarInventoryHelper } from 'src/packages/carManagement';

@Injectable({
  providedIn: 'root',
})
export class CarLoadingSaveService extends DataService<any> {
  endpoint = 'car_management/movement';
  objectName = 'base_movement';

  /**
   * Default-Constructor
   * @param {HttpClient} http
   * @param {ErrorService} errorService
   */
  constructor(
    public http: HttpClient,
    public errorService: ErrorService,
    private readonly invhelper: CarInventoryHelper
  ) {
    super(http, errorService);
  }

  saveOrder(object: any): Observable<any> {
    return this.http
      .post<any>(
        this.endpointWithUrl + '/without_object',
        this.formatPayload(object),
        this.httpOptions
      )
      .pipe(tap((x) => this.afteraveCallback(x)))
      .pipe(retry(1), catchError(this.errorService.convert));
  }

  saveCommission(object: any): Observable<any> {
    return this.http
      .post<any>(
        this.endpointWithUrl + '/commision',
        this.formatPayload(object),
        this.httpOptions
      )
      .pipe(tap((x) => this.afteraveCallback(x)))
      .pipe(retry(1), catchError(this.errorService.convert));
  }

  saveFree(object: any): Observable<any> {
    return this.http
      .post<any>(
        this.endpointWithUrl + '/free_loading',
        this.formatPayload(object),
        this.httpOptions
      )
      .pipe(tap((x) => this.afteraveCallback(x)))
      .pipe(retry(1), catchError(this.errorService.convert));
  }

  saveCarOrder(object: any): Observable<any> {
    return this.http
      .post<any>(
        this.endpointWithUrl + '/order',
        this.formatPayload(object),
        this.httpOptions
      )
      .pipe(tap((x) => this.afteraveCallback(x)))
      .pipe(retry(1), catchError(this.errorService.convert));
  }

  /**
   * Nach dem Speichern wird der Callback ausgeführt
   * @param {any} result
   */
  private async afteraveCallback(result: any) {
    await this.invhelper.updateLocalInventory(true);
  }
}
