import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
// internal
import { StockObjectModel } from 'src/packages/warehouse';

@Injectable({
  providedIn: 'root',
})
export class ActiveInventoriesCounterService {
  // Die ID des Users, dessen Inventuren gezählt werden sollen
  private _userId: number;
  // Beinhaltet alle Ids der Warehouse::Stock's die inventory_required = true sind
  private _inventoriesActiveIDs: Set<number> = new Set<number>([]);
  // Zählt die Anzahl der aktiven Inventuren
  private _inventoriesActive: BehaviorSubject<number> = new BehaviorSubject<number>(this._inventoriesActiveIDs.size);
  // Observable für _inventoriesActive
  public inventoriesActiveCounter$: Observable<number> = this._inventoriesActive.asObservable();


  /**
   * Initialisiert das Set mit den IDs der aktiven Inventuren
   * - Wird in App-Componnent initialisiert
   * @param data - StockObjectModels, dessen IDs die in die Map eingefügt werden sollen
   */
  async initialState(data: StockObjectModel[], userID: number): Promise<void> {
    this._userId = userID;
    data.forEach((stock: StockObjectModel) => this.onStore(stock));
    this._inventoriesActive.next(this._inventoriesActiveIDs.size);
  }

  /**
   * Aktualisiert das Set mit den IDs der aktiven Inventuren
   * - Wird aufgerufen im Stock-Service
   */
  onStore(object: StockObjectModel): void {
    // Hinzufügen der ID, wenn inventory_required = true, ansonsten löschen
    if (object.inventory_required === true && object.inventory_required_by_id === this._userId)
      this._inventoriesActiveIDs.add(object.id);
    if (object.inventory_required === false)
      this._inventoriesActiveIDs.delete(object.id);
    this._inventoriesActive.next(this._inventoriesActiveIDs.size);
  }

  /**
   * Löscht eine ID eines StockObjectModels aus dem Set
   * @param id - ID des StockObjectModels, dessen ID gelöscht werden soll
   */
  onLocalDelete(id: number): void {
    if (!id) return;
    this._inventoriesActiveIDs.delete(id);
    this._inventoriesActive.next(this._inventoriesActiveIDs.size);
  }

  /**
   * Gibt die Anzahl der aktiven Inventuren zurück
   */
  getActiveInventories(): number {
    return this._inventoriesActiveIDs.size;
  }

  /**
   * Gibt die Anzahl der aktiven Inventuren als Observable zurück
   */
  getActiveInventoriesAsObservable(): Observable<number> {
    return this.inventoriesActiveCounter$;
  }
}
