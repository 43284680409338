import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ViewWillEnter } from '@ionic/angular';
import { take } from 'rxjs';
import { filter } from 'rxjs/operators';
// internal
import { UserHelper } from 'src/app/providers/helpers/user-helper.service';

/**
 * Komponente für die Startseite der Anwendung.
 * Leitet den Benutzer auf die im Mandanten des Nutzers gesetzte Startseite weiter.
 */

@Component({
  selector: 'app-home-page',
  template: '',
  standalone: true
})
export class HomePageComponent implements ViewWillEnter {
  constructor(
    private userHelper: UserHelper,
    private router: Router
  ) {
  }

  /**
   * Wartet darauf, dass der Benutzer geladen ist und leitet dann
   * auf die im Mandanten des Nutzers gesetzte Startseite weiter.
   */
  ionViewWillEnter(): void {
    this.userHelper.userLoaded$
      .pipe(
        filter(loaded => loaded),
        take(1)
      )
      .subscribe(async (): Promise<void> => {
        await this.silentRedirectToMandatorHomePage();
      });
  }

  /**
   * Leitet den Benutzer auf die im Mandanten gesetzte Startseite weiter, ohne die URL zu ändern.
   * @private
   */
  private async silentRedirectToMandatorHomePage(): Promise<void> {
    const homePage: string = this.userHelper.getHomePage();
    if (homePage)
      await this.router.navigate(
        [homePage],
        {skipLocationChange: true}
      );
  }
}
