<ion-header>
  <ion-toolbar [color]="color === 'primary' ? 'primary' : null" [ngClass]="{'colorBgDebug': color === 'debug'}">
    <ion-buttons *ngIf="!modal" slot="start">
      <ion-menu-toggle autoHide="false">
        <ion-button (click)="pageService.toggleMenu()">
          <ion-icon name="menu" slot="icon-only" color="light"></ion-icon>
        </ion-button>
      </ion-menu-toggle>
      <ion-back-button *ngIf="backButton && !useLocationBack" defaultHref="{{ defaultHref }}"
        (click)="onlyDefaultHref ? routeBack() : null" color="light">
      </ion-back-button>
      <ion-back-button class="location-back" *ngIf="backButton && useLocationBack"
        (click)="pageService.returnToPreviousPage()" color="light">
      </ion-back-button>
    </ion-buttons>
    <ion-buttons *ngIf="modal && allowClose" slot="end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close-circle-outline" color="light"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title [ngClass]="{'isModalTitle': modal}" color="light">{{ title }}</ion-title>
  </ion-toolbar>
</ion-header>