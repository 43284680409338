import { ToastButton, ToastController } from '@ionic/angular';
import { Injectable } from '@angular/core';

export type ToastColor =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'success'
  | 'warning'
  | 'danger'
  | 'medium'
  | 'light';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private toastCtrl: ToastController) {}

  /**
   * Returns a ToastMessage if the action was successful.
   * @param message - passes the message to be output
   */
  async custom(
    message: string,
    color: ToastColor = 'primary',
    duration: number = 3000,
    buttons?: ToastButton[]
  ) {
    const toast = await this.toastCtrl.create({
      message,
      duration,
      color,
      buttons,
      position: 'bottom',
    });
    toast.present();
  }

  /**
   * Returns a ToastMessage if the action was successful.
   * @param message - passes the message to be output
   */
  async success(message: string) {
    const toast = await this.toastCtrl.create({
      message,
      duration: 3000,
      color: 'primary',
      position: 'bottom',
    });
    toast.present();
  }

  /**
   * Returns a ToastMessage if the action was not successful.
   * @param message - passes the message to be output
   */
  async danger(message: string) {
    const toast = await this.toastCtrl.create({
      message,
      color: 'danger',
      position: 'bottom',
      buttons: [
        {
          text: 'Ok',
          role: 'cancel',
        },
      ],
    });
    toast.present();
  }
}
