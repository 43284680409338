import { EventEmitter, Injectable } from '@angular/core';
import {
  SyncEngineService,
  SyncInformationService,
  SyncProcessorService,
  SyncQueueService,
} from '@vending/sync-engine-client';
import { QueueEntry } from '@vending/sync-engine-client/lib/models/queueEntry';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { interval } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { FunctionSwitchHelperService } from 'src/app/providers/component-helpers/function-switch.service';
import { MileageService } from 'src/app/providers/model-services/orders/mileage.service';
import { ObjectImageService } from 'src/app/providers/model-services/system';
import {
  CarInventoryHelper,
  CarOrderService,
  CarService,
} from 'src/packages/carManagement';
import { ChecklistService } from 'src/packages/checklistManagement/providers/checklist.service';
import {
  DamageCategoryService,
  DamageReportService,
} from 'src/packages/damageManagement/providers';
import { TemplateService } from 'src/packages/templateManagement/providers/template.service';
import {
  TimeEvent,
  TimelogHelperV2,
  TimelogState,
} from 'src/packages/timeManagement';
import {
  BufferstockService,
  StockMovementService,
} from 'src/packages/warehouse/providers';
import { BasketService } from 'src/packages/warehouse/providers/basket.service';
import { BufferstockCleanupService } from 'src/packages/warehouse/providers/bufferstock-cleanup.service';
import { BufferstockInventoryService } from 'src/packages/warehouse/providers/buffertock-inventory.service';
import { WarehouseOrderAcceptanceService } from 'src/packages/warehouse/providers/order-acceptance.service';
import { WarehouseOrderService } from 'src/packages/warehouse/providers/order.service';
import { StockObjectService } from 'src/packages/warehouse/providers/stockObject.service';
import {
  CAR_MANAGEMENT,
  OFFLINE_STORAGE_UPDATE_INTERVAL,
  ZE_AUTO_DAYSTART,
} from '../../assets/constants/functionSwitch.constants';
import { DataService } from './data.service';
import { EventsService } from './events.service';
import { InitializationHelper } from './helpers/initialization-helper';
import { SystemHelper } from './helpers/system-helper';
import { UserHelper } from './helpers/user-helper.service';
import { LocalDestroyService } from './localDestroy.service';
import { ArticleUnitService } from './model-services/article-unit.service';
import { ArticleService } from './model-services/article.service';
import { BoilerplateService } from './model-services/boilerplate.service';
import { BusinessService } from './model-services/business.service';
import { ClientDeviceService } from './model-services/client-device.service';
import { CustomerService } from './model-services/customer.service';
import { FeatureTypeService } from './model-services/features/feature-type.service';
import { MediaFilesService } from './model-services/features/media-files.service';
import { LocationBufferstockService } from './model-services/locations/location-bufferstock.service';
import { LocationCustomerService } from './model-services/locations/location-customer.service';
import { LocationMachineService } from './model-services/locations/location-machine.service';
import { MachineGroupService } from './model-services/machines/machine-group.service';
import { MachineStateService } from './model-services/machines/machine-state.service';
import { MachineTypeService } from './model-services/machines/machine-type.service';
import { MachineService } from './model-services/machines/machine.service';
import { MandatorService } from './model-services/mandator.service';
import { OrderService } from './model-services/orders/order.service';
import { ScrappingTypeService } from './model-services/orders/scrapping-type.service';
import { PriorityService } from './model-services/priority.service';
import { StatusService } from './model-services/status.service';
import { TimelogCategoryService } from './model-services/timelogs/timelog-category.service';
import { TimelogCorrectionService } from './model-services/timelogs/timelog-correction.service';
import { TimelogService } from './model-services/timelogs/timelog.service';
import { UserService } from './model-services/user.service';
import { TemporaryImageService } from './model-services/writeable-checklist/temporary-image.service';
import { OfflineDataService } from './offlineData.service';
import { FeatureService } from './model-services/features/feature.service';

@Injectable({
  providedIn: 'root',
})
/**
 * Wrapper bzw. Facade der alles für Offline kapselt
 */
export class SyncWrapperService {
  private service = new SyncQueueService(this.indexedDBService, '');

  public get icons(): Record<string, string> {
    return this._icons;
  }

  private set icons(v: Record<string, string>) {
    this._icons = v;
  }

  _automaticTimelogStart?: boolean;

  constructor(
    private readonly checklistService: ChecklistService,
    private readonly engineService: SyncEngineService,
    private readonly eventsService: EventsService,
    private readonly functionSwitchHelper: FunctionSwitchHelperService,
    private readonly initializationHelper: InitializationHelper,
    private readonly invHelper: CarInventoryHelper,
    private readonly localDestroyWrapper: LocalDestroyService,
    private readonly syncInformationService: SyncInformationService,
    private readonly syncProcessor: SyncProcessorService,
    private readonly systemHelper: SystemHelper,
    private readonly timelogHelper: TimelogHelperV2,
    private readonly userHelper: UserHelper,
    // Hier müssen weitere offline verfügbare Dienste injiziert werden, damit diese im ProcessorService registriert sind
    public readonly customerService: CustomerService,
    public readonly machineService: MachineService,
    public readonly mandatorService: MandatorService,
    public readonly businessService: BusinessService,
    public readonly userService: UserService,
    public readonly unitService: ArticleUnitService,
    public readonly damageCategoryService: DamageCategoryService,
    public readonly priorityService: PriorityService,
    public readonly clientDeviceService: ClientDeviceService,
    public readonly damageReportService: DamageReportService,
    public readonly boilerplateService: BoilerplateService,
    public readonly orderService: OrderService,
    public readonly articleService: ArticleService,
    public readonly temporaryImageService: TemporaryImageService,
    public readonly mileageService: MileageService,
    public readonly indexedDBService: NgxIndexedDBService,
    public readonly machineGroupService: MachineGroupService,
    public readonly machineTypeService: MachineTypeService,
    public readonly machineStateService: MachineStateService,
    public readonly carService: CarService,
    public readonly statusService: StatusService,
    public readonly templateService: TemplateService,
    public readonly timelogService: TimelogService,
    public readonly carOrderService: CarOrderService,
    public readonly timelogCorrectionService: TimelogCorrectionService,
    public readonly timelogCategoryService: TimelogCategoryService,
    public readonly featureTypeService: FeatureTypeService,
    public readonly mediaFilesService: MediaFilesService,
    public readonly stockObjectService: StockObjectService,
    public readonly bufferstockService: BufferstockService,
    public readonly stockMovementService: StockMovementService,
    public readonly locationBufferstockService: LocationBufferstockService,
    public readonly locationMachineService: LocationMachineService,
    public readonly locationCustomerService: LocationCustomerService,
    public readonly warehouseOrderService: WarehouseOrderService,
    public readonly basketService: BasketService,
    public readonly orderAcceptanceService: WarehouseOrderAcceptanceService,
    public readonly scrappingTypeService: ScrappingTypeService,
    public readonly bufferstockCleanupService: BufferstockCleanupService,
    public readonly bufferstockInventoryService: BufferstockInventoryService,
    public readonly objectImageService: ObjectImageService,
    public readonly featureService: FeatureService
  ) {
    SyncInformationService.setServiceUrl(DataService.serviceUrl);
    this.addService(this.mandatorService);
    this.addService(this.businessService);
    this.addService(this.userService);
    this.addService(this.clientDeviceService);
    this.addService(this.unitService);
    this.addService(this.priorityService);
    this.addService(this.damageCategoryService);
    this.addService(this.damageReportService);
    this.addService(this.customerService);
    this.addService(this.machineService);
    this.addService(this.boilerplateService);
    this.addService(this.articleService);
    this.addService(this.orderService);
    this.addService(this.temporaryImageService);
    this.addService(this.mileageService);
    this.addService(this.checklistService);
    this.addService(this.machineGroupService);
    this.addService(this.machineTypeService);
    this.addService(this.machineStateService);
    this.addService(this.carService);
    this.addService(this.statusService);
    this.addService(this.templateService);
    this.addService(this.timelogService);
    this.addService(this.carOrderService);
    this.addService(this.timelogCorrectionService);
    this.addService(this.timelogCategoryService);
    this.addService(this.featureTypeService);
    this.addService(this.mediaFilesService);
    this.addService(this.stockObjectService);
    this.addService(this.bufferstockService);
    this.addService(this.stockMovementService);
    this.addService(this.locationBufferstockService);
    this.addService(this.locationMachineService);
    this.addService(this.locationCustomerService);
    this.addService(this.warehouseOrderService);
    this.addService(this.basketService);
    this.addService(this.orderAcceptanceService);
    this.addService(this.scrappingTypeService);
    this.addService(this.bufferstockCleanupService);
    this.addService(this.bufferstockInventoryService);
    this.addService(this.objectImageService);
    this.addService(this.featureService)

    this._automaticTimelogStart =
      this.functionSwitchHelper.has(ZE_AUTO_DAYSTART);
  }

  /**
   * Letzter Sync Timestamp
   * @returns sync time
   */
  public get syncTimestamp(): Date | null {
    return this.syncProcessor.lastSyncTimestamp;
  }
  private syncStarted = false;
  private syncInterval: number = 60 * 1000;

  private services: Array<OfflineDataService<any>> = [];

  private _icons: Record<string, string> = {};

  public downloadProgressChanged: EventEmitter<number | null> =
    new EventEmitter<number | null>();

  public uploadStatusChanged: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  public getServices(): Array<OfflineDataService<any>> {
    return this.services;
  }

  /**
   * Volltext neu aufbauen
   */
  public async rebuildFulltext(): Promise<void> {
    await this.machineService.rebuildFullText();
    await this.stockObjectService.rebuildFullText();
    await this.locationBufferstockService.rebuildFullText();
    await this.articleService.rebuildFullText();
  }

  /**
   * ConfigurableIDs neu aufbauen (#782)
   */
  public async rebuildConfigurableIds(): Promise<void> {
    this.services.forEach((service: OfflineDataService<any>): void => {
      console.debug('Rebuilding Configurable IDs for', service.type)
      service.rebuildConfigurableIds();
    });
  }

  /**
   * Manuelle Offlinedaten-Validierung
   */
  public async validateLocalData(): Promise<void> {
    this.services.forEach((service: OfflineDataService<any>): void =>{
      service.resetAlreadyValidated();
    });
    await this.syncProcessor.validateLocalData();
  }

  /**
   * Fügt intern den Service ein
   * @param service to add
   */
  private addService(service: OfflineDataService<any>) {
    this.services.push(service);
    this.icons[service.type] = service.iconName;
  }

  /**
   * queuedData
   */
  public queuedData(): Promise<QueueEntry<any>[]> {
    return this.service.getQueuedData();
  }

  /**
   * Update queue entry
   */
  public updateQueueEntry(entry: QueueEntry<any>): Promise<number> {
    return this.service.update(entry);
  }

  /**
   * Starte die Verarbeitung der Daten
   */
  public async startSync() {
    // Serverseitige Synchronisierung starten
    this.syncStarted = true;
    const useSubstring =
      await this.functionSwitchHelper.useSubstringForFullText();
    this.articleService.useSubstring = useSubstring;
    this.syncProcessor.fullSyncStarted.subscribe(() => {
      this.initLoading();
    });
    this.syncProcessor.fullSyncProgess.subscribe((val) =>
      this.setProgessFullSync(val)
    );
    this.syncProcessor.fullSyncStopped.subscribe(() => this.stopLoading());

    await this.internalSync();

    const fsCarManagementActive = this.functionSwitchHelper.has(CAR_MANAGEMENT);
    if (fsCarManagementActive) await this.invHelper.updateLocalInventory();

    const fsIntervalOffline = await this.functionSwitchHelper.getValue(
      OFFLINE_STORAGE_UPDATE_INTERVAL
    );
    if (Number(fsIntervalOffline) > 0) {
      this.syncInterval = Number(fsIntervalOffline) * 1000;
    }

    this.internalSync().then(() => {
      if (Number(fsIntervalOffline) > 0)
        interval(this.syncInterval)
          .pipe(takeWhile(() => this.syncStarted))
          .subscribe(async () => await this.internalSync());
    });

    // Updates von Client-Seite aus starten (Queue)
    this.services.forEach((service) => {
      service.startSync();
    });
  }

  /**
   * Löscht die lokalen IndexDB und lädt die Anwendung neu
   */
  public async dropSyncInformationsAndReload() {
    if (this.timelogHelper.currentState !== TimelogState.Unkown) {
      try {
        await this.timelogHelper.changeStateDebounce(TimeEvent.Logout);
      } catch (e) {
        console.error(e);
        return;
      }
    }

    // Sync anhalten
    this.stopSync();

    // Zeitstempel vor Host Veränderung zurücksetzen, da danach nicht mehr möglich
    this.syncInformationService.clearTimestamp();
    window.location.href = '/';

    // Indexdb komplett löschen
    await this.engineService.resetAll();
  }

  private async initLoading() {
    this.initializationHelper.addTask(
      {
        startEvent: 'syncWrapper:startInitSyncProgress',
        proggressEvent: 'syncWrapper:initSyncProgress',
        completedEvent: 'syncWrapper:completedInitSyncProgress',
        title: 'Erstsynchronisation',
        description:
          'Es werden alle Daten auf das Gerät geladen um die Offlinefähigkeit zu gewährleisten.',
      },
      true
    );
    this.eventsService.publish('syncWrapper:startInitSyncProgress', {});
  }

  /**
   * Fortschritt im Loading-Fenster setzen
   * @param val progress
   */
  private setProgessFullSync(val: number) {
    this.eventsService.publish('syncWrapper:initSyncProgress', val);
  }

  /**
   * Initial Sync Fenster schleißen
   */
  private stopLoading() {
    this.eventsService.publish('syncWrapper:completedInitSyncProgress', {});
    this.userHelper.loadUser().then(() => {
      if (
        this._automaticTimelogStart &&
        this.timelogHelper.currentState === TimelogState.Unkown
      )
        this.timelogHelper.changeStateDebounce(TimeEvent.WorkStart);
    });
  }

  private initProgressSync() {
    this.downloadProgressChanged.emit(0);
  }

  private setProgressSync(val: number) {
    this.downloadProgressChanged.emit(Math.round(val * 100) / 100);
  }

  private stopProgressSync() {
    this.downloadProgressChanged.emit(null);
  }

  /**
   * Interne Synchronisierungsfunktion, um den Prozess zu starten
   */
  public async internalSync(): Promise<void> {
    if (!this.systemHelper.isOnline) return;
    const args = {
      user_id: this.userHelper.getUser().id,
    };
    this.syncProcessor.syncStarted.subscribe(() => this.initProgressSync());
    this.syncProcessor.syncProgess.subscribe((val) =>
      this.setProgressSync(val)
    );
    this.syncProcessor.syncStopped.subscribe(() => this.stopProgressSync());
    await this.syncProcessor.process(args).catch((err) => Promise.reject(err));
    await this.localDestroyWrapper
      .runLocalDataCleaner()
      .catch((err) => Promise.reject(err));
  }

  public async startUploadedQueue() {
    this.uploadStatusChanged.emit(true);
    this.getServices().forEach((service) => {
      service.startSyncOnce();
    });
    this.uploadStatusChanged.emit(false);
  }

  /**
   * Synchronisierung stoppen
   */
  public stopSync() {
    this.syncStarted = false;

    this.services.forEach((service) => {
      service.stopSync();
    });
  }

  /**
   * Löscht einen Eintrag aus der Queue
   * @param entry to delete
   * @returns if deleted
   */
  public async deleteEntryFromQueue(entry: QueueEntry<any>) {
    return this.service.delete(entry.id);
  }

  public async getAmountOfData(): Promise<number> {
    const amounts = await Promise.all(
      this.services.map((service) => service.getLocalCount())
    );
    return amounts.reduce((a, b) => a + b, 0);
  }

  public async getAmountOfFulltext(): Promise<{
    queued: number;
    finished: number;
  }> {
    const amounts: { queued: number; finished: number } =
      await this.services[0].getFulltextAmount();
    return {
      queued: amounts.queued,
      finished: amounts.finished,
    };
  }
}
