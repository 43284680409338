// Framework Dependencies
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

export const TOUR_MANAGEMENT_BASE_ROUTE = 'tour-management';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./pages/tours/tours.module').then((m) => m.ToursPageModule),
  },
  {
    path: 'details/:id',
    loadChildren: () =>
      import('./pages/tour-details/tour-details.module').then(
        (m) => m.TourDetailsPageModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TourManagementRoutingModule {}
