import { IDataService } from 'src/app/providers/idataService';

/**
 * Konvertiert die Daten zu FormData um diese ans Backend senden zu können bei File upload
 * @param object Daten die konvertiert werden sollen
 * @param service Service der die Daten verwaltet
 * @param filesAttributes Attribute die Dateien enthalten
 * @param stringifyArrayAttributes Attribute die arrays beinhalten und zu einem string zusammengefasst werden sollen
 * @returns FormData zum verschicken
 */
export function toFormData(
  object: any,
  service: IDataService<any>,
  filesAttributes: string[] = ['files'],
  stringifyArrayAttributes: string[] = []
): FormData {
  let form = new FormData();
  form = attachObjectToForm(
    form,
    object,
    service.objectName,
    service.removeParams,
    filesAttributes,
    stringifyArrayAttributes
  );
  return form;
}

function attachObjectToForm(
  form: FormData,
  object: any,
  nestedKey: string,
  removeAttributes: string[],
  filesAttributes: string[],
  stringifyArrayAttributes: string[]
): FormData {
  if (!object || !nestedKey || !form) return form;
  const filteredKeys: string[] = Object.keys(object).filter(
    (o) =>
      !removeAttributes.includes(o) &&
      object[o] !== null &&
      object[o] !== undefined
  );
  filteredKeys.forEach((key) => {
    if (Array.isArray(object[key])) {
      form = attachArrayToForm(
        form,
        object,
        nestedKey,
        removeAttributes,
        filesAttributes,
        stringifyArrayAttributes,
        key
      );
    } else if (
      typeof object[key] === 'object' &&
      !filesAttributes.includes(key)
    ) {
      const newNestedKey = nestedKey + `[${key}]`;
      form = attachObjectToForm(
        form,
        object[key],
        newNestedKey,
        removeAttributes,
        filesAttributes,
        stringifyArrayAttributes
      );
    } else {
      const newNestedKey = nestedKey + `[${key}]`;
      form.append(newNestedKey, object[key]);
    }
  });
  return form;
}

function attachArrayToForm(
  form: FormData,
  object: any,
  nestedKey: string,
  removeAttributes: string[],
  filesAttributes: string[],
  stringifyArrayAttributes: string[],
  key: string
): FormData {
  if (stringifyArrayAttributes.includes(key)) {
    form.append(`${nestedKey}[${key}]`, '[' + object[key].join(',') + ']');
  } else if (object[key].length > 0) {
    const newNestedKey = nestedKey + `[${key}]`;
    if (filesAttributes.includes(key)) {
      let files: FileList = object[key];
      if ('image' in object[key][0] && 'file_url' in object[key][0]) {
        files = object[key].map((o) => o.image);
      }
      // tslint:disable-next-line: prefer-for-of // Because type is FileList
      for (let i = 0; i < files.length; i++) {
        form.append(`${newNestedKey}[]`, files[i]);
      }
    } else {
      form = attachObjectToForm(
        form,
        object[key],
        newNestedKey,
        removeAttributes,
        filesAttributes,
        stringifyArrayAttributes
      );
    }
  }
  return form;
}
