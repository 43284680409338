import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class LoadingHelper {
  private loadingCounter = 0;
  private loadingObject: HTMLIonLoadingElement = null;
  private loadingText = 'Daten werden geladen...';
  private loadingTimer: any = null;

  constructor(public loadingCtrl: LoadingController) {}

  /**
   * Changes the default displayed text
   * @param text Custom Text (sets to default if not provided)
   */
  public setText(text: string = 'Daten werden geladen...'): void {
    if (this.loadingCounter === 0 && this.loadingText !== text) {
      this.loadingText = text;
      this.initLoading();
    }
  }

  /**
   * Adds an Loadingprocess to the Queue
   */
  public addLoading(): void {
    this.loadingCounter++;
    console.debug(`[LoadingHelper] Loading ${this.loadingCounter} registriert`);
    this.updateLoading();
  }

  /**
   * Removes an Loadingprocess of the Queue
   */
  public removeLoading(): void {
    console.debug(`[LoadingHelper] Loading ${this.loadingCounter} entfernt`);
    this.loadingCounter--;
    if (this.loadingCounter <= 0) {
      this.resetLoadings();
    }
  }

  public resetLoadings(): void {
    console.debug(`[LoadingHelper] Loading reset`);
    this.loadingCounter = 0;
    this.setText();
    if (this.loadingObject) {
      this.loadingObject.dismiss();
      this.loadingObject = null;
    }
    if (this.loadingTimer) {
      clearTimeout(this.loadingTimer);
      this.loadingTimer = null;
    }
  }

  /**
   * Initialisiert das Loading Objekt mit dem Controller
   */
  private async initLoading(): Promise<HTMLIonLoadingElement> {
    this.loadingObject = await this.loadingCtrl.create({
      message: this.loadingText,
      spinner: 'dots',
    });

    return this.loadingObject;
  }

  /**
   * Verzögerung bevor ein Loading erscheint.
   */
  private loadTimer(): any {
    console.debug('[LoadingHelper] Loading wird in 250ms angezeigt.');
    return setTimeout(() => {
      if (this.loadingCounter === 0) {
        this.loadingTimer = null;
        this.loadingObject = null;
        return;
      }
      console.debug('[LoadingHelper] Loading wird angezeigt.');
      this.loadingObject.present();
    }, 250);
  }

  /**
   * Erstellt ein Loading.
   */
  private async updateLoading(): Promise<void> {
    if (!this.loadingObject) {
      await this.initLoading();
      this.loadingTimer = this.loadTimer();
    }
  }
}
